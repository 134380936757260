import { Helmet } from 'react-helmet-async'
import './error.css'

export default function Error() {
  return (
    <>
      <Helmet>
        <title>Ошибка</title>
      </Helmet>
      <div className='error__container'>
        <div className='error__content'>
          <svg className='error__svg' width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M47.1711 29.1141L49.9995 31.9424L52.8278 29.114L76.8457 5.09634L74.0269 2.27751L76.8457 5.09633C78.3058 3.63629 80.6739 3.63629 82.1339 5.09633L84.9531 2.27718L82.1339 5.09633L94.9038 17.8662C96.3632 19.3256 96.3644 21.6923 94.9038 23.1543C94.9036 23.1546 94.9034 23.1548 94.9031 23.155L70.885 47.1722L68.0563 50.0008L70.885 52.8293L94.9052 76.8468C94.9052 76.8469 94.9053 76.8469 94.9053 76.847C96.3652 78.307 96.3652 80.675 94.9052 82.135L82.1355 94.9035L82.1353 94.9037C80.6753 96.3637 78.3072 96.3637 76.8471 94.9037L76.847 94.9036L52.8277 70.8859L49.9993 68.0577L47.171 70.886L23.1531 94.9037C21.6931 96.3637 19.3249 96.3637 17.8649 94.9037L17.8647 94.9035L5.09503 82.135C3.63499 80.675 3.63499 78.3069 5.09503 76.8468L29.114 52.8292L31.9427 50.0007L29.114 47.1722L5.09503 23.1544C5.09501 23.1544 5.09499 23.1543 5.09496 23.1543C3.63499 21.6943 3.63501 19.3262 5.09503 17.8662L5.09516 17.866L17.8639 5.09613C17.8639 5.09609 17.864 5.09604 17.864 5.096C19.3241 3.63609 21.6919 3.63613 23.1519 5.09613L23.152 5.0962L47.1711 29.1141Z" stroke="#004AFF" strokeWidth="8" />
          </svg>
          <div>
            <h1 className='error__title'>Ошибка сервера</h1>
            <p className='error__text'>На сервере произошла непредвиденная ошибка. Пожалуйста, подождите, в скором времени она будет исправлена.</p>
          </div>
        </div>
      </div>
    </>
  )
}