import { useCallback, useEffect, useState } from "react"
import Spinner from "../../components/spinner/spinner"
import MetricsFilterTable from "../../components/filter-table/metrics-filter-table/metrics-filter-table"
import { Metric } from "../../types/metrics-model"
import axios, { PagesURl } from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './metrics-page.css'
import { Helmet } from "react-helmet-async"
import { useNavigate } from "react-router-dom"
import { getOrganization } from "../../services/organization"

export default function MetricsPage() {
  const navigate = useNavigate()
  useEffect(()=>{
    if (!getOrganization()){
      navigate('/')
    }
  })

  const baseUrl = PagesURl.METRICS

  const [metrics, setMetrics] = useState<Metric[]>([])
  const [metricsIsLoading, setMetricsIsLoading] = useState(true)

  const notify = (message: string) => toast(message);

  const getMetricsByProductMetrics = useCallback(async () => {
    setMetricsIsLoading(true)
    try {
      const response = await axios.post<{data: Metric[], totalCount: number}>(baseUrl + `/allProducts/findMetric?productMetrics=All`,null,{
        headers: {
          'Content-Type': 'application/json',
        },
      })
      setMetrics(response.data.data)
      setMetricsIsLoading(false)
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }, [baseUrl]);

  useEffect(() => {
    getMetricsByProductMetrics();
  }, [getMetricsByProductMetrics])

  return (
    <>
      <Helmet>
        <title>Метрики</title>
      </Helmet>
      <div className="metrics-container">
        <h2>Метрики</h2>
        <ul>
          {/*<li>
            <button onClick={()=>{setIsCreateModalOpen(true)}}>Создать продукт</button>
          </li>*/}
        </ul>
        <div className="metrics-table">
          {!metricsIsLoading ?
              <MetricsFilterTable content={metrics} />  : <Spinner />}
        </div>
      </div>
    </>

  )
}