import { useState } from 'react';
import Warning from '../warning/warning';
import './card.css'

type CardProps = {
  title: string;
  secondLine: string,
  leftContent?: string[]
  rightContent?: string[]
  centralContent?: string
  isCanDelete?: boolean
  onDeleteAction: () => void
  onClickAction: () => void
  onChangeAction?: () => void
}

export default function Card({ title, secondLine, leftContent, rightContent, centralContent, onDeleteAction, onClickAction, onChangeAction, isCanDelete}: CardProps) {
  const [isOpenWarning, setIsOpenWarning] = useState(false)

  const getCardName = (name:string) => {
    let names = name.split(' ')
    if (names[0] && names[1] && names[2]){
      return `${names[0]} ${names[1][0].toUpperCase()}. ${names[2][0].toUpperCase()}.`
    } return name
  }

  const getIsCanDelete = () => {
    if (isCanDelete === undefined){
      return true
    }
    return isCanDelete
  }

  return (
    <>
      <div onClick={onClickAction} className={`card__container pointer`}>
        <div className={`card__titleContainer ${!getIsCanDelete() ? 'card__titleContainer_notDelete' : 'card__titleContainer_canDelete'}`}>
          <div className='card__titleBlock centerFlex'>
            <h1 className='card__title'>{title}</h1>
            {onChangeAction && <svg onClick={(e) => { e.stopPropagation(); onChangeAction() }} className='card__editTitle' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4.29102 19.526H19.4577M4.29102 19.526V15.7628L11.8743 8.23652M4.29102 19.526H8.08268L15.666 11.9997M11.8743 8.23652L14.5935 5.53771L14.5952 5.53611C14.9695 5.16461 15.157 4.97853 15.3731 4.90884C15.5635 4.84744 15.7686 4.84744 15.959 4.90884C16.175 4.97849 16.3623 5.16435 16.736 5.53532L18.3852 7.17211C18.7606 7.54468 18.9484 7.73105 19.0187 7.94586C19.0806 8.13481 19.0805 8.33834 19.0187 8.5273C18.9485 8.74195 18.761 8.92803 18.3861 9.30007L18.3852 9.30087L15.666 11.9997M11.8743 8.23652L15.666 11.9997" stroke="#0042E4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>}
          </div>
          {getIsCanDelete() &&
            <svg onClick={(e) => {e.stopPropagation();setIsOpenWarning(true)}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.52288 14.4855C5.439 14.4022 5.37242 14.3032 5.32696 14.1941C5.28144 14.0848 5.258 13.9676 5.258 13.8492C5.258 13.7308 5.28144 13.6136 5.32696 13.5043C5.37246 13.3951 5.43912 13.296 5.52309 13.2127C5.52315 13.2126 5.5232 13.2126 5.52325 13.2125L8.74021 10.0031L5.52298 6.79331L5.52288 6.79321C5.35412 6.62445 5.25931 6.39556 5.25931 6.1569C5.25931 5.91824 5.35412 5.68935 5.52288 5.52059C5.69164 5.35183 5.92053 5.25702 6.15919 5.25702C6.39785 5.25702 6.62674 5.35183 6.7955 5.52059L6.7956 5.52069L10.0053 8.73792L13.2151 5.52069L13.2152 5.52059C13.3839 5.35183 13.6128 5.25702 13.8515 5.25702C14.0902 5.25702 14.319 5.35183 14.4878 5.52059C14.6566 5.68935 14.7514 5.91824 14.7514 6.1569C14.7514 6.39556 14.6566 6.62445 14.4878 6.79321L14.4877 6.79331L11.2705 10.0031L14.4874 13.2125C14.4875 13.2126 14.4875 13.2126 14.4876 13.2127C14.5716 13.296 14.6382 13.3951 14.6837 13.5043C14.7292 13.6136 14.7527 13.7308 14.7527 13.8492C14.7527 13.9676 14.7292 14.0848 14.6837 14.1941C14.6383 14.3032 14.5717 14.4022 14.4878 14.4855C14.4045 14.5694 14.3055 14.636 14.1964 14.6814C14.0871 14.727 13.9699 14.7504 13.8515 14.7504C13.7331 14.7504 13.6159 14.727 13.5066 14.6814C13.3974 14.6359 13.2983 14.5693 13.215 14.4853C13.2149 14.4852 13.2149 14.4852 13.2148 14.4851L10.0053 11.2682L6.79587 14.4851C6.79583 14.4852 6.7958 14.4852 6.79577 14.4852C6.71243 14.5692 6.61328 14.6359 6.50405 14.6814C6.39478 14.727 6.27757 14.7504 6.15919 14.7504C6.04081 14.7504 5.9236 14.727 5.81433 14.6814C5.70521 14.636 5.60616 14.5694 5.52288 14.4855ZM5.52288 14.4855C5.52276 14.4854 5.52263 14.4853 5.52251 14.4851L5.61304 14.3954L5.52325 14.4859C5.52313 14.4858 5.523 14.4856 5.52288 14.4855Z" fill="#2051C9" stroke="#2051C9" strokeWidth="0.255" />
              <rect x="1" y="1" width="18" height="18" rx="1.61538" stroke="#2051C9" strokeWidth="2" />
            </svg>}
        </div>
        {secondLine && <p className='card__date centerFlex'>{centralContent ? 'Адрес:' : 'Дата создания:'} {secondLine}</p>}
        <div className='card__infoContainer flex'>
          {leftContent && rightContent &&
            <>
              <div className='card__infoLeft card__infoContent'>
                <h2 className='card__infoTitle'>Участники:</h2>
                <ol className='card__infoList'>
                  {leftContent.map((el) => (<li key={el} className='card__infoPoint'>{getCardName(el)}</li>))}
                </ol>
              </div>
              <div className='card__infoRight card__infoContent'>
                <h2 className='card__infoTitle'>Товары:</h2>
                <ul className='card__infoList'>
                  {rightContent.map((el) => (<li key={el} className='card__infoPoint radiusList'>{el}</li>))}
                </ul>
              </div>
            </>}
          {centralContent &&
            <div className='card__centralInfo'>
              <p className='card__centralInfoTitle'>Описание:</p>
              <p className='card__centralInfoText'>{centralContent}</p>
            </div>
          }
        </div>
      </div>
      {isOpenWarning && <Warning title='Вы действительно хотите удалить?' onClose={()=>{setIsOpenWarning(false)}} onConfirm={onDeleteAction}/>}
    </>
  )
}