import { useRef } from "react";

type PhoneInputProps = {
  onChangeValue: (value: string)=>void
  value: string
  nameOfClass?: string
  placeholderText?: string
}

export default function PhoneInput({onChangeValue, value, nameOfClass, placeholderText}:PhoneInputProps){

  const input = useRef<HTMLInputElement>(null)

  function removeTrailingSpaces(s:string) {
    let i = s.length - 1;
    while (i >= 0 && s[i] === ' ') {
        i--;
    }
    return s.substring(0, i + 1);
  }

  function formatPhoneNumber(phoneNumber: string): string {
    const digitsOnly = phoneNumber.replace(/\D/g, '');
    if (digitsOnly === ''){
      return ''
    }
    return removeTrailingSpaces(`+${digitsOnly.slice(0, 1)}${digitsOnly.slice(1, 4)}${digitsOnly.slice(4, 7)}${digitsOnly.slice(7, 9)}${digitsOnly.slice(9)}`)
  }

  const changeNumber = (e: string) => {
    if (!input.current){
      return
    }
    let inputValue = e.replace(/\D/g, '')
/*     if (inputValue === value && inputValue.length <= 11){
      inputValue = inputValue.substring(0, inputValue.length - 1)
    } */
    if (inputValue[0] === '+' && inputValue[1] === '8'){
      inputValue = inputValue.replace('+8', '+7')
    }
    if (inputValue[0] === '8'){
      inputValue = inputValue.replace('8', '7')
    }
    if (inputValue[0] !== '7' && inputValue[0] !== '8' && inputValue.length !== 0){
      inputValue = '+7' + inputValue
    }
    if (value.length === 11 && value.length > inputValue.length){
      onChangeValue('+'+inputValue)
      return 
    }
    inputValue.length <= 11 && onChangeValue('+'+inputValue)
  }

  return (
    <>
    <input ref={input} onChange={(e) => { changeNumber(e.target.value)}} value={formatPhoneNumber(value)} name='loginData' placeholder={placeholderText} className={`${nameOfClass ? nameOfClass : 'account__modal__input'}`} required type='text' />
    </>
  )
}