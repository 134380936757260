import { useEffect, useState } from "react"
import PasswordInput from "../../components/account-block/password-input"
import 'react-toastify/dist/ReactToastify.css';
import './registration.css'
import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { registrationAction } from "../../store/api-actions/user-api-actions";
import { validatePhoneNumber } from "../../utils/user";
import { getLoginError, getRegistrationStatus } from "../../store/user-process/selectors";
import { redirectToRoute } from "../../store/action";
import PhoneInput from "../../components/custom-input/phone-input/phone-input";
import CodeBlock from "./code-block";
import Spinner from "../../components/spinner/spinner";
import ErrorBlock from "../../components/error-block/error-block";

export default function Registration() {

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const error = useAppSelector(getLoginError)
  const pending = useAppSelector(getRegistrationStatus)
  const [isUploaded, setIsUploaded] = useState(false)
  const [createOrganization, setCreateOrganization] = useState(false)

  const [invalidNumber, setInvalidNumber] = useState<string | null>(null)

  const [codeError, setCodeError] = useState<string>()

  const [registrationForm, setRegistrationForm] = useState({
    phone: "",
    password: "",
    code: 0
  })

  const [activeStage, setActiveStage] = useState<number>()

  const handleRegistration = async () => {
    if (validatePhoneNumber(registrationForm.phone)){
      setIsUploaded(true)
      dispatch(registrationAction(registrationForm))
      setInvalidNumber(null)
    } else {
      setInvalidNumber('Номер телефона не соответствует формату')
    }
  }

  const toFirstStage = () => {
    setActiveStage(1)
    sessionStorage.setItem('regSt', '1')
  }
  const toTwoStage = () => {
    if (!validatePhoneNumber(registrationForm.phone)){
      setInvalidNumber('Номер телефона не соответствует формату')
      return
    }
    setInvalidNumber(null)
    setActiveStage(2)
    sessionStorage.setItem('regSt', '2')
  }
  const toThreeStage = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (e){
      e.preventDefault()
    }
    setActiveStage(3)
    sessionStorage.setItem('regSt', '3')
  }

  useEffect(()=>{
    if (isUploaded && !pending && !error){
      setCreateOrganization(true)
    }
  }, [pending, isUploaded, error])

  useEffect(()=>{
    let stage = sessionStorage.getItem('regSt')
    let phone = sessionStorage.getItem('regPh')
    let pass = sessionStorage.getItem('regPass')
    let code = sessionStorage.getItem('regCode')
    if (stage){
      if (parseInt(stage) === 1){
        sessionStorage.removeItem('regCode')
        sessionStorage.removeItem('regSt')
        sessionStorage.removeItem('regPh')
        sessionStorage.removeItem('regPass')
      } else if (parseInt(stage) === 2){
        sessionStorage.removeItem('regCode')
      }
      setActiveStage(parseInt(stage))
      let newRegForm = {...registrationForm}
      newRegForm.phone = phone ? phone : ''
      newRegForm.password = pass ? pass : ''
      newRegForm.code = code ? parseInt(code) : 0
      setRegistrationForm(newRegForm)
    } else {
      setActiveStage(1)
      sessionStorage.setItem('regSt', '1')
      sessionStorage.removeItem('regPh')
      sessionStorage.removeItem('regPass')
      sessionStorage.removeItem('regCode')
    }
  },[])

  if (!activeStage){
    return <Spinner/>
  }

  return (
    <>
      <Helmet>
        <title>Регистрация</title>
      </Helmet>
      {codeError && <ErrorBlock text={codeError}/>}
      {invalidNumber && <ErrorBlock text={invalidNumber}/>}
      {!createOrganization ? 
      <div className='account__modal'>
        <div className='account__modal__content'>
          <div className='account__modal__text'>
            <h1 className='account__modal__title'>Регистрация</h1>
            {activeStage !== 1 && <p className='account__modal__p'>{activeStage === 2 ? 'Для завершения регистрации проследуйте инструкциям в Telegram-боте.' : 'Введите полученный код.'}</p>}
              {activeStage === 1 &&
                <form className='account__modal__form'>
                  <PhoneInput placeholderText="Телефон" value={registrationForm.phone} onChangeValue={(value: string) => { setRegistrationForm({ ...registrationForm, phone: value });sessionStorage.setItem('regPh', value) }} />
                  <PasswordInput changeInput={(e) => { setRegistrationForm({ ...registrationForm, password: e.target.value });sessionStorage.setItem('regPass', e.target.value) }} value={registrationForm.password} isRegistration />
                  <button onClick={toTwoStage} className='account__modal__submit' type='button'>Зарегистрироваться</button>
                </form>}
              {activeStage === 2 &&
              <>
              <button className="account__tgBot centerFlex pointer">
                <svg className="account__tgBot_img" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19.4002 7.8999L4.4502 13.0749L9.6252 14.7999M19.4002 7.8999L9.6252 14.7999M19.4002 7.8999L14.8002 19.9749L9.6252 14.7999" stroke="#0042E4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12.5 24C18.8512 24 24 18.8512 24 12.5C24 6.14872 18.8512 1 12.5 1C6.14872 1 1 6.14872 1 12.5C1 18.8512 6.14872 24 12.5 24Z" stroke="#0042E4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <a onClick={(e)=>{toThreeStage(e);window.open('https://t.me//ProStockTgBot', '_blank')}} href='https://t.me//ProStockTgBot' className="account__tgText">ProStock Bot</a>
              </button>
              <button onClick={toFirstStage} className="account__back centerFlex pointer">
                <svg className="account__tgBot_img" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.5 8.36602C0.833332 7.98112 0.833333 7.01887 1.5 6.63397L9.75 1.87083C10.4167 1.48593 11.25 1.96706 11.25 2.73686L11.25 12.2631C11.25 13.0329 10.4167 13.5141 9.75 13.1292L1.5 8.36602Z" fill="#424547" />
                </svg>
                <span className="account__backText">Вернуться</span>
              </button>
              </>}
            {activeStage === 1 && <p onClick={()=>{navigate('/login')}} className='haveAccount__text'>У меня уже есть аккаунт</p>}
            {activeStage === 3 && <CodeBlock isError={codeError ? true : false} changeCode={(code: number)=>{setRegistrationForm({...registrationForm, code: code}); sessionStorage.setItem('regCode', code.toString())}}/>}
            {activeStage === 3 && <>
              <button onClick={handleRegistration} className="account__registration centerFlex pointer">Подтвердить</button>
              <button onClick={toTwoStage} className="account__registrationBack centerFlex pointer">
                <svg className="account__tgBot_img" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.5 8.36602C0.833332 7.98112 0.833333 7.01887 1.5 6.63397L9.75 1.87083C10.4167 1.48593 11.25 1.96706 11.25 2.73686L11.25 12.2631C11.25 13.0329 10.4167 13.5141 9.75 13.1292L1.5 8.36602Z" fill="#424547" />
                </svg>
                <span className="account__backText">Вернуться</span>
              </button>
            </>}
            <p className='approval__text'>Используя систему товароучета, вы даете согласие на <span style={{ textDecorationLine: 'underline', cursor: 'pointer' }}>обработку персональных данных.</span></p>
          </div>
        </div>
      </div> :
      <div className='account__modal'>
        <div className='account__modal__content'>
          <div className='account__modal__text'>
            <h1 className='account__modal__title'>Спасибо за регистрацию</h1>
            <button onClick={()=>{dispatch(redirectToRoute('/'))}} className='account__modal__submit w345'>Создать первую организацию</button>
          </div>
        </div>
      </div>
      }
    </>
  )
}