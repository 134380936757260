type CustomSliderProps = {
  isActive: string | number | boolean;
  changeCheckbox: Function
  name: string
}

export default function CustomSliderbox({ isActive, changeCheckbox, name }: CustomSliderProps) {
  return (
    <>
      {!isActive ?
        <svg className='checkboxSvg' onClick={() => { changeCheckbox(false, name) }} width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.0718861 7.48985C0.0561173 7.38229 0.0428513 7.27376 0.0322482 7.16456C0.0428513 7.27388 0.0561173 7.38229 0.0718861 7.48985Z" fill="#D2DCF0" />
          <path d="M0.312494 8.53543C0.290472 8.46582 0.269539 8.39575 0.249746 8.32512C0.269539 8.3957 0.290472 8.46576 0.312494 8.53543Z" fill="#D2DCF0" />
          <path d="M4.38447 0.292264C4.43329 0.276461 4.48245 0.261225 4.53177 0.246612C4.97596 0.115149 5.44037 0.0337564 5.91893 0.00849473C6.02528 0.00288725 6.13235 5.60284e-05 6.24006 5.60284e-05C4.7323 5.60284e-05 3.34949 0.557058 2.2708 1.48438C2.88714 0.954447 3.60283 0.545447 4.38447 0.292264Z" fill="#D2DCF0" />
          <path d="M4.60001 12.7726C4.65884 12.7894 4.71801 12.8052 4.77755 12.82C4.74514 12.8119 4.71273 12.8039 4.68054 12.7953C4.65357 12.7881 4.62687 12.7802 4.60001 12.7726Z" fill="#D2DCF0" />
          <path d="M4.09449 12.6055C4.05811 12.5916 4.02211 12.5771 3.98612 12.5626C4.14506 12.6267 4.30709 12.6842 4.47206 12.7348C4.40573 12.7144 4.33983 12.6931 4.27436 12.6704C4.21406 12.6497 4.15397 12.6282 4.09449 12.6055Z" fill="#D2DCF0" />
          <path d="M0.177699 8.0443C0.171772 8.01887 0.165464 7.9935 0.159809 7.96795C0.18058 8.06164 0.203527 8.15441 0.228159 8.24645C0.222286 8.22453 0.216631 8.20244 0.210976 8.18041C0.199448 8.13521 0.188356 8.08984 0.177699 8.0443Z" fill="#D2DCF0" />
          <path d="M5.26415 12.9206C5.25218 12.9187 5.24033 12.9165 5.22842 12.9145C5.36099 12.937 5.4948 12.9553 5.62998 12.969C5.6253 12.9685 5.62063 12.9682 5.61595 12.9677C5.49769 12.9555 5.38045 12.9396 5.26415 12.9206Z" fill="#D2DCF0" />
          <path d="M4.94942 12.8604C4.93224 12.8566 4.91517 12.8526 4.89804 12.8487C4.95013 12.8606 5.00244 12.8718 5.05491 12.8824C5.0405 12.8795 5.02604 12.8768 5.01174 12.8738C4.99091 12.8694 4.9702 12.865 4.94942 12.8604Z" fill="#D2DCF0" />
          <path d="M5.89875 12.9894C6.0118 12.9963 6.1255 13 6.24001 13C6.13229 13 6.02528 12.9969 5.91887 12.9909C5.91218 12.9905 5.90544 12.9899 5.89875 12.9894Z" fill="#D2DCF0" />
          <path d="M0.0182158 7.00008C0.00614456 6.83503 5.54211e-05 6.66828 5.54211e-05 6.49994C5.54211e-05 6.7243 0.0109841 6.94599 0.032299 7.16451C0.0269159 7.1099 0.0222395 7.05508 0.0182158 7.00008Z" fill="#D2DCF0" />
          <path d="M13.44 6.5C13.44 9.2824 11.9819 11.7054 9.8218 13H17.76C21.2063 13 24 10.0899 24 6.5C24 2.91013 21.2063 0 17.76 0H9.82174C11.9819 1.29457 13.44 3.7176 13.44 6.5Z" fill="#D2DCF0" />
          <path d="M0.236693 4.72053C0.250721 4.6691 0.265294 4.61795 0.280519 4.56709C0.523629 3.75294 0.916217 3.0075 1.42489 2.36542C0.534723 3.489 -1.19209e-06 4.92942 -1.19209e-06 6.49995C-1.19209e-06 6.38774 0.00271845 6.27627 0.00810158 6.16543C0.0324072 5.66699 0.110597 5.18323 0.236693 4.72053Z" fill="#D2DCF0" />
          <path d="M2.56215 11.7509C2.48961 11.6957 2.41822 11.6389 2.34807 11.5805C2.41822 11.6388 2.48955 11.6956 2.56215 11.7509Z" fill="#D2DCF0" />
          <path d="M1.82765 1.9038C1.96881 1.75676 2.11665 1.61675 2.27075 1.48432C2.11671 1.61675 1.96881 1.75676 1.82765 1.9038Z" fill="#D2DCF0" />
          <path d="M0.211028 8.18041C0.216683 8.20244 0.222283 8.22447 0.22821 8.24645C0.235224 8.27279 0.24251 8.29895 0.249797 8.32506C0.269589 8.3957 0.290525 8.46582 0.312546 8.53537C0.323367 8.56958 0.334459 8.60362 0.345769 8.63755C0.36703 8.7011 0.389214 8.76426 0.412269 8.82684C0.467188 8.97592 0.527164 9.12234 0.592088 9.26598C0.628084 9.34561 0.665548 9.42429 0.704534 9.50211C1.03633 10.1646 1.47454 10.76 1.99556 11.2639C2.02688 11.2942 2.05852 11.3242 2.0905 11.3539C2.1744 11.4317 2.26031 11.5073 2.34818 11.5804C2.41832 11.6388 2.48972 11.6956 2.56225 11.7508C2.60885 11.7864 2.656 11.8212 2.70363 11.8554C2.9257 12.0148 3.15782 12.16 3.39941 12.2888C3.39974 12.2889 3.39995 12.2892 3.40022 12.2893C3.48668 12.3354 3.57433 12.3793 3.66307 12.4214C3.76921 12.4715 3.87704 12.5186 3.98622 12.5626C4.02222 12.5772 4.05827 12.5917 4.09459 12.6056C2.20028 11.8831 0.736507 10.2313 0.211028 8.18041Z" fill="#BEC8DC" />
          <path d="M0.0182138 7.00008C0.0222376 7.05507 0.0269152 7.10996 0.032244 7.16456C0.0429015 7.27382 0.0561131 7.38223 0.0718819 7.48984C0.0955893 7.65121 0.124898 7.81066 0.159806 7.96789C0.165461 7.99344 0.171769 8.01881 0.177696 8.04424C0.098472 7.70576 0.0443138 7.35702 0.0182138 7.00008Z" fill="#BEC8DC" />
          <path d="M4.27441 12.6705C4.33982 12.6931 4.40573 12.7145 4.47212 12.7349C4.51458 12.748 4.55727 12.7605 4.60006 12.7726C4.62687 12.7803 4.65362 12.7882 4.68059 12.7954C4.54319 12.7585 4.40785 12.7166 4.27441 12.6705Z" fill="#BEC8DC" />
          <path d="M5.01174 12.8737C5.02609 12.8768 5.04056 12.8795 5.05491 12.8824C5.11244 12.8939 5.17029 12.9046 5.22837 12.9145C5.24033 12.9165 5.25213 12.9187 5.26409 12.9206C5.17943 12.9068 5.09526 12.8911 5.01174 12.8737Z" fill="#BEC8DC" />
          <path d="M12.48 6.5C12.48 10.0899 9.68629 13 6.24001 13H9.82174C11.9819 11.7054 13.44 9.28246 13.44 6.5C13.44 3.71765 11.9819 1.29457 9.82174 0H6.24001C9.68629 0 12.48 2.91013 12.48 6.5Z" fill="#BEC8DC" />
          <path d="M5.616 12.9677C5.62068 12.9682 5.62536 12.9685 5.63003 12.969C5.7191 12.978 5.80865 12.9852 5.8987 12.9902C5.90544 12.9906 5.91213 12.9912 5.91887 12.9915C5.81725 12.9861 5.71633 12.9781 5.616 12.9677Z" fill="#BEC8DC" />
          <path d="M4.68054 12.7954C4.71268 12.804 4.74514 12.8119 4.77755 12.8201C4.81756 12.8301 4.85775 12.8396 4.89804 12.8488C4.91511 12.8528 4.93224 12.8567 4.94942 12.8605C4.85911 12.8407 4.76933 12.8191 4.68054 12.7954Z" fill="#BEC8DC" />
          <path d="M0.177696 8.0443C0.188354 8.08984 0.199391 8.13521 0.210974 8.18035C0.736453 10.2312 2.20028 11.8831 4.09448 12.6055C4.15402 12.6282 4.21406 12.6496 4.27436 12.6705C4.40785 12.7166 4.54324 12.7585 4.68048 12.7954C4.76933 12.8191 4.85905 12.8407 4.94937 12.8605C4.97014 12.8651 4.99091 12.8695 5.01168 12.8738C5.09525 12.8912 5.17937 12.9069 5.26409 12.9207C5.38034 12.9397 5.49768 12.9555 5.616 12.9678C5.71632 12.9781 5.81724 12.9862 5.91887 12.9916C6.02523 12.9972 6.13229 13 6.24001 13C9.68629 13 12.48 10.0899 12.48 6.5C12.48 2.91013 9.68629 0 6.24001 0C6.13229 0 6.02528 0.00283146 5.91887 0.00843906C5.44026 0.0337009 4.9759 0.115207 4.53171 0.246556C4.48234 0.261169 4.43324 0.276349 4.38441 0.292208C3.60283 0.545447 2.88709 0.954391 2.2708 1.48426C2.1167 1.61674 1.96881 1.75676 1.8277 1.90374C1.68655 2.05072 1.55213 2.20479 1.425 2.36531C0.916325 3.00738 0.523738 3.75288 0.280628 4.56698C0.265457 4.61784 0.250831 4.66904 0.236802 4.72041C0.110598 5.18311 0.0324602 5.66688 0.00820923 6.16537C0.00282574 6.27616 0.000107765 6.38768 0.000107765 6.49989C0.000107765 6.66811 0.00625134 6.83497 0.0182686 7.00002C0.0443144 7.35703 0.0984716 7.70576 0.177696 8.0443ZM6.24001 1.99997C8.62593 1.99997 10.56 4.01467 10.56 6.49994C10.56 8.98521 8.62582 10.9999 6.24001 10.9999C3.85409 10.9999 1.92003 8.98521 1.92003 6.49994C1.92003 4.01473 3.85409 1.99997 6.24001 1.99997Z" fill="#E4EAF6" />
          <path d="M6.24001 2.00007C3.85415 2.00007 1.92003 4.01477 1.92003 6.50004C1.92003 8.9853 3.85415 11 6.24001 11C8.62587 11 10.56 8.9853 10.56 6.50004C10.56 4.01477 8.62587 2.00007 6.24001 2.00007Z" fill="white" />
        </svg> :
        <svg className='checkboxSvg' onClick={() => { changeCheckbox(true, name) }} width="24" height="13" viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.9281 5.51015C23.9439 5.61771 23.9572 5.72624 23.9678 5.83544C23.9572 5.72612 23.9439 5.61771 23.9281 5.51015Z" fill="#007BFF" />
          <path d="M23.6875 4.46457C23.7095 4.53418 23.7305 4.60425 23.7503 4.67488C23.7305 4.6043 23.7095 4.53424 23.6875 4.46457Z" fill="#007BFF" />
          <path d="M19.6155 12.7077C19.5667 12.7235 19.5175 12.7388 19.4682 12.7534C19.024 12.8849 18.5596 12.9662 18.0811 12.9915C17.9747 12.9971 17.8677 12.9999 17.7599 12.9999C19.2677 12.9999 20.6505 12.4429 21.7292 11.5156C21.1129 12.0456 20.3972 12.4546 19.6155 12.7077Z" fill="#007BFF" />
          <path d="M19.4 0.227352C19.3412 0.210643 19.282 0.19484 19.2225 0.18C19.2549 0.1881 19.2873 0.196086 19.3195 0.204696C19.3464 0.211889 19.3731 0.219762 19.4 0.227352Z" fill="#007BFF" />
          <path d="M19.9055 0.3945C19.9419 0.408377 19.9779 0.422876 20.0139 0.437433C19.8549 0.373316 19.6929 0.315827 19.5279 0.26519C19.5943 0.285581 19.6602 0.306934 19.7256 0.32959C19.7859 0.350264 19.846 0.371787 19.9055 0.3945Z" fill="#007BFF" />
          <path d="M23.8223 4.9557C23.8282 4.98113 23.8345 5.0065 23.8402 5.03205C23.8194 4.93836 23.7965 4.84559 23.7718 4.75355C23.7777 4.77547 23.7834 4.79756 23.789 4.81959C23.8006 4.86479 23.8116 4.91016 23.8223 4.9557Z" fill="#007BFF" />
          <path d="M18.7359 0.0793505C18.7478 0.0812763 18.7597 0.0834857 18.7716 0.0855248C18.639 0.0630385 18.5052 0.0446867 18.37 0.0310364C18.3747 0.0315461 18.3794 0.0318299 18.384 0.0323397C18.5023 0.0445174 18.6195 0.0603759 18.7359 0.0793505Z" fill="#007BFF" />
          <path d="M19.0506 0.139614C19.0678 0.143352 19.0848 0.147375 19.102 0.151283C19.0499 0.139389 18.9976 0.128173 18.9451 0.117638C18.9595 0.120526 18.974 0.123245 18.9883 0.126247C19.0091 0.130609 19.0298 0.135026 19.0506 0.139614Z" fill="#007BFF" />
          <path d="M18.1012 0.00979903C17.9882 0.00339866 17.8745 0 17.76 0C17.8677 0 17.9747 0.00283128 18.0811 0.00843868C18.0878 0.00877852 18.0946 0.00940255 18.1012 0.00979903Z" fill="#007BFF" />
          <path d="M23.9818 5.99992C23.9939 6.16497 23.9999 6.33172 23.9999 6.50006C23.9999 6.2757 23.989 6.05401 23.9677 5.83549C23.9731 5.8901 23.9778 5.94492 23.9818 5.99992Z" fill="#007BFF" />
          <path d="M10.56 6.5C10.56 3.7176 12.0181 1.29457 14.1782 0L6.24001 0C2.79373 0 0 2.91013 0 6.5C0 10.0899 2.79373 13 6.24001 13H14.1783C12.0181 11.7054 10.56 9.2824 10.56 6.5Z" fill="#007BFF" />
          <path d="M23.7633 8.27947C23.7493 8.3309 23.7347 8.38205 23.7195 8.43291C23.4764 9.24706 23.0838 9.99251 22.5751 10.6346C23.4653 9.51101 24 8.07058 24 6.50006C24 6.61226 23.9973 6.72373 23.9919 6.83458C23.9676 7.33301 23.8894 7.81678 23.7633 8.27947Z" fill="#007BFF" />
          <path d="M21.4379 1.24909C21.5104 1.30432 21.5818 1.36107 21.6519 1.41952C21.5818 1.36118 21.5104 1.30437 21.4379 1.24909Z" fill="#007BFF" />
          <path d="M22.1724 11.0962C22.0312 11.2432 21.8833 11.3833 21.7292 11.5157C21.8833 11.3833 22.0312 11.2432 22.1724 11.0962Z" fill="#007BFF" />
          <path d="M23.789 4.81959C23.7833 4.79756 23.7777 4.77553 23.7718 4.75355C23.7648 4.72721 23.7575 4.70105 23.7502 4.67494C23.7304 4.6043 23.7095 4.53418 23.6874 4.46463C23.6766 4.43042 23.6655 4.39638 23.6542 4.36245C23.633 4.2989 23.6108 4.23574 23.5877 4.17316C23.5328 4.02408 23.4728 3.87766 23.4079 3.73402C23.3719 3.65439 23.3344 3.57571 23.2955 3.49789C22.9637 2.83537 22.5255 2.23996 22.0044 1.73609C21.9731 1.70579 21.9415 1.67577 21.9095 1.64614C21.8256 1.56826 21.7397 1.49271 21.6518 1.41958C21.5817 1.36119 21.5103 1.30438 21.4377 1.24915C21.3911 1.21364 21.344 1.1788 21.2964 1.14459C21.0743 0.985206 20.8422 0.840037 20.6006 0.71118C20.6003 0.711066 20.6 0.71084 20.5998 0.710727C20.5133 0.664621 20.4257 0.620668 20.3369 0.578641C20.2308 0.528458 20.123 0.481447 20.0138 0.437381C19.9778 0.422824 19.9417 0.408324 19.9054 0.394447C21.7997 1.1169 23.2635 2.7687 23.789 4.81959Z" fill="#106CD0" />
          <path d="M23.9818 5.99992C23.9778 5.94493 23.9731 5.89004 23.9678 5.83544C23.9571 5.72618 23.9439 5.61777 23.9281 5.51016C23.9044 5.34879 23.8751 5.18934 23.8402 5.03211C23.8345 5.00656 23.8282 4.98119 23.8223 4.95576C23.9015 5.29424 23.9557 5.64298 23.9818 5.99992Z" fill="#106CD0" />
          <path d="M19.7256 0.329479C19.6602 0.30688 19.5943 0.285468 19.5279 0.265078C19.4854 0.251994 19.4427 0.239477 19.3999 0.227356C19.3731 0.21971 19.3464 0.211837 19.3194 0.204643C19.4568 0.24146 19.5921 0.283374 19.7256 0.329479Z" fill="#106CD0" />
          <path d="M18.9883 0.126252C18.9739 0.12325 18.9594 0.120474 18.9451 0.117642C18.8876 0.106144 18.8297 0.0953831 18.7716 0.0855276C18.7597 0.0835452 18.7479 0.0813357 18.7359 0.0793533C18.8206 0.0932302 18.9047 0.108863 18.9883 0.126252Z" fill="#106CD0" />
          <path d="M11.52 6.5C11.52 2.91013 14.3137 0 17.76 0L14.1783 0C12.0181 1.29457 10.56 3.71754 10.56 6.5C10.56 9.28235 12.0181 11.7054 14.1783 13H17.76C14.3137 13 11.52 10.0899 11.52 6.5Z" fill="#106CD0" />
          <path d="M18.384 0.0322889C18.3793 0.0317791 18.3746 0.0314953 18.37 0.0309856C18.2809 0.0219798 18.1913 0.0148435 18.1013 0.00980246C18.0946 0.00940597 18.0879 0.00878235 18.0811 0.00849915C18.1828 0.01388 18.2837 0.0219237 18.384 0.0322889Z" fill="#106CD0" />
          <path d="M19.3195 0.20464C19.2873 0.19603 19.2549 0.1881 19.2225 0.179944C19.1824 0.169919 19.1423 0.160403 19.102 0.151171C19.0849 0.147206 19.0678 0.143298 19.0506 0.139503C19.1409 0.159328 19.2307 0.180851 19.3195 0.20464Z" fill="#106CD0" />
          <path d="M23.8223 4.9557C23.8116 4.91016 23.8006 4.86479 23.789 4.81965C23.2635 2.76876 21.7997 1.11695 19.9055 0.3945C19.846 0.371788 19.7859 0.350379 19.7256 0.329478C19.5922 0.283373 19.4568 0.241459 19.3195 0.204642C19.2307 0.180853 19.1409 0.15933 19.0506 0.139506C19.0299 0.134918 19.0091 0.130499 18.9883 0.126195C18.9047 0.108806 18.8206 0.0931165 18.7359 0.0792963C18.6197 0.0603217 18.5023 0.0444628 18.384 0.0322284C18.2837 0.0218632 18.1828 0.0137646 18.0811 0.00844041C17.9748 0.002833 17.8677 0 17.76 0C14.3137 0 11.52 2.91013 11.52 6.5C11.52 10.0899 14.3137 13 17.76 13C17.8677 13 17.9747 12.9972 18.0811 12.9916C18.5597 12.9663 19.0241 12.8848 19.4683 12.7534C19.5177 12.7388 19.5668 12.7237 19.6156 12.7078C20.3972 12.4546 21.1129 12.0456 21.7292 11.5157C21.8833 11.3833 22.0312 11.2432 22.1723 11.0963C22.3135 10.9493 22.4479 10.7952 22.575 10.6347C23.0837 9.99262 23.4763 9.24712 23.7194 8.43302C23.7345 8.38216 23.7492 8.33096 23.7632 8.27959C23.8894 7.81689 23.9675 7.33312 23.9918 6.83463C23.9972 6.72384 23.9999 6.61232 23.9999 6.50011C23.9999 6.33189 23.9937 6.16503 23.9817 5.99998C23.9557 5.64297 23.9015 5.29424 23.8223 4.9557ZM17.76 11C15.3741 11 13.44 8.98533 13.44 6.50006C13.44 4.01479 15.3742 2.00009 17.76 2.00009C20.1459 2.00009 22.08 4.01479 22.08 6.50006C22.08 8.98527 20.1459 11 17.76 11Z" fill="#67A6EA" />
          <path d="M17.76 10.9999C20.1458 10.9999 22.08 8.98523 22.08 6.49996C22.08 4.0147 20.1458 1.99999 17.76 1.99999C15.3741 1.99999 13.44 4.0147 13.44 6.49996C13.44 8.98523 15.3741 10.9999 17.76 10.9999Z" fill="white" />
        </svg>
      }
    </>
  )
}