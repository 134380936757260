import { useRef, useState } from 'react'
import './edit-block.css'

type EditBlockProps = {
  value: string,
  valueStyle: any
  onChange: (value: string) => void
  name: string
  isActive: boolean
}

export default function EditBlock({ value, onChange, valueStyle, name, isActive }: EditBlockProps) {

  const container = useRef<HTMLDivElement>(null)

  const [displayKey, setDisplayKey] = useState(false)

  const changeValue = (value: string) => {
    onChange(value)
  }

  return (
    <div ref={container}  className={`editInput__container`}>
      {isActive ? <input onChange={(e) => { changeValue(e.target.value) }} style={valueStyle} className='editInput__textInputActive' type='text' defaultValue={value} /> :
        name !== 'Название:' && <p onMouseEnter={()=>{setDisplayKey(true)}} onMouseLeave={()=>{setDisplayKey(false)}} className={`pointer editInput__disableInput`}>{name}</p>}
      {!isActive && displayKey &&<p onMouseEnter={()=>{setDisplayKey(true)}} className='editBlock__valueBlock'>{value ? value : name}</p>}
    </div>
  )
}