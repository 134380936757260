import './dropdown-list.css'

type DropdownListPointProps = {
  value: string
  isActive: boolean
  type: 'checkbox' | 'radio'
  onClickValue: (value: string, isAdd? : boolean) => void
}

export default function DropdownListPoint({ value, isActive, type, onClickValue }: DropdownListPointProps) {
  return (
    <div onClick={()=>{onClickValue(value, !isActive)}} className='dropdownListPoint__point'>
      {type === 'radio' ? <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6" cy="6" r="5.5" fill="white" stroke={isActive ? "#007BFF" : "#C6C6C6"} />
        {isActive && <path d="M8.5 6C8.5 7.38071 7.38071 8.5 6 8.5C4.61929 8.5 3.5 7.38071 3.5 6C3.5 4.61929 4.61929 3.5 6 3.5C7.38071 3.5 8.5 4.61929 8.5 6Z" fill="#007BFF" />}
      </svg> :
        !isActive ? <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" fill="white" stroke="#C6C6C6" />
        </svg> :
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 3.00019V12.0002C0 13.0503 -6.98492e-08 13.5751 0.204366 13.9762C0.384122 14.3289 0.670753 14.6161 1.02356 14.7958C1.42425 15 1.94905 15 2.9971 15H12.0029C13.0509 15 13.575 15 13.9757 14.7958C14.3285 14.6161 14.6161 14.3289 14.7958 13.9762C15 13.5755 15 13.0514 15 12.0034V2.9971C15 1.94905 15 1.42425 14.7958 1.02356C14.6161 0.670753 14.3285 0.384122 13.9757 0.204366C13.5746 -6.98492e-08 13.0503 0 12.0002 0H3.00019C1.95008 0 1.42464 -6.98492e-08 1.02356 0.204366C0.670753 0.384122 0.384122 0.670753 0.204366 1.02356C-6.98492e-08 1.42464 0 1.95008 0 3.00019Z" fill="#004AFF"/>
          <mask id="path-2-inside-1_0_259" fill="white">
          <path d="M12.0802 4.38827L6.29226 10.1762L3.50436 7.38827C3.38706 7.27097 3.19746 7.27097 3.08016 7.38827C2.96286 7.50557 2.96286 7.69517 3.08016 7.81247L6.08016 10.8125C6.13866 10.871 6.21546 10.9004 6.29226 10.9004C6.36906 10.9004 6.44586 10.871 6.50436 10.8125L12.5044 4.81247C12.6217 4.69517 12.6217 4.50557 12.5044 4.38827C12.3871 4.27097 12.1972 4.27097 12.0802 4.38827Z"/>
          </mask>
          <path d="M12.0802 4.38827L6.29226 10.1762L3.50436 7.38827C3.38706 7.27097 3.19746 7.27097 3.08016 7.38827C2.96286 7.50557 2.96286 7.69517 3.08016 7.81247L6.08016 10.8125C6.13866 10.871 6.21546 10.9004 6.29226 10.9004C6.36906 10.9004 6.44586 10.871 6.50436 10.8125L12.5044 4.81247C12.6217 4.69517 12.6217 4.50557 12.5044 4.38827C12.3871 4.27097 12.1972 4.27097 12.0802 4.38827Z" fill="white"/>
          <path d="M12.0802 4.38827L15.6157 7.9238L15.6202 7.91927L12.0802 4.38827ZM6.29226 10.1762L2.75673 13.7117L6.29226 17.2472L9.8278 13.7117L6.29226 10.1762ZM3.50436 7.38827L-0.0311718 10.9238H-0.0311716L3.50436 7.38827ZM3.08016 7.81247L-0.455372 11.348H-0.455371L3.08016 7.81247ZM6.08016 10.8125L9.6157 7.27693V7.27693L6.08016 10.8125ZM6.50436 10.8125L10.0399 14.348L6.50436 10.8125ZM12.5044 4.81247L8.96883 1.27693L12.5044 4.81247ZM8.54463 0.852735L2.75673 6.64064L9.8278 13.7117L15.6157 7.9238L8.54463 0.852735ZM9.8278 6.64063L7.0399 3.85273L-0.0311716 10.9238L2.75673 13.7117L9.8278 6.64063ZM7.0399 3.85273C4.96997 1.78281 1.61455 1.78281 -0.455372 3.85273L6.6157 10.9238C4.78038 12.7591 1.80415 12.7591 -0.0311718 10.9238L7.0399 3.85273ZM-0.455372 3.85273C-2.52529 5.92266 -2.52529 9.27808 -0.455372 11.348L6.6157 4.27693C8.45102 6.11226 8.45102 9.08848 6.6157 10.9238L-0.455372 3.85273ZM-0.455371 11.348L2.54463 14.348L9.6157 7.27693L6.6157 4.27693L-0.455371 11.348ZM2.54463 14.348C3.57629 15.3797 4.94042 15.9004 6.29226 15.9004V5.90037C7.49051 5.90037 8.70103 6.36227 9.6157 7.27693L2.54463 14.348ZM6.29226 15.9004C7.64412 15.9004 9.00824 15.3797 10.0399 14.348L2.96883 7.27693C3.88348 6.36228 5.094 5.90037 6.29226 5.90037V15.9004ZM10.0399 14.348L16.0399 8.348L8.96883 1.27693L2.96883 7.27693L10.0399 14.348ZM16.0399 8.348C18.1098 6.27808 18.1098 2.92266 16.0399 0.852734L8.96883 7.9238C7.13351 6.08848 7.13351 3.11226 8.96883 1.27693L16.0399 8.348ZM16.0399 0.852734C13.9725 -1.21468 10.6133 -1.2212 8.5401 0.857264L15.6202 7.91927C13.7811 9.76314 10.8016 9.75662 8.96883 7.9238L16.0399 0.852734Z" fill="white" mask="url(#path-2-inside-1_0_259)"/>
          </svg>          
      }
      <span className='dropdownListPoint__text'>{value}</span>
    </div>
  )
}