import { useEffect, useState } from 'react';
import axios, { PagesURl } from "../../services/api";
import './selling-page.css'
import { CreateSelling, SoldSelling, Selling, TableSelling } from '../../types/selling-model';
import { checkCreateSelling } from '../../utils/selling';
import SellingFilterTable from '../../components/filter-table/selling-filter-table/selling-filter-table';
import Spinner from '../../components/spinner/spinner';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FileInput from '../../components/custom-input/file-input/file-input';
import { Helmet } from 'react-helmet-async';
import { SortData } from '../../types/main';
import { getTotalStages } from '../../utils/filter-table';
import { useNavigate } from 'react-router-dom';
import { getOrganization } from '../../services/organization';
import { useAppSelector } from '../../hooks';
import { getStateActiveOrganization } from '../../store/organization-process/selectors';
import excelAxios from 'axios';
import { getToken } from '../../services/token';
import { getUserRoles } from '../../store/user-process/selectors';
import { stageList } from '../../consts';
import PaginationBlock from '../../components/pagination-block/pagination-block';
import { ChildShipment } from '../../types/shipment-model';
import { getAllMarketShipment } from '../../utils/shipment';
import SellingModalWindow from '../../components/modal-window/selling/selling-modal-window';
import ControlPanel from '../../components/control-panel/control-panel';
import SynchronizationBlock from '../../components/synchronization-block/synchronization-block';
import { getDataValue } from '../../utils';

function SellingPage() {

  const navigate = useNavigate()
  useEffect(() => {
    if (!getOrganization()) {
      navigate('/')
    }
  })

  const user = useAppSelector(getUserRoles)

  const activeOrganization = useAppSelector(getStateActiveOrganization)

  const baseUrl = PagesURl.SELLING

  const [isGetAllSellingLoading, setIsGetAllSellingLoading] = useState(true)

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const [isChangeModalOpen, setIsChangeModalOpen] = useState(false)

  const [selling, setSelling] = useState<Selling[]>([])
  const [tableSelling, setTableSelling] = useState<TableSelling[] | null>(null)
  const [activeSelling, setActiveSelling] = useState<Selling | null>(null)
  const [shimpents, setShipments] = useState<ChildShipment[] | null>(null)

  const [activeStage, setActiveStage] = useState<number | null>(null)
  const [countRows, setCountRows] = useState<number>(stageList[0])
  const [activeFilter, setActiveFilter] = useState<SortData>({ take: countRows, skip: 0 })
  const [sellingCount, setSellingCount] = useState(0)

  const notify = (message: string) => toast(message);

  const fetchSelling = async (data: SortData = { take: countRows, skip: 0 }) => {
    setIsGetAllSellingLoading(true)
    try {
      const response = await axios.post<{ data: Selling[], totalCount: number }>(baseUrl + '/find', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setSelling(response.data.data)
      let result: TableSelling[] = []
      for (let sell of response.data.data) {
        result.push({
          id: sell.id,
          organizationName: sell.organizationName,
          shipmentNumber: sell.shipmentNumber,
          productBarcode: sell.productBarcode,
          quantity: sell.quantity,
          marketplaceType: sell.marketplaceType,
          soldAt: getDataValue(sell.soldAt)
        })
      }
      setTableSelling(result)
      setSellingCount(response.data.totalCount)
      setActiveFilter(data)
      if (!activeStage) {
        setActiveStage(response.data.totalCount > data.take ? 1 : null)
      }
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)) {
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }

  const changeActiveStage = (toStage: number) => {
    let newSortData: SortData = JSON.parse(JSON.stringify(activeFilter))
    newSortData.skip = countRows * toStage
    fetchSelling(newSortData)
    setActiveStage(toStage + 1)
  }

  useEffect(() => {
    fetchSelling()
  }, []);

  async function handleCreateSubmit(data: CreateSelling) {
    await axios.post(baseUrl + '/create', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        fetchSelling()
      })
      .catch((error: any) => {
        if (error.response.data.match(/Message = (.*?),/)) {
          notify(error.response.data.match(/Message = (.*?),/)[1])
        }
      });
  }
  async function handleSoldSubmit(data: SoldSelling) {
    await axios.post(baseUrl + '/sold', null, {
      params: {
        sellingId: data.sellingId
      }
    })
      .then((response) => {
        // Здесь может быть логика для обновления статуса продажи в интерфейсе или других действий
      })
      .catch((error: any) => {
        if (error.response.data.match(/Message = (.*?),/)) {
          notify(error.response.data.match(/Message = (.*?),/)[1])
        }
      });
  }
  const handleSubmitFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    if (e.target.files) {
      for (let i in e.target.files) {
        if (!isNaN(parseInt(i))) {
          formData.append("excelFiles", e.target.files[i])
        }
      }
    }
    await excelAxios.post(
      PagesURl.ExternalData + `/sale/parse/excel`,
      formData,
      {
        headers: {
          "accept": "application/json",
          "Content-type": "multipart/form-data",
          "Authorization": 'Bearer ' + getToken()
        },
      }).then(() => fetchSelling()).catch((error: any) => {
        if (error.response.data.match(/Message = (.*?),/)) {
          notify(error.response.data.match(/Message = (.*?),/)[1])
        }
      });

  }
  const handleChangeSell = async (id: string, data: CreateSelling) => {
    await axios.put(baseUrl + '/edit', { ...data, id: id }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        fetchSelling()
      })
      .catch((error: any) => {
        if (error.response.data.match(/Message = (.*?),/)) {
          notify(error.response.data.match(/Message = (.*?),/)[1])
        }
      });
  }

  const getSellingInfo = (sellidId: string) => {
  }

  const handleDeleteSelling = async (id: string) => {
    try {
      await axios.delete(baseUrl + `/delete?saleId=${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(() => { fetchSelling() })
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)) {
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }
  const handleSynchronizeWb = async () => {
    if (activeOrganization) {
      await axios.post(PagesURl.ExternalData + `/sale/create/byWBApi?organizationId=${activeOrganization.id}`)
      fetchSelling()
    }
  }
  const handleSynchronizeOzon = async () => {
    if (activeOrganization) {
      await axios.post(PagesURl.ExternalData + `/sale/create/byOzonApi?organizationId=${activeOrganization.id}`)
      fetchSelling()
    }
  }

  const openChangeModal = (id: string) => {
    getAllMarketShipment()
      .then((data) => {
        if (data){
          setShipments(data)
        }
      })
      .catch((error: any) => {
        if (error.response.data.match(/Message = (.*?),/)) {
          notify(error.response.data.match(/Message = (.*?),/)[1])
        }
      });
    setActiveSelling(selling.filter((sell) => (sell.id === id))[0])
    setIsChangeModalOpen(true)
  }
  const getSelectedProduct = (activeShipmentNumber: string, productBar: number) => {
    if (shimpents) {
      const product = shimpents.filter((shipment) => (shipment.number === activeShipmentNumber))[0].products.filter((product) => (product.productBarcode === productBar))[0]
      return { id: product.productBarcode, name: product.productName }
    }
    return ''
  }

  const changeSelectedValue = (newValue: number) => {
    setActiveStage(null)
    setCountRows(newValue)
    fetchSelling({ take: newValue, skip: 0 })
  }

  if (!user) {
    return <Spinner />
  }

  return (
    <>
      <Helmet>
        <title>Продажи</title>
      </Helmet>
      <div className="selling-container">
        <div className='flex selling__buttonsBlock'>
          {user.role > 0 &&
            <ControlPanel
              content={[{
                title: 'Панель управления',
                data: <div>
                  <div className='flex synchronizeBlock'>
                    <FileInput onUploadFiles={handleSubmitFile} />
                  </div>
                </div>
              },
              {
                title: 'Синхронизация',
                data: <>
                  <SynchronizationBlock isActive={activeOrganization?.wbApiKey ? true : false} text='Cинхронизация с WB' onSynchronization={() => { handleSynchronizeWb() }} />
                  <SynchronizationBlock isActive={activeOrganization?.ozonApiKey && activeOrganization.ozonClientId ? true : false} text='Cинхронизация с OZON' onSynchronization={() => { handleSynchronizeOzon() }} />
                </>
              }
              ]}
            />}
        </div>
        {tableSelling &&
          <PaginationBlock
            onChangeSelectedValue={changeSelectedValue}
            activeStage={activeStage}
            getTotalStages={() => { return getTotalStages(sellingCount, countRows) }}
            countRows={countRows}
            changeActiveStage={changeActiveStage}
            children={isGetAllSellingLoading ?
                <SellingFilterTable onChangeSelling={openChangeModal} isEdit={user.role > 1} activeFilter={activeFilter} onFilter={fetchSelling} onDeleteSelling={handleDeleteSelling} content={tableSelling} getPointInfo={getSellingInfo} /> :  <Spinner />
            }
          />}
        {shimpents &&
          <SellingModalWindow
            isOpen={isCreateModalOpen}
            title='Создание продажи'
            btnText='Создать'
            fields={[
              {
                name: 'Номер поставки',
                backName: 'shipmentNumber',
                type: 'select',
                options: shimpents
              },
              {
                name: 'Товар',
                backName: 'productBarcode',
                type: 'select',
              },
              {
                name: 'Количество',
                backName: 'quantity',
                type: 'number'
              },
              {
                name: 'Дата продажи',
                backName: 'soldAt',
                type: 'date'
              }
            ]}
            onRequestClose={() => { setIsCreateModalOpen(false) }}
            onSubmit={handleCreateSubmit}
            checkFields={checkCreateSelling}
          />}
        {activeSelling && shimpents &&
          <SellingModalWindow
            isOpen={isChangeModalOpen}
            title='Редактирование продажи'
            btnText='Создать'
            fields={[
              {
                name: 'Номер поставки',
                backName: 'shipmentNumber',
                type: 'select',
                options: shimpents,
                value: activeSelling.shipmentNumber
              },
              {
                name: 'Товар',
                backName: 'productBarcode',
                type: 'select',
                value: getSelectedProduct(activeSelling.shipmentNumber, activeSelling.productBarcode)
              },
              {
                name: 'Количество',
                backName: 'quantity',
                type: 'number',
                value: activeSelling.quantity
              },
              {
                name: 'Дата продажи',
                backName: 'soldAt',
                type: 'date',
                value: activeSelling.soldAt
              }
            ]}
            onRequestClose={() => { setIsChangeModalOpen(false) }}
            onSubmit={(data: CreateSelling) => { handleChangeSell(activeSelling.id, data) }}
            checkFields={checkCreateSelling}
          />}
      </div>
    </>
  );
}

export default SellingPage;
