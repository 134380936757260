export const Links  = {
  cabinet: 'Личный кабинет',
  organization:'Организации',
  warehouses: 'Склады',
  products:'Продукты',
  accounting : 'Товароучет',
  warehouseshipment : 'Поставки до склада',
  marketshipment : 'Поставки до маркетплейса',
  selling : 'Продажи',
  metrics : 'Метрики',
  warehouse: 'Склады'
}