import { useRef } from 'react'
import './file-input.css'
import { toast } from 'react-toastify'

type FileInputPRops = {
  onUploadFiles: (e: React.ChangeEvent<HTMLInputElement>) => void
  displayPattern?: boolean
}

export default function FileInput({ onUploadFiles, displayPattern }: FileInputPRops) {

  const notify = (message: string) => toast(message);

  const inputRef = useRef<HTMLInputElement>(null)

  const onClickText = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  const uploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    notify('Данные синхронизируются, пожалуйста подождите')
    onUploadFiles(e)
  }

  return (
    <>
      <div className='fileInput__container'>
        <div className='fileInput__download'>
          <span onClick={onClickText} className='fileInput__text'>Загрузить файлы</span>
          <input ref={inputRef} className='fileInput__input' onChange={uploadFiles} multiple type='file' accept='.xls,.xlsx' />
          <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.29 11.29C5.19627 11.383 5.12188 11.4936 5.07111 11.6154C5.02034 11.7373 4.9942 11.868 4.9942 12C4.9942 12.132 5.02034 12.2627 5.07111 12.3846C5.12188 12.5064 5.19627 12.617 5.29 12.71L8.29 15.71C8.38296 15.8037 8.49356 15.8781 8.61542 15.9289C8.73728 15.9797 8.86799 16.0058 9 16.0058C9.13201 16.0058 9.26272 15.9797 9.38458 15.9289C9.50644 15.8781 9.61704 15.8037 9.71 15.71L12.71 12.71C12.8983 12.5217 13.0041 12.2663 13.0041 12C13.0041 11.7337 12.8983 11.4783 12.71 11.29C12.5217 11.1017 12.2663 10.9959 12 10.9959C11.7337 10.9959 11.4783 11.1017 11.29 11.29L10 12.59V1C10 0.734784 9.89464 0.48043 9.70711 0.292893C9.51957 0.105357 9.26522 0 9 0C8.73478 0 8.48043 0.105357 8.29289 0.292893C8.10536 0.48043 8 0.734784 8 1V12.59L6.71 11.29C6.61704 11.1963 6.50644 11.1219 6.38458 11.0711C6.26272 11.0203 6.13201 10.9942 6 10.9942C5.86799 10.9942 5.73728 11.0203 5.61542 11.0711C5.49356 11.1219 5.38296 11.1963 5.29 11.29ZM15 7H13C12.7348 7 12.4804 7.10536 12.2929 7.29289C12.1054 7.48043 12 7.73478 12 8C12 8.26522 12.1054 8.51957 12.2929 8.70711C12.4804 8.89464 12.7348 9 13 9H15C15.2652 9 15.5196 9.10536 15.7071 9.29289C15.8946 9.48043 16 9.73478 16 10V17C16 17.2652 15.8946 17.5196 15.7071 17.7071C15.5196 17.8946 15.2652 18 15 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V10C2 9.73478 2.10536 9.48043 2.29289 9.29289C2.48043 9.10536 2.73478 9 3 9H5C5.26522 9 5.51957 8.89464 5.70711 8.70711C5.89464 8.51957 6 8.26522 6 8C6 7.73478 5.89464 7.48043 5.70711 7.29289C5.51957 7.10536 5.26522 7 5 7H3C2.20435 7 1.44129 7.31607 0.87868 7.87868C0.316071 8.44129 0 9.20435 0 10V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H15C15.7956 20 16.5587 19.6839 17.1213 19.1213C17.6839 18.5587 18 17.7956 18 17V10C18 9.20435 17.6839 8.44129 17.1213 7.87868C16.5587 7.31607 15.7956 7 15 7Z" fill="#0042E4" />
          </svg>
        </div>
        {displayPattern && <div className='fileInput__download' >
          <a className='fileInput__text' href='patterns/шаблон_продукты.xlsx' download>Скачать шаблон</a>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.71 10.79C12.6149 10.699 12.5028 10.6276 12.38 10.58C12.1365 10.48 11.8635 10.48 11.62 10.58C11.4972 10.6276 11.3851 10.699 11.29 10.79L9.29 12.79C9.1017 12.9783 8.99591 13.2337 8.99591 13.5C8.99591 13.7663 9.1017 14.0217 9.29 14.21C9.4783 14.3983 9.7337 14.5041 10 14.5041C10.2663 14.5041 10.5217 14.3983 10.71 14.21L11 13.91V16.5C11 16.7652 11.1054 17.0196 11.2929 17.2071C11.4804 17.3947 11.7348 17.5 12 17.5C12.2652 17.5 12.5196 17.3947 12.7071 17.2071C12.8946 17.0196 13 16.7652 13 16.5V13.91L13.29 14.21C13.383 14.3038 13.4936 14.3781 13.6154 14.4289C13.7373 14.4797 13.868 14.5058 14 14.5058C14.132 14.5058 14.2627 14.4797 14.3846 14.4289C14.5064 14.3781 14.617 14.3038 14.71 14.21C14.8037 14.1171 14.8781 14.0065 14.9289 13.8846C14.9797 13.7627 15.0058 13.632 15.0058 13.5C15.0058 13.368 14.9797 13.2373 14.9289 13.1154C14.8781 12.9936 14.8037 12.883 14.71 12.79L12.71 10.79ZM19 5.50002H12.72L12.4 4.50002C12.1926 3.91325 11.8077 3.40553 11.2989 3.04718C10.7901 2.68884 10.1824 2.49762 9.56 2.50002H5C4.20435 2.50002 3.44129 2.81609 2.87868 3.3787C2.31607 3.94131 2 4.70437 2 5.50002V18.5C2 19.2957 2.31607 20.0587 2.87868 20.6213C3.44129 21.184 4.20435 21.5 5 21.5H19C19.7956 21.5 20.5587 21.184 21.1213 20.6213C21.6839 20.0587 22 19.2957 22 18.5V8.50002C22 7.70437 21.6839 6.94131 21.1213 6.3787C20.5587 5.81609 19.7956 5.50002 19 5.50002ZM20 18.5C20 18.7652 19.8946 19.0196 19.7071 19.2071C19.5196 19.3947 19.2652 19.5 19 19.5H5C4.73478 19.5 4.48043 19.3947 4.29289 19.2071C4.10536 19.0196 4 18.7652 4 18.5V5.50002C4 5.23481 4.10536 4.98045 4.29289 4.79292C4.48043 4.60538 4.73478 4.50002 5 4.50002H9.56C9.76964 4.49948 9.97416 4.56484 10.1446 4.68686C10.3151 4.80889 10.4429 4.9814 10.51 5.18002L11.05 6.82002C11.1171 7.01864 11.2449 7.19116 11.4154 7.31318C11.5858 7.4352 11.7904 7.50056 12 7.50002H19C19.2652 7.50002 19.5196 7.60538 19.7071 7.79292C19.8946 7.98045 20 8.23481 20 8.50002V18.5Z" fill="#0042E4" />
          </svg>

        </div>}
      </div>
    </>
  )
}