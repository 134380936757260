import { Product, TableProduct } from '../../../types/product-model'
import '../filter-table.css'
import { ProductNames } from '../../../types/enums/product-type'
import { SortData } from '../../../types/main'
import { getKeyByValue, getText } from '../../../utils'
import HeaderPoint from '../header-point'
import { useEffect, useState } from 'react'
import DetailsBlock from '../details-block/details-block'
import { getAllProducts } from '../../../utils/product'
import { getActiveFilter } from '../../../utils/filter-table'

type ProductFilterTableProps = {
  content: Product[],
  changeProductModal: (product: Product) => void;
  onDeleteProduct: (barcode: number) => void;
  getPointInfo: (barcode: number) => void
  onFilter: (data?: SortData) => void
  activeFilter: SortData
  isEdit: boolean
}



export default function ProductFilterTable({ content, getPointInfo, changeProductModal, onDeleteProduct, onFilter, activeFilter, isEdit }: ProductFilterTableProps) {
  const tableHeader = ProductNames
  const [allFilterNames, setAllFilterNames] = useState<{ [K in keyof TableProduct]: (string | number | boolean)[] }>({
    name: [],
    barcode: [],
    articleOzon: [],
    articleWB: [],
    photos: []
  })

  const changeProduct = (index: number) => {
    changeProductModal(content[index])
  }

  const deleteProduct = (index: number) => {
    onDeleteProduct(content[index].barcode)
  }

  const changeFilterContent = (data: SortData | null) => {
    if (!data) {
      onFilter()
    } else {
      onFilter(data)
    }
  }

  const getAllNamesByField = (field: keyof TableProduct) => {
    if (allFilterNames) {
      return allFilterNames[field]
    }
    return []
  }

  const getProductText = (el: Product, key: keyof TableProduct) => {
    if (key !== 'photos'){
      return getText(el[key])
    }
    if (el[key].length === 0){
      return <div className='table__img_empty'></div>
    } else if (el[key].length === 1){
      return <img className='table__img' src={el[key][0].url}/>
    } else {
      for (let img of el[key]){
        if (img.marketplaceType === 'WB'){
          return <img className='table__img' src={img.url}/> 
        }
      }
    }
  }

  const getAllNames = async () => {
    let newFilterNames = JSON.parse(JSON.stringify(allFilterNames))
    if (newFilterNames.name.length !== 0) {
      return
    }
    const products = await getAllProducts()
    if (!products){
      return
    }
    Object.values(products).forEach(product => {
      newFilterNames.name.push(product.name);
      newFilterNames.barcode.push(product.barcode);
      newFilterNames.articleOzon.push(product.articleOzon);
      newFilterNames.articleWB.push(product.articleWB);
    });
    setAllFilterNames(newFilterNames)
  }

  useEffect(() => {
    getAllNames()
  }, [])

  return (
    <>
      <div>
        <div className='scrolltable__container'>
          <div className={`table__container ${content.length < 3 && content.length !== 0 ? 'padding__table' : ''}`}>
            <table className='table borderTable'>
              <thead className='thead'>
                <tr className="table__tr">
                  {Object.values(tableHeader).map((el, index) => (
                    <HeaderPoint
                      blockedFilter={content.length === 0}
                      allNames={getAllNamesByField(getKeyByValue(tableHeader, el) as keyof TableProduct)} 
                      filterData={getActiveFilter(activeFilter, getKeyByValue(tableHeader, el) as string)} 
                      changeFilterContent={changeFilterContent} 
                      columnName={getKeyByValue(tableHeader, el) as string} 
                      name={el} key={`${el}--${index}`} />))}
                </tr>
              </thead>
              <tbody className='tbody'>
                {content.length !== 0 ?
                content.map((el, indexLen) => (
                  <tr key={el.barcode} className='body__tr' onClick={() => { isEdit && getPointInfo(el.barcode) }}>
                    {Object.keys(tableHeader).map((key, index) => (
                      <td className={`table__td ${indexLen === content.length - 1 ? 'last__td' : ''}`} key={key}>
                        <div className={`table__content ${index + 1 !== Object.keys(tableHeader).length && isEdit ? 'table__content_center' : ''}`}>
                          {getProductText(el, key as keyof TableProduct)}
                          {index + 1 === Object.keys(tableHeader).length && isEdit &&
                            <DetailsBlock displayEdit={true} changeAction={changeProduct} deleteAction={deleteProduct} index={indexLen} />
                          }
                        </div>
                      </td>))}
                  </tr>
                )) : <td colSpan={Object.values(tableHeader).length} className='body__notData'>Нет данных</td>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}