import { SortData } from "../types/main"

export const getTotalStages = (totalCount: number, length: number) => {
  let count = totalCount % length > 0 ? 1 : 0
  count += Math.floor(totalCount / length)
  return count 
}

export const getActiveFilter = (activeFilter: SortData, columnName: string) => {
  if (!activeFilter.filters){
    return {take: activeFilter.take, skip: activeFilter.skip}
  }
  return columnName === activeFilter.filters[0].field.name ? activeFilter : {take: activeFilter.take, skip: activeFilter.skip}
}