import { useRef, useState } from "react"
import '../filter-table.css'
import useOutsideClick from "../../../hooks/useOutsideClick"
import AdditionalComponent from "./additional-component"
import Warning from "../../warning/warning"

type DetailsBlockProps = {
  index: number
  displayEdit: boolean
  changeText?:string
  deleteAction: (index: number) => void
  changeAction: (index: number) => void
  additionalComponents?: {img: string, hoverImg: string, text: string, action: ()=> void}[]
}

export default function DetailsBlock({ index, deleteAction, changeAction, displayEdit, changeText, additionalComponents }: DetailsBlockProps) {
  const [isDisplayDetails, setIsDisplayDetails] = useState(false)
  const detailsBlock = useRef<HTMLDivElement>(null)
  const [isWarningDelete, setIsWarningDelete] = useState(false)

  const changeDisplayDetails = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation()
    setIsDisplayDetails(!isDisplayDetails)
  }

  useOutsideClick(detailsBlock, ()=>{setIsDisplayDetails(false)})

  const onChangeProduct = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setIsDisplayDetails(false)
    changeAction(index)
  }

  const onDeleteProduct = (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e){
      e.stopPropagation()
    }
    setIsDisplayDetails(false)
    deleteAction(index)
  }

  const additionalAction = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    if (additionalComponents){
      e.stopPropagation()
      setIsDisplayDetails(false)
      additionalComponents[index].action()
    }
  }

  return (
    <>
    <div ref={detailsBlock} className="details__block">
      <img onClick={(e) => { changeDisplayDetails(e) }} alt='ещё' src='/img/details.svg' />
      {isDisplayDetails &&
        <div className='details__container'>
          {additionalComponents && additionalComponents.map((el, index)=>(
            <AdditionalComponent key={el.text} action={(e:React.MouseEvent<HTMLDivElement, MouseEvent>)=>{additionalAction(e, index)}} img={el.img} hoverImg={el.hoverImg} text={el.text}/>))
          }
          {displayEdit && <div onClick={onChangeProduct} className="details__content">
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.5 12.5001H12.5M2.5 12.5001V10.0001L7.5 5.00007M2.5 12.5001H5L10 7.50006M7.5 5.00007L9.29287 3.20716L9.294 3.2061C9.54075 2.9593 9.66437 2.83568 9.80687 2.78938C9.93244 2.74859 10.0676 2.74859 10.1932 2.78938C10.3356 2.83565 10.4591 2.95912 10.7055 3.20557L11.7929 4.29295C12.0404 4.54046 12.1642 4.66427 12.2106 4.80698C12.2514 4.9325 12.2513 5.06772 12.2106 5.19325C12.1642 5.33585 12.0406 5.45947 11.7934 5.70663L11.7929 5.70716L10 7.50006M7.5 5.00007L10 7.50006" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span className="details__text">{changeText ? changeText : 'Редактировать'}</span>
          </div>}
          <div onClick={(e)=>{e.stopPropagation();setIsWarningDelete(true);setIsDisplayDetails(false)}} className="details__content">
            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_110_712)">
                <path d="M2.16663 3H10.8333L9.97741 10.1104C9.91647 10.6168 9.45269 11 8.90068 11H4.09922C3.5472 11 3.08347 10.6168 3.02251 10.1104L2.16663 3Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.97849 1.57358C4.15732 1.22343 4.53902 1 4.9584 1H8.04158C8.461 1 8.84265 1.22343 9.02151 1.57358L9.75 3H3.25L3.97849 1.57358Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1.08337 3H11.9167" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.41663 5.5V8" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.58337 5.5V8" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_110_712">
                  <rect width="13" height="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span className="details__text">Удалить</span>
          </div>
        </div>}
    </div> 
    {isWarningDelete && <Warning title="Вы действительно хотите удалить?" onClose={()=>{setIsWarningDelete(false)}} onConfirm={()=>{onDeleteProduct()}}/>}
    </>
  )
}