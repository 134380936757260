import { createAsyncThunk } from "@reduxjs/toolkit";
import { AddUserBack, CreateOrganization, DeleteUserBack, EditOrganization, EditParticipant, Organization, Participant } from "../../types/organization-model";
import { AppDispatch, State } from "../../types/state";
import { AxiosInstance } from "axios";
import { dropOrganization, getOrganization, saveOrganization } from "../../services/organization";
import { changeStateActiveOrganization } from "../organization-process/organization-actions";
import { PagesURl } from "../../services/api";
import { getUserInfoAction } from "./user-api-actions";
import { Invitation } from "../../types/notifications";
import { Task } from "../../types/task";

export const createOrganizationAction = createAsyncThunk<void, CreateOrganization, { dispatch: AppDispatch; state: State; extra: AxiosInstance }>(
  'organization/createOrganization',
  async (data, {dispatch, extra: api}) => {
    await api.post(PagesURl.ORGANIZATION + `/create`, data);
    dispatch(getOrganizationsAPI())
  },
);

export const editActiveOrganizationAction = createAsyncThunk<void, EditOrganization, { dispatch: AppDispatch; state: State; extra: AxiosInstance }>(
  'organization/editActiveOrganization',
  async (data, {dispatch, extra: api}) => {
    await api.put(PagesURl.ORGANIZATION + `/edit`, data);
    dispatch(getActiveOrganizationAction())
  },
);

export const getActiveOrganizationAction = createAsyncThunk<Organization, undefined, { dispatch: AppDispatch; state: State; extra: AxiosInstance }>(
  'organization/getActiveOrganization',
  async (_args, {extra: api}) => {
    const {data} = await api.get<Organization>(PagesURl.ORGANIZATION + `/get?id=${getOrganization()}`);
    return data;
  },
);

export const deleteOrganizationAction = createAsyncThunk<void, number, { dispatch: AppDispatch; state: State; extra: AxiosInstance }>(
  'organization/deleteOrganization',
  async (id, {dispatch, extra: api}) => {
    await api.delete(PagesURl.ORGANIZATION + `/delete?id=${id}`);
    dropOrganization()
    dispatch(changeStateActiveOrganization(null))
    dispatch(getOrganizationsAPI())
  },
);

export const getParticipantsAction = createAsyncThunk<Participant[], number, { dispatch: AppDispatch; state: State; extra: AxiosInstance }>(
  'organization/getUsers',
  async (id, {extra: api}) => {
    const {data} = await api.get<Participant[]>(PagesURl.ORGANIZATION + `/get/users/?id=${id}`);
    return data
  },
);

export const getOrganizationsAPI = createAsyncThunk<Organization[], undefined, { dispatch: AppDispatch; state: State; extra: AxiosInstance }>(
  'organization/getOrganizations',
  async (_args, {dispatch, extra: api}) => {
    const {data} = await api.get<Organization[]>(PagesURl.ORGANIZATION + `/find`);
    if (!getOrganization()){
      saveOrganization(data[0].id)
    }
    dispatch(getUserInfoAction())
    return data;
  },
);

export const addParticipantsAction = createAsyncThunk<void | string, AddUserBack, { dispatch: AppDispatch; state: State; extra: AxiosInstance }>(
  'organization/addUser',
  async (data, {dispatch, extra: api}) => {
    try{
      await api.post(PagesURl.ORGANIZATION + `/invite/user`, data);
      dispatch(getParticipantsAction(data.organizationId))
    } catch (error: any) {
      return error.response.data.match(/Message = (.*?),/)[1]
    }
  },
);

export const deleteParticipantsAction = createAsyncThunk<void, DeleteUserBack, { dispatch: AppDispatch; state: State; extra: AxiosInstance }>(
  'organization/deleteUser',
  async (data, {dispatch, extra: api}) => {
    try{
      await api.delete(PagesURl.ORGANIZATION + `/delete/user`, {data: data});
      dispatch(getParticipantsAction(data.organizationId))
    }catch(error){
      console.log(error)
    }
  },
);

export const editParticipantAction = createAsyncThunk<void, EditParticipant, { dispatch: AppDispatch; state: State; extra: AxiosInstance }>(
  'organization/editParticiapnt',
  async (data, {dispatch, extra: api}) => {
    await api.put(PagesURl.USER + '/edit/rights', data);
    const organization = getOrganization()
    if (organization){
      dispatch(getParticipantsAction(organization))
    }
  },
);

export const getInvitationsLengthAction = createAsyncThunk<number, undefined, { state: State; extra: AxiosInstance }>(
  'organization/getInvitationsLength',
  async (_args, {extra: api}) => {
    const {data} = await api.post<{data: Invitation[], totalCount: number}>(PagesURl.ORGANIZATION + '/find/invitations');
    return data.totalCount
  },
);

export const getSyncErrorsLengthAction = createAsyncThunk<number, undefined, { state: State; extra: AxiosInstance }>(
  'organization/getSyncErrorsLength',
  async (_args, {extra: api}) => {
    const {data} = await api.post<{data:Error[], totalCount: number}>(PagesURl.ExternalData + '/find/errors?isNew=true');
    return data.totalCount
  },
);

export const getTasksLengthAction = createAsyncThunk<number, undefined, { state: State; extra: AxiosInstance }>(
  'organization/getTasksLength',
  async (_args, {extra: api}) => {
    const {data} = await api.post<{data: Task[], totalCount: number}>(PagesURl.BACKGROUND + `/find/organization?isAll=false&organizationId=${getOrganization()}`);
    return data.data.length
  },
);