import './filter-blocks.css'

type CheckboxPointProps = {
  text: string,
  isActive: boolean
  changeNames?: (name: string, isAdd: boolean) => void
  changeAllNames?: (isAdd: boolean) => void
}

export default function CheckboxPoint({ text, isActive, changeNames, changeAllNames }: CheckboxPointProps) {
  const changeActive = () => {
    if (text !== 'Выбрать все' && changeNames !== undefined) {
      changeNames(text, !isActive)
    } else if (changeAllNames) {
      changeAllNames(!isActive)
    }
  }
  return (
    <div className='checkbox__container' onClick={changeActive}>

      <img alt='' src={isActive ? 'img/checkbox/active-checkbox.svg' : 'img/checkbox/disable-checkbox.svg'}/>
      <div className='variants__point'>{text}</div>
    </div>
  )
}