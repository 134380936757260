import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom';
import './not-found.css'
import { browserHistory } from '../../services/browser-history';

export default function NotFound() {

  const goBack = () => {
    browserHistory.back()
  }
  return (
    <>
      <Helmet>
        <title>Страница не найдена</title>
      </Helmet>
      <div className='error__container'>
        <div className='error__content'>
          <svg className='error__svg' xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
            <g clipPath="url(#clip0_480_604)">
              <path d="M55.6203 39.0459C55.6203 34.4198 59.3851 30.655 64.0131 30.655C68.6391 30.655 72.404 34.4189 72.404 39.0459C72.404 40.3776 71.3248 41.4568 69.9931 41.4568C68.6613 41.4568 67.5822 40.3776 67.5822 39.0459C67.5822 37.0776 65.9813 35.4768 64.0131 35.4768C62.0448 35.4768 60.4421 37.0776 60.4421 39.0459C60.4421 40.3776 59.3629 41.4568 58.0312 41.4568C56.6994 41.4568 55.6203 40.3767 55.6203 39.0459ZM28.8864 39.0198C28.8864 34.3938 32.6512 30.629 37.2792 30.629C41.9052 30.629 45.67 34.3928 45.67 39.0198C45.67 40.3516 44.5909 41.4307 43.2591 41.4307C41.9274 41.4307 40.8483 40.3516 40.8483 39.0198C40.8483 37.0516 39.2474 35.4507 37.2792 35.4507C35.3099 35.4507 33.7081 37.0516 33.7081 39.0198C33.7081 40.3516 32.629 41.4307 31.2973 41.4307C29.9655 41.4307 28.8864 40.3516 28.8864 39.0198ZM28.7812 70.2949C32.359 61.6813 41.0903 56.116 51.0232 56.116C60.7478 56.116 69.427 61.6533 73.133 70.2245C73.7676 71.6913 73.0935 73.3934 71.6257 74.0279C70.1599 74.6644 68.4588 73.9865 67.8242 72.5206C65.0334 66.071 58.4401 61.9021 51.0241 61.9021C43.4366 61.9021 36.8028 66.0662 34.1257 72.5139C33.6638 73.6268 32.5866 74.298 31.4535 74.298C31.0832 74.298 30.708 74.2266 30.3445 74.0761C28.8671 73.4647 28.167 71.7713 28.7812 70.2949ZM82.4207 100H17.5802C7.88748 100 0 92.1135 0 82.4207V17.5802C0 7.88651 7.88748 0 17.5802 0H82.4188C92.1135 0 99.999 7.88651 99.999 17.5802V82.4188C100.001 92.1135 92.1135 100 82.4207 100ZM17.5802 8.27322C12.4489 8.27322 8.27322 12.4489 8.27322 17.5802V82.4188C8.27322 87.5501 12.4489 91.7258 17.5802 91.7258H82.4188C87.5501 91.7258 91.7258 87.5501 91.7258 82.4188V17.5802C91.7258 12.4489 87.5501 8.27322 82.4188 8.27322H17.5802Z" fill="#004AFF" />
            </g>
            <defs>
              <clipPath id="clip0_480_604">
                <rect width="100" height="100" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div className='errorPage__content'>
            <h1 className='error__title'>404 - Страница не найдена!</h1>
            <span className='error__text'>Запрашиваемая страница не найдена, либо произошла ошибка. Вы можете вернуться </span>
            <span className='error__link' onClick={goBack}>назад</span>
            <span> , или перейти на </span> 
            <Link className='error__link' to='/' replace>Главную страницу</Link>.
          </div>
        </div>
      </div>
    </>

  )
}