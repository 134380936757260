import { useEffect, useState } from 'react';
import './shipment-page.css';
import axios, { PagesURl } from '../../services/api';
import { MarketPlaceType, ShipmentState } from "../../types/enums/shipment-type";
import { ChangeChildShipment, ChildShipment, ChildTableShipment, CreateChildShipment, EditChildShipment, HistoryTableChildShipment, ShipmentsHistoryDto } from '../../types/shipment-model';
import { getAllProducts } from '../../utils/product';
import Modal from 'react-modal';
import { Product } from '../../types/product-model';
import Spinner from '../../components/spinner/spinner';
import { getDataValue, getDataValueInput } from '../../utils';
import { checkCreateChildShipment, getMissingLines, sortActivityLog } from '../../utils/shipment';
import MissingLine from './missing-line';
import MarketShipmnentFilterTable from '../../components/filter-table/market-shipment-filter-table/market-shipment-filter-table';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateButton from '../../components/buttons/create-button/create-button';
import { Helmet } from 'react-helmet-async';
import { SortData } from '../../types/main';
import { getTotalStages } from '../../utils/filter-table';
import { useNavigate } from 'react-router-dom';
import { getOrganization } from '../../services/organization';
import { getUserRoles } from '../../store/user-process/selectors';
import { useAppSelector } from '../../hooks';
import { stageList } from '../../consts';
import PaginationBlock from '../../components/pagination-block/pagination-block';
import { Warehouse } from '../../types/warehouse-model';
import CreateMarketShipmentModalWindow from '../../components/modal-window/shipment/create-marketplace-shipment';
import { getStateActiveOrganization } from '../../store/organization-process/selectors';
import SynchronizationBlock from '../../components/synchronization-block/synchronization-block';
import ControlPanel from '../../components/control-panel/control-panel';

function MarketShipmentPage() {
  const navigate = useNavigate()
  useEffect(() => {
    if (!getOrganization()) {
      navigate('/')
    }
  })

  const user = useAppSelector(getUserRoles)

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const activeOrganization = useAppSelector(getStateActiveOrganization)
  const [isDisplayHistory, setIsDisplayHistory] = useState(false);

  const [isHistoryChange, setIsHistoryChange] = useState(false)

  const [shipments, setShipments] = useState<ChildShipment[]>([]);
  const [isShipmentsLoading, setIsShipmentsLoading] = useState(true)

  const [tableShipments, setTableShipments] = useState<ChildTableShipment[]>([]);
  const [history, setHistory] = useState<HistoryTableChildShipment[] | null>(null);
  const [products, setProducts] = useState<Product[]>([])

  const [activeStage, setActiveStage] = useState<number | null>(null)
  const [countRows, setCountRows] = useState<number>(stageList[0])
  const [activeFilter, setActiveFilter] = useState<SortData>({ take: countRows, skip: 0 })
  const [shipmentCount, setShipmentCount] = useState(0)
  const [activeShipment, setActiveShipment] = useState<ChildShipment | null>(null)
  const [isChangeShipment, setIsChangeShipment] = useState(false)

  const baseUrl = `${PagesURl.SHIPMENT}/child`;
  const historyUrl = `${PagesURl.SHIPMENT}/history/child`

  const [warehouses, setWarehouses] = useState<Warehouse[]>([])

  const notify = (message: string) => toast(message);

  /*   const changeIsActiveOnly = (initialState: boolean) => {
      setIsActiveOnly(!initialState)
    } */

  const fetchShipments = async (data: SortData = { take: countRows, skip: 0 }) => {
    setIsShipmentsLoading(true)
    try {
      const response = await axios.post<{ data: ChildShipment[], totalCount: number }>(baseUrl + "/find", data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setShipments(response.data.data);
      const result: ChildTableShipment[] = [];
      for (const shipment of response.data.data) {
        result.push({
          number: shipment.number,
          createdAt: getDataValue(shipment.createdAt),
          description: shipment.description,
          products: shipment.products,
          marketplaceType: shipment.marketplaceType,
          warehouseName: shipment.warehouseName,
          state: ShipmentState[shipment.state]
        });
      }
      setTableShipments(result)

      setShipmentCount(response.data.totalCount)
      setActiveFilter(data)
      if (!activeStage) {
        setActiveStage(response.data.totalCount > data.take ? 1 : null)
      }

      setIsShipmentsLoading(false)
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  };

  useEffect(() => {
    fetchShipments()
    handleFindWareHouses()
    
  }, []);

  const changeActiveStage = (toStage: number) => {
    let newSortData: SortData = JSON.parse(JSON.stringify(activeFilter))
    newSortData.skip = countRows * toStage
    fetchShipments(newSortData)
    setActiveStage(toStage + 1)
  }

  const handleCreateSubmit = async (data: CreateChildShipment) => {
    await axios
      .post(baseUrl + '/create', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        setTableShipments([])
        fetchShipments();
      })
      .catch((error: any) => {
        if (error.response.data.match(/Message = (.*?),/)){
          notify(error.response.data.match(/Message = (.*?),/)[1])
        }
      });
  };

  const handleGetSubmit = async (shipmentNumber: string) => {
    try {
      const response = await axios.get<ShipmentsHistoryDto>(`${historyUrl}?Number=${shipmentNumber}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data.shipmentsHistory
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  };

  const handleStateChangeSubmit = async (data: ChangeChildShipment) => {
    await axios
      .post(baseUrl + `/state/change`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        setIsHistoryChange(true)
        getShipmentInfo(data.shipmentNumber)
        fetchShipments();
        setIsHistoryChange(false)
      })
      .catch((error: any) => {
        if (error.response.data.match(/Message = (.*?),/)){
          notify(error.response.data.match(/Message = (.*?),/)[1])
        }
      })
  };

  const handleDeleteShipment = async (shipmentNumber: string) => {
    try {
      await axios.delete(baseUrl + `/delete?shipmentNumber=${shipmentNumber}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(() => { fetchShipments() })
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }

  const getShipmentInfo = async (shipmentNumber: string) => {
    const shipment = await handleGetSubmit(shipmentNumber)
    let result: HistoryTableChildShipment[] = []
    let emtyFlag = false;
    // eslint-disable-next-line array-callback-return
    shipment?.map((point) => {
      if (point.newState === 4) {
        emtyFlag = true
      }
      result.push({
        'editDate': getDataValue(point.editDate),
        'newState': point.newState,
        'shipmentNumber': point.shipmentNumber
      })
    })
    if (!emtyFlag) {
      for (let shipment in shipments) {
        if (shipments[shipment].number === shipmentNumber) {
          result.push({
            'editDate': getDataValue(shipments[shipment].createdAt),
            'newState': 4,
            'shipmentNumber': shipmentNumber
          })
        }
      }
    }
    result = sortActivityLog(result) as HistoryTableChildShipment[]
    setHistory(result)
    setIsDisplayHistory(true)
  }

  const handleFindWareHouses = async () => {
    try{
       const {data} = await axios.post<{data: Warehouse[], totalCount: number}>(PagesURl.WAREHOUSE + '/find')
       setWarehouses(data.data)
    }catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }

  const handleChangeShipment = async (data: CreateChildShipment) => {
    const newData: EditChildShipment  = {
      number: data.number,
      products:data.products,
      description:data.description,
      marketplaceType: data.marketplaceType
    }
    await axios
      .put(baseUrl + '/edit', newData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        setIsChangeShipment(false)
        setTableShipments([])
        fetchShipments()
      })
      .catch((error: any) => {
        if (error.response.data.match(/Message = (.*?),/)){
          notify(error.response.data.match(/Message = (.*?),/)[1])
        }
      });
  }

  const handleSynchronizeWb = async () => {
    if (activeOrganization) {
      await axios.post(PagesURl.ExternalData + `/shipment/create/byWBApi?organizationId=${activeOrganization.id}`)
      fetchShipments()
    }
  }
  const handleSynchronizeOzon = async () => {
    if (activeOrganization) {
      await axios.post(PagesURl.ExternalData + `/shipments/create/byOzonApi?organizationId=${activeOrganization.id}`)
      fetchShipments()
    }
  }

  const OpenCreateModal = () => {
    handleFindWareHouses()
    getAllProducts().then((products) => {
      if (products){
        setProducts(products)
      }
    });
    setIsCreateModalOpen(true)
  }

  const changeDate = (newDate: string, shipmentNumber: string, targetState: number) => {
    handleStateChangeSubmit({
      'shipmentNumber': shipmentNumber,
      'editDate': newDate + ':00.000Z',
      'newState': targetState
    })
  }

  const changeSelectedValue = (newValue: number) => {
    setActiveStage(null)
    setCountRows(newValue)
    fetchShipments({ take: newValue, skip: 0 })
  }

  const onChangeShipment = async (e: React.MouseEvent<HTMLOrSVGElement>, shipment: ChildTableShipment) => {
    e.stopPropagation()
    try {
      const {data} = await axios.get<{shipmentInfo: ChildShipment}>(baseUrl + `/get?shipmentNumber=${shipment.number}`,{
        headers: {
          'Content-Type': 'application/json',
        },
      })
      setActiveShipment(data.shipmentInfo)
      setIsChangeShipment(true)
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }

  const getActiveWarehouseName = () => {
    let filterWarehouses = warehouses.filter((warehouse)=>{
      return warehouse.name === activeShipment?.warehouseName
    })
    if (!filterWarehouses.length){
      return
    } else {
      return filterWarehouses[0].id
    }
  }

  if (!user) {
    return <Spinner />
  }

  return (
    <>
      <Helmet>
        <title>Поставки до маркетплейса</title>
      </Helmet>
      <div className="shipment-container">
        <div className='shipment-content'>
          <div className='shipment__controls centerFlex'>
            {user.role > 0 && <CreateButton text='Cоздать поставку' icon='/img/sidebar/marketshipment-icon.svg' createAction={OpenCreateModal} />}
            <ControlPanel 
                content={[
                {
                  title: 'Синхронизация',
                  data: <>
                  <SynchronizationBlock isActive={activeOrganization?.wbApiKey ? true : false} text='Cинхронизация с WB' onSynchronization={()=>{handleSynchronizeWb()}}/>
                  <SynchronizationBlock isActive={activeOrganization?.ozonApiKey && activeOrganization.ozonClientId ? true : false} text='Cинхронизация с OZON' onSynchronization={()=>{handleSynchronizeOzon()}}/>
                  </>
                }
              ]}
                />
          </div>
          <PaginationBlock
            onChangeSelectedValue={changeSelectedValue}
            activeStage={activeStage}
            getTotalStages={()=>{return getTotalStages(shipmentCount, countRows)}}
            countRows={countRows}
            changeActiveStage={changeActiveStage}
            children={!isShipmentsLoading ?
                <MarketShipmnentFilterTable onChangeShipment={onChangeShipment} isEdit={user.role > 1} activeFilter={activeFilter} onFilter={fetchShipments} onDeleteShipment={handleDeleteShipment} content={tableShipments} getPointInfo={getShipmentInfo}
                />  :
              <Spinner />
            }
          />
        </div>
        <CreateMarketShipmentModalWindow
          isOpen={isCreateModalOpen}
          title={'Создание поставки'}
          btnText='Создать'
          fields={[
            {
              name: 'Маркетплейс',
              backName: 'marketplaceType',
              type: 'select',
              options: MarketPlaceType
            },
            {
              name: 'Склад',
              backName: 'warehouseId',
              type: 'select',
              options: warehouses
            },
            {
              name: 'Товар',
              backName: 'products',
              type: 'table',
              options: products
            },
            {
              name: 'Номер',
              backName: 'number',
              type: 'text',
            },
            {
              name: 'Комментарий',
              backName: 'description',
              type: 'text',
              required: false
            },
            {
              name: 'Дата создания',
              backName: 'createdAt',
              type: 'datetime-local',
            },
          ]}
          onRequestClose={() => { setIsCreateModalOpen(false) }}
          onSubmit={handleCreateSubmit}
          checkFields={checkCreateChildShipment}
        />
        {activeShipment &&
        <CreateMarketShipmentModalWindow
          isOpen={isChangeShipment}
          title={activeShipment.number}
          btnText='Создать'
          fields={[
            {
              name: 'Маркетплейс',
              backName: 'marketplaceType',
              type: 'select',
              options: MarketPlaceType,
              value: activeShipment.marketplaceType
            },
            {
              name: 'Товар',
              backName: 'products',
              type: 'table',
              options: products,
              value: activeShipment.products
            },
            {
              name: 'Номер',
              backName: 'number',
              type: 'text',
              value: activeShipment.number
            },
            {
              name: 'Комментарий',
              backName: 'description',
              type: 'text',
              required: false,
              value: activeShipment.description
            },
          ]}
          onRequestClose={() => { setIsChangeShipment(false) }}
          onSubmit={handleChangeShipment}
          checkFields={checkCreateChildShipment}
          onError={(error: string)=>{notify(error)}}
          activeWarehouseName={getActiveWarehouseName()}
        />}

        <Modal
          isOpen={isDisplayHistory}
          ariaHideApp={false}
          contentLabel="Product Info"
          className="modal"
          overlayClassName="modal-overlay"
          onRequestClose={() => { setIsDisplayHistory(false) }}
        >
          <div>
            {history && <div className='modal-content'>
              {isHistoryChange ? <Spinner /> :
                <>
                  <div className="closeInfo__button" onClick={() => { setIsDisplayHistory(false) }}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(0.719608 0.69438 -0.719608 0.69438 0.392899 2.15039)" stroke="black" strokeWidth="2" />
                      <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(-0.719608 0.69438 -0.719608 -0.69438 21.6071 1)" stroke="black" strokeWidth="2" />
                    </svg>
                  </div>

                  {history.length !== 0 ? <table className='history__table borderTable'>
                    <thead>
                      <tr>
                        <th>Состояние</th>
                        <th>Время перехода в состояние</th>
                      </tr>
                    </thead>
                    <tbody>
                      {history.map((el, index) => (
                        <tr className='history__value' key={`${el.editDate}--${index}`}>
                          <td>{ShipmentState[el.newState]}</td>
                          <td>
                            {user.role > 1 && el.newState !== 6 ? <input className='history__value' onChange={(e) => { changeDate(e.target.value, el.shipmentNumber, el.newState) }} defaultValue={getDataValueInput(el.editDate)} type='datetime-local' /> : el.editDate}
                          </td>
                        </tr>
                      ))}
                      {getMissingLines(history.length, history[0].shipmentNumber, false).map((el) => <MissingLine key={el.newState} shipmentNumber={el.shipmentNumber} isReadOnly={user.role > 1 && el.newState !== 6 ? el.isReadOnly : true} newState={el.newState} changeValue={changeDate} />)}
                    </tbody>
                  </table> : 'Истории нет'}
                </>}
            </div>
            }
          </div>

        </Modal>
      </div>
    </>
  );
}

export default MarketShipmentPage;
