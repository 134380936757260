import { FilterTableAccounting, TableAccounting } from '../../../types/accounting-model'
import { AccountingNames } from '../../../types/enums/accounting-type'
import '../filter-table.css'
import HeaderPoint from '../header-point'
import { formatNumber, getKeyByValue } from '../../../utils'
import { SortData } from '../../../types/main'
import { useEffect, useState } from 'react'
import { getActiveFilter } from '../../../utils/filter-table'
import {getAllProducts} from "../../../utils/product";

type AccountingFilterTableProps = {
  content: TableAccounting[],
  getPointInfo: (barcode: number) => void
  onFilter: (data?: SortData) => void
  activeFilter: SortData
}

export default function AccountingFilterTable({ content, getPointInfo, onFilter, activeFilter }: AccountingFilterTableProps) {
  const tableHeader = AccountingNames
  const [allFilterNames, setAllFilterNames] = useState<{ [K in keyof FilterTableAccounting]: (string | number | boolean)[] }>({
    barcode: [],
    name: [],
    warehouseCount: [],
    marketPlaceCount: [],
    isSyncOzon: [],
    isSyncWB: [],
    photos: [],
    rateOfDecreases: []
  })

  const getAllNamesByField = (field: keyof FilterTableAccounting) => {
    if (allFilterNames) {
      return allFilterNames[field]
    }
    return []
  }

  const getAllNames = async () => {
    let newFilterNames: { [K in keyof TableAccounting]: (string | number | boolean)[] } = JSON.parse(JSON.stringify(allFilterNames))
    if (newFilterNames.name.length !== 0) {
      return
    }
    const products = await getAllProducts()
    /*     for (let shipment of shipments){
          for (let key in shipment){
            const shipmentKey = key as keyof ParentTableShipment;
            newFilterNames[shipmentKey].push(shipment[shipmentKey])
          }
        } */
    if (!products){
      return
    }
    Object.values(products).forEach(product => {
      newFilterNames.barcode.push(product.barcode);
      newFilterNames.name.push(product.name);
    });
    setAllFilterNames(newFilterNames)
  }

  useEffect(() => {
    getAllNames()
  }, [])

  const changeFilterContent = async (data: SortData | null) => {
    if (!data) {
      onFilter()
    } else {
      onFilter(data)
    }
  }

  const getAccountingText = (el: TableAccounting, key: keyof FilterTableAccounting) => {
    switch (key){
      case 'barcode':
        return (
          <div>
            {el.barcode && <div className='table___accounting_codeBlock centerFlex'>
              <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 2H4.64563V13.6344H2V2ZM13.6875 2H16.3331V13.6344H13.6875V2ZM7.29125 2H8.60875V13.6344H7.29125V2ZM9.93687 2H11.2544V13.6344H9.93687V2ZM17.6825 2H19V13.6344H17.6825V2Z" fill="#1A1B1C"/>
              </svg>
              <span className='table___accounting_code'>{el.barcode}</span>
            </div>}
            {el.articleWB && 
            <div className='table___accounting_codeBlock centerFlex'>
              <svg width="27" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.71226 13L2 3.00074H3.77917L5.86344 10.6802L8.09694 3L9.7554 3.00074L11.9967 10.7213L14.0902 3.00259L15.8694 3L13.1556 12.9981L11.0932 12.9959L8.92709 5.5286L6.75803 12.9978L4.71226 13ZM24.5867 10.1344C24.5867 10.9528 24.295 11.6326 23.6992 12.1743C23.1034 12.716 22.4025 12.9926 21.5383 12.9926H17.2046V3H21.2228C22.0522 3 22.7528 3.2766 23.3252 3.79498C23.9095 4.32521 24.1898 4.98245 24.1898 5.76595C24.1898 6.64202 23.8392 7.31036 23.1619 7.80616C24.0495 8.26715 24.5867 9.09694 24.5867 10.1344ZM18.8749 4.54441V7.17225H21.2343C21.9704 7.17225 22.531 6.59573 22.531 5.85815C22.531 5.12056 21.9704 4.54441 21.2343 4.54441H18.8749ZM21.5497 11.4482C22.3207 11.4482 22.9283 10.8372 22.9283 10.0537C22.9283 9.26986 22.3207 8.6589 21.5497 8.6589H18.8749V11.4597H21.5497V11.4482Z" fill="#1A1B1C" />
              </svg>
              <span className='table___accounting_code'>{el.articleWB}</span>
            </div>}
            {el.articleOzon&&<div className='table___accounting_codeBlock centerFlex'>
              <svg width="37" height="16" viewBox="0 0 37 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.6187 9.65473H12.5998L16.4539 4.58033C16.5379 4.46891 16.5146 4.30641 16.4026 4.22285C16.3606 4.19035 16.3046 4.17178 16.2486 4.17178H10.6727C10.1828 4.17178 9.7815 4.57104 9.7815 5.05852C9.7815 5.546 10.1828 5.94527 10.6727 5.94527H13.1784L9.31023 11.0243C9.22157 11.1357 9.2449 11.2936 9.35689 11.3818C9.40355 11.4189 9.45954 11.4375 9.51553 11.4329H15.6141C16.104 11.4097 16.4819 10.9918 16.4586 10.4997C16.4353 10.0447 16.0713 9.68259 15.6141 9.65938V9.65473H15.6187ZM35.1041 4.17178C34.6142 4.17178 34.2129 4.57104 34.2129 5.05852V8.03445L29.3975 4.23213C29.2902 4.14392 29.1269 4.15785 29.0383 4.26927C29.0009 4.3157 28.9823 4.37141 28.9823 4.43177V10.5461C28.9823 11.0336 29.3835 11.4329 29.8735 11.4329C30.3634 11.4329 30.7647 11.0382 30.7647 10.5461V7.57019L35.5801 11.3772C35.692 11.4654 35.8554 11.4468 35.944 11.3354C35.9813 11.2889 36 11.2332 36 11.1775V5.05852C35.9953 4.5664 35.5987 4.17178 35.1041 4.17178ZM22.3612 9.82187C20.3221 9.82187 18.801 8.75406 18.801 7.79768C18.801 6.8413 20.3268 5.77349 22.3612 5.77349C24.4002 5.77349 25.9213 6.8413 25.9213 7.79768C25.9213 8.75406 24.4049 9.82187 22.3612 9.82187ZM22.3612 4C19.4122 4 17.0185 5.69921 17.0185 7.79768C17.0185 9.89615 19.4122 11.5954 22.3612 11.5954C25.3101 11.5954 27.7038 9.89615 27.7038 7.79768C27.7038 5.69921 25.3101 4 22.3612 4ZM4.82149 9.82651C3.69697 9.82651 2.78243 8.9212 2.78243 7.80232C2.78243 6.68344 3.69231 5.77349 4.81682 5.77349C5.94134 5.77349 6.85589 6.6788 6.85589 7.79768V7.80232C6.85589 8.91655 5.94601 9.82187 4.82149 9.82651ZM4.82149 4C2.71244 4 1.00467 5.69921 1 7.79768C1 9.89615 2.70777 11.5954 4.81682 11.6C6.92588 11.6 8.63365 9.90079 8.63832 7.80232V7.79768C8.63365 5.69921 6.92588 4 4.82149 4Z" fill="#1A1B1C" />
              </svg>
              <span className='table___accounting_code'>{el.articleOzon}</span>
            </div>}
            <div className='table___accounting_codeBlock'>
              <p className='table__accounting_codeVendor'>Артикул продавца</p>
              <p className='table__accounting_code_content'>{el.vendorCode ?  el.vendorCode : '-'}</p>
            </div>
            <div className='table___accounting_codeBlock centerFlex'>
              <p className='table__accounting_codeVendor'>Организация: </p>
              <p style={{marginLeft: '19px'}} className='table__accounting_code_content'>{el.organizationName}</p>
            </div>
          </div>
        )
      case 'name':
        if (el['photos'].length === 0){
          return <div className='table__imgBlock'><p>{el.name}</p></div>
        } else if (el['photos'].length === 1){
          return <div className='table__imgBlock'><p>{el.name}</p><img className='table__img' src={el['photos'][0].url}/></div>
        } else {
          for (let img of el['photos']){
            if (img.marketplaceType === 'WB'){
              return <div className='table__imgBlock'><p>{el.name}</p><img className='table__img' src={img.url}/></div>
            }
          }
        }
        return (
          <div>
            <p>{el.name}</p>
          </div>
        )
      case 'warehouseCount':
        return (
          <div className='table___accounting_warehouse'>
            {el.warehouseCount.map((warehouse)=>(
              <div className='table___accounting_warehouseBlock centerFlex' key={warehouse.name}>
                <span>{warehouse.name}</span>
                <span className='table___accounting_warehouseQuantity'>{warehouse.quantity}</span>
              </div>
            ))}
          </div>
        )
      case 'marketPlaceCount':
        return (
          <div className='table___accounting_marketPlaceCountBlock centerFlex'>
            <div className='table___accounting_marketPlaceCount centerFlex'>
              <svg width="27" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.71226 13L2 3.00074H3.77917L5.86344 10.6802L8.09694 3L9.7554 3.00074L11.9967 10.7213L14.0902 3.00259L15.8694 3L13.1556 12.9981L11.0932 12.9959L8.92709 5.5286L6.75803 12.9978L4.71226 13ZM24.5867 10.1344C24.5867 10.9528 24.295 11.6326 23.6992 12.1743C23.1034 12.716 22.4025 12.9926 21.5383 12.9926H17.2046V3H21.2228C22.0522 3 22.7528 3.2766 23.3252 3.79498C23.9095 4.32521 24.1898 4.98245 24.1898 5.76595C24.1898 6.64202 23.8392 7.31036 23.1619 7.80616C24.0495 8.26715 24.5867 9.09694 24.5867 10.1344ZM18.8749 4.54441V7.17225H21.2343C21.9704 7.17225 22.531 6.59573 22.531 5.85815C22.531 5.12056 21.9704 4.54441 21.2343 4.54441H18.8749ZM21.5497 11.4482C22.3207 11.4482 22.9283 10.8372 22.9283 10.0537C22.9283 9.26986 22.3207 8.6589 21.5497 8.6589H18.8749V11.4597H21.5497V11.4482Z" fill="#1A1B1C" />
              </svg>
              <span className='table___accounting_code_border table___accounting_code'>: {el.wbCount}</span>
              <img className='table__accounting_sync' src='/img/icons/sync.svg'/>
              <img src={`/img/icons/${el.isSyncWB ? 'done' : 'not'}.svg`}/>
            </div>
            <div className='table___accounting_marketPlaceCount centerFlex'>
              <svg style={{marginLeft: '-12px'}} width="37" height="16" viewBox="0 0 37 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.6187 9.65473H12.5998L16.4539 4.58033C16.5379 4.46891 16.5146 4.30641 16.4026 4.22285C16.3606 4.19035 16.3046 4.17178 16.2486 4.17178H10.6727C10.1828 4.17178 9.7815 4.57104 9.7815 5.05852C9.7815 5.546 10.1828 5.94527 10.6727 5.94527H13.1784L9.31023 11.0243C9.22157 11.1357 9.2449 11.2936 9.35689 11.3818C9.40355 11.4189 9.45954 11.4375 9.51553 11.4329H15.6141C16.104 11.4097 16.4819 10.9918 16.4586 10.4997C16.4353 10.0447 16.0713 9.68259 15.6141 9.65938V9.65473H15.6187ZM35.1041 4.17178C34.6142 4.17178 34.2129 4.57104 34.2129 5.05852V8.03445L29.3975 4.23213C29.2902 4.14392 29.1269 4.15785 29.0383 4.26927C29.0009 4.3157 28.9823 4.37141 28.9823 4.43177V10.5461C28.9823 11.0336 29.3835 11.4329 29.8735 11.4329C30.3634 11.4329 30.7647 11.0382 30.7647 10.5461V7.57019L35.5801 11.3772C35.692 11.4654 35.8554 11.4468 35.944 11.3354C35.9813 11.2889 36 11.2332 36 11.1775V5.05852C35.9953 4.5664 35.5987 4.17178 35.1041 4.17178ZM22.3612 9.82187C20.3221 9.82187 18.801 8.75406 18.801 7.79768C18.801 6.8413 20.3268 5.77349 22.3612 5.77349C24.4002 5.77349 25.9213 6.8413 25.9213 7.79768C25.9213 8.75406 24.4049 9.82187 22.3612 9.82187ZM22.3612 4C19.4122 4 17.0185 5.69921 17.0185 7.79768C17.0185 9.89615 19.4122 11.5954 22.3612 11.5954C25.3101 11.5954 27.7038 9.89615 27.7038 7.79768C27.7038 5.69921 25.3101 4 22.3612 4ZM4.82149 9.82651C3.69697 9.82651 2.78243 8.9212 2.78243 7.80232C2.78243 6.68344 3.69231 5.77349 4.81682 5.77349C5.94134 5.77349 6.85589 6.6788 6.85589 7.79768V7.80232C6.85589 8.91655 5.94601 9.82187 4.82149 9.82651ZM4.82149 4C2.71244 4 1.00467 5.69921 1 7.79768C1 9.89615 2.70777 11.5954 4.81682 11.6C6.92588 11.6 8.63365 9.90079 8.63832 7.80232V7.79768C8.63365 5.69921 6.92588 4 4.82149 4Z" fill="#1A1B1C" />
              </svg>
              <span className='table___accounting_code_border table___accounting_code'> : {el.ozonCount}</span>
              <img className='table__accounting_sync' src='/img/icons/sync.svg'/>
              <img src={`/img/icons/${el.isSyncOzon ? 'done' : 'not'}.svg`}/>
            </div>
          </div>
        )
      case 'rateOfDecreases':
        return (
          <div className='table___accounting_marketPlaceCountBlock centerFlex'>
            {el.rateOfDecreases.map((rate, index) => (
              <div key={`${rate.marketplaceType}--${index}`} className='table___accounting_marketPlaceCount centerFlex'>
                {rate.marketplaceType === 'WB' ?
                  <svg width="27" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.71226 13L2 3.00074H3.77917L5.86344 10.6802L8.09694 3L9.7554 3.00074L11.9967 10.7213L14.0902 3.00259L15.8694 3L13.1556 12.9981L11.0932 12.9959L8.92709 5.5286L6.75803 12.9978L4.71226 13ZM24.5867 10.1344C24.5867 10.9528 24.295 11.6326 23.6992 12.1743C23.1034 12.716 22.4025 12.9926 21.5383 12.9926H17.2046V3H21.2228C22.0522 3 22.7528 3.2766 23.3252 3.79498C23.9095 4.32521 24.1898 4.98245 24.1898 5.76595C24.1898 6.64202 23.8392 7.31036 23.1619 7.80616C24.0495 8.26715 24.5867 9.09694 24.5867 10.1344ZM18.8749 4.54441V7.17225H21.2343C21.9704 7.17225 22.531 6.59573 22.531 5.85815C22.531 5.12056 21.9704 4.54441 21.2343 4.54441H18.8749ZM21.5497 11.4482C22.3207 11.4482 22.9283 10.8372 22.9283 10.0537C22.9283 9.26986 22.3207 8.6589 21.5497 8.6589H18.8749V11.4597H21.5497V11.4482Z" fill="#1A1B1C" />
                  </svg>
                  : <svg style={{ marginLeft: '-12px' }} width="37" height="16" viewBox="0 0 37 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.6187 9.65473H12.5998L16.4539 4.58033C16.5379 4.46891 16.5146 4.30641 16.4026 4.22285C16.3606 4.19035 16.3046 4.17178 16.2486 4.17178H10.6727C10.1828 4.17178 9.7815 4.57104 9.7815 5.05852C9.7815 5.546 10.1828 5.94527 10.6727 5.94527H13.1784L9.31023 11.0243C9.22157 11.1357 9.2449 11.2936 9.35689 11.3818C9.40355 11.4189 9.45954 11.4375 9.51553 11.4329H15.6141C16.104 11.4097 16.4819 10.9918 16.4586 10.4997C16.4353 10.0447 16.0713 9.68259 15.6141 9.65938V9.65473H15.6187ZM35.1041 4.17178C34.6142 4.17178 34.2129 4.57104 34.2129 5.05852V8.03445L29.3975 4.23213C29.2902 4.14392 29.1269 4.15785 29.0383 4.26927C29.0009 4.3157 28.9823 4.37141 28.9823 4.43177V10.5461C28.9823 11.0336 29.3835 11.4329 29.8735 11.4329C30.3634 11.4329 30.7647 11.0382 30.7647 10.5461V7.57019L35.5801 11.3772C35.692 11.4654 35.8554 11.4468 35.944 11.3354C35.9813 11.2889 36 11.2332 36 11.1775V5.05852C35.9953 4.5664 35.5987 4.17178 35.1041 4.17178ZM22.3612 9.82187C20.3221 9.82187 18.801 8.75406 18.801 7.79768C18.801 6.8413 20.3268 5.77349 22.3612 5.77349C24.4002 5.77349 25.9213 6.8413 25.9213 7.79768C25.9213 8.75406 24.4049 9.82187 22.3612 9.82187ZM22.3612 4C19.4122 4 17.0185 5.69921 17.0185 7.79768C17.0185 9.89615 19.4122 11.5954 22.3612 11.5954C25.3101 11.5954 27.7038 9.89615 27.7038 7.79768C27.7038 5.69921 25.3101 4 22.3612 4ZM4.82149 9.82651C3.69697 9.82651 2.78243 8.9212 2.78243 7.80232C2.78243 6.68344 3.69231 5.77349 4.81682 5.77349C5.94134 5.77349 6.85589 6.6788 6.85589 7.79768V7.80232C6.85589 8.91655 5.94601 9.82187 4.82149 9.82651ZM4.82149 4C2.71244 4 1.00467 5.69921 1 7.79768C1 9.89615 2.70777 11.5954 4.81682 11.6C6.92588 11.6 8.63365 9.90079 8.63832 7.80232V7.79768C8.63365 5.69921 6.92588 4 4.82149 4Z" fill="#1A1B1C" />
                  </svg>}
                <span className='table___accounting_code_border table___accounting_code'>: {rate.rate.toFixed(2)}</span>
                <img className='table__accounting_sync' src='/img/icons/sync.svg' />
                <img src={`/img/icons/${rate.isSync ? 'done' : 'not'}.svg`} />
              </div>
            ))}
          </div>
        )
    }
  }

  return (
    <>
      <div>
        <div className='scrolltable__container'>
          <div className={`table__container`}>
            <table className='table borderTable'>
              <thead className='thead'>
                <tr className="table__tr">
                  {Object.values(tableHeader).map((el, index) => (<HeaderPoint blockedFilter allNames={getAllNamesByField(getKeyByValue(tableHeader, el) as keyof FilterTableAccounting)} filterData={getActiveFilter(activeFilter, getKeyByValue(tableHeader, el) as string)} changeFilterContent={changeFilterContent} columnName={getKeyByValue(tableHeader, el) as string} name={el} key={`${el}--${index}`} />))}
                </tr>
              </thead>
              <tbody>
                {content.length !== 0 ? content.map((el, indexLen) => (
                  <tr key={`${el.name}--${indexLen}`} style={{ cursor: 'pointer' }}>
                    {Object.keys(tableHeader).map((key, index) => (
                      <td className={`table__td ${indexLen === content.length - 1 ? 'last__td' : ''}`} key={`${key}--${index}`}>
                        <div className='table__content table__content_center'>
                          {getAccountingText(el, key as keyof FilterTableAccounting)}
                        </div>
                      </td>))}
                  </tr>
                )) : <td colSpan={Object.values(tableHeader).length} className='body__notData'>Нет данных</td>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}