import './warning.css'

type WarningProps = {
  title: string,
  text?: string,
  onClose: ()=>void
  onConfirm: ()=>void
}

export default function Warning({title, text, onClose, onConfirm}:WarningProps){
  const onCloseWarning = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
    e.stopPropagation()
    onClose()
  }
  const onConfirmWarning = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
    e.stopPropagation()
    onConfirm()
    onClose()
  }
  return (
    <div className='warning__container'>
      <div className="warning__content centerFlex">
        <h1 className='warning__title'>{title}</h1>
        {text && <p className='warning__text'>{text}</p>}
        <p className='warning__confirm'>Подтвердить?</p>
        <div className='centerFlex warning__button centerFlex'>
          <button type='button' onClick={(e)=>{onCloseWarning(e)}} className='warning__closeButton centerFlex pointer'>Нет</button>
          <button type='button' onClick={(e)=>{onConfirmWarning(e)}} className='warning__confirmButton centerFlex pointer'>Да</button>
        </div>
      </div>
    </div>
  )
}