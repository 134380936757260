import { useRef, useState } from 'react'
import './dropdown-list.css'
import DropdownListPoint from './dropdown-list-point';

type DropDownListProps = {
  activeValue: string
  title: string
  isRequired: boolean
  list: {value: string, isActive: boolean}[]
  type: 'checkbox' | 'radio'
  onChangeValue: (value: string, isAdd? : boolean) => void
}

export default function DropDownList({activeValue, isRequired, title, list, type, onChangeValue}: DropDownListProps) {
  const [displayList, setDisplayList] = useState(false)
  const block = useRef<HTMLDivElement>(null)

  return (
    <div ref={block}>
      <div onClick={() => { setDisplayList(!displayList) }} className="dropdownist__value-container">
        <p className="dropdownist__title">{title}{isRequired && <span className='dropdownist__required'>{' *'}</span>}</p>
        <p className="dropdownist__value">{activeValue}</p>
        <img className={`${displayList ? 'dropdownist__active' : ''}`} width={'17px'} height={'17px'} alt='' src='img/organization-page/add-participant-arrow.svg' />
      </div>
      {displayList &&
        <div className='dropdownListPoint__container'>
          {list.map((el)=>(<DropdownListPoint type={type} onClickValue={onChangeValue} isActive={el.isActive} key={el.value} value={el.value}/>))}
        </div>
      }
    </div>
  )
}