import { useState } from 'react'
import './account-block.css'

type PasswordInputType = {
  isRegistration: boolean
  value: string
  changeInput: (e: React.ChangeEvent<HTMLInputElement>)=> void
}

export default function PasswordInput({ isRegistration, value, changeInput }: PasswordInputType) {

  const [isShow, setIsShow] = useState(false)

  return (
    <div className='password__block'>
      <input onChange={(e)=>{changeInput(e)}} value={value} name='password' autoComplete={isRegistration ? "new-password" : undefined} placeholder='Пароль' className='account__modal__input' required type={isShow ? 'text' : 'password'} />
      {isShow ?
        <svg onClick={()=>{setIsShow(false)}}  className='password__show' width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_241_277)">
            <path d="M1.2334 9C1.2334 9 4.2334 3 9.4834 3C14.7334 3 17.7334 9 17.7334 9" stroke="#808080" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.2334 9C1.2334 9 4.2334 15 9.4834 15C14.7334 15 17.7334 9 17.7334 9" stroke="#808080" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.4834 11.25C10.726 11.25 11.7334 10.2426 11.7334 9C11.7334 7.75736 10.726 6.75 9.4834 6.75C8.24076 6.75 7.2334 7.75736 7.2334 9C7.2334 10.2426 8.24076 11.25 9.4834 11.25Z" stroke="#808080" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_241_277">
              <rect width="18" height="18" fill="white" transform="translate(0.483398)" />
            </clipPath>
          </defs>
        </svg>
        :
        <svg onClick={()=>{setIsShow(true)}} className='password__notShow' width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.06055 1.66663L18.7272 18.3333" stroke="#808080" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5.98786 5.60213C3.44787 7.32935 2.06055 9.99996 2.06055 9.99996C2.06055 9.99996 5.09085 15.8333 10.3939 15.8333C12.1025 15.8333 13.575 15.2278 14.7865 14.407M9.56055 4.21514C9.83171 4.1834 10.1095 4.16663 10.3939 4.16663C15.6969 4.16663 18.7272 9.99996 18.7272 9.99996C18.7272 9.99996 18.1507 11.1097 17.0605 12.3612" stroke="#808080" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.0602 11.8635C11.6179 12.2593 11.0339 12.5 10.3936 12.5C9.0128 12.5 7.89355 11.3808 7.89355 10C7.89355 9.31363 8.17016 8.69188 8.61799 8.24011" stroke="#808080" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

      }
    </div>
  )
}