import { useEffect, useState } from 'react'
import './filter.css'

type FilterRangeProps = {
  title: string | JSX.Element
  changeFilterValue: (newValues: string[])=>void
  isDeletingFilter: boolean
}

export default function FilterRange({title, changeFilterValue, isDeletingFilter}:FilterRangeProps){

  const [atValue, setAtValue] = useState<string>()
  const [toValue, setToValue] = useState<string>()

  const changeValue = (value: string, isAt: boolean) => {
    if (isAt){
      setAtValue(value)
      if (toValue){
        changeFilterValue([value, toValue])
      }
    } else {
      setToValue(value)
      if (atValue){
        changeFilterValue([atValue, value])
      }
    }
  }

  useEffect(()=>{
    if (isDeletingFilter){
      setAtValue(undefined)
      setToValue(undefined)
    }
  },[isDeletingFilter])

  return (
    <div className='filterRange__container centerFlex'>
        <h3 className='filterRange__title'>{title}</h3>
        <div className="filter__border">
          <div className="filter__border-block">
            <input placeholder='От' value={atValue ? atValue : ''} onChange={(e)=>{changeValue(e.target.value, true)}} className="filter__border-input"  type='number'/>
          </div>
          <div className="filter__border-block">
            <input placeholder='До' value={toValue ? toValue : ''} onChange={(e)=>{changeValue(e.target.value, false)}} className="filter__border-input" type='number'/>
          </div>
        </div>
      </div>
  )
}