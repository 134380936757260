import { useRef, useState } from 'react'
import './modal-component.css'
import Warning from '../warning/warning'
import useOutsideClick from '../../hooks/useOutsideClick'

type ModalComponentProps = {
  title: string,
  onClose: () => void,
  children: JSX.Element
  style?: any
  warning?: { title: string, text?: string}
}

export default function ModalComponent({ title, onClose, children, style, warning }: ModalComponentProps) {
  const [isWarning, setIsWarning] = useState(false)
  const block = useRef<HTMLDivElement>(null)
  return (
    <>
      <div onClick={(e)=>{e.stopPropagation()}} className='modalComponent__container'>
        <div style={style} className='modalComponent__content'>
          <div className='centerFlex modalComponent__firstLine'>
            <h1 className='modalComponent__title'>{title}</h1>
            <div ref={block} className="close__button modalComponent__closeButton" onClick={(e)=>{warning ? setIsWarning(true) : e.stopPropagation();onClose()}}>
              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(0.719608 0.69438 -0.719608 0.69438 0.392899 2.15039)" stroke="black" strokeWidth="2" />
                <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(-0.719608 0.69438 -0.719608 -0.69438 21.6071 1)" stroke="black" strokeWidth="2" />
              </svg>
            </div>
          </div>
          {children}
        </div>
      </div>
      {warning && isWarning && <Warning title={warning.title} text={warning.text} onClose={()=>{setIsWarning(false)}} onConfirm={onClose}/>}
    </>
  )
}