import { useEffect, useState } from "react";
import Spinner from "../../components/spinner/spinner";
import { Accounting, SelectAccounting, TableAccounting } from "../../types/accounting-model";
import axios, { PagesURl } from '../../services/api';
import Modal from 'react-modal';
import './accounting-page.css'
import AccountingFilterTable from "../../components/filter-table/accounting-filter-table/accounting-filter-table";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet-async";
import { SortData } from "../../types/main";
import { stageList } from "../../consts";
import { getTotalStages } from "../../utils/filter-table";
import { useNavigate } from "react-router-dom";
import { getOrganization } from "../../services/organization";
import PaginationBlock from "../../components/pagination-block/pagination-block";
import FilterRadio from "../../filter/filter-radio";
import FilterOptions from "../../filter/filter-options";
import FilterRange from "../../filter/filter-range";
import {getAllProducts} from "../../utils/product";
import { getOptimizeFilter } from "../../utils/get-optimize-filter";
import { useAppSelector } from "../../hooks";
import { getStateOrganizations } from "../../store/organization-process/selectors";
import { removeElementListByValue } from "../../utils";

export default function AccountingPage() {
  const navigate = useNavigate()
  useEffect(()=>{
    if (!getOrganization()){
      navigate('/')
    }
  })

  const [accountings, setAccountings] = useState<TableAccounting[]>([])
  const [isAccountingLoading, setIsAccountingLoading] = useState(true)

  const [isGetAccounting, setIsGetAccounting] = useState(false)
  const [selectAccounting, setSelectAccounting] = useState<SelectAccounting[]>([])

  const [activeStage, setActiveStage] = useState<number | null>(null)
  const [countRows, setCountRows] = useState<number>(stageList[0])
  const [activeFilter, setActiveFilter] = useState<SortData>({take: countRows, skip: 0})
  const [remainsCount, setRemainsCount] = useState(0)

  const [isDisplayFilters, setDisplayFilters] = useState(false)

  const [allBarcodes, setAllBarcodes] = useState<string[] | null>(null)
  const [allNames, setAllNames] = useState<string[] | null>(null)
  const allOrganizations = useAppSelector(getStateOrganizations)
  const [isDeletingFilters, setIsDeletingFilters] = useState(false)
  const [isDeletingSort, setIsDeletingSort] = useState(false)

  const notify = (message: string) => toast(message);

  const baseUrl = PagesURl.REMAINS

  const getOrganizationNames = () => {
    let result = []
    for (let organization of allOrganizations){
      result.push(organization.name)
    }
    return result
  }

  const sortNames: {name: string | JSX.Element, backName: string | null}[] = [
    {name: 'Нет сортировки',backName: null}, 
    {name: 'Товар', backName: 'name'},
    {name: <div className="centerFlex"><span className="sortName">Количество на </span><img alt='OZON' src='img/logo/ozon.svg'/></div>, backName: 'ozonCount'},
    {name: <div className="centerFlex"><span className="sortName">Количество на </span><img alt='WILDBERRIES' src='img/logo/wildberries.svg'/></div>, backName: 'wbCount'},
    {name: <div className="centerFlex"><span className="sortName">Артикул</span><img alt='OZON' src='img/logo/ozon.svg'/></div>, backName: 'articleOzon'},
    {name: <div className="centerFlex"><span style={{marginRight: '5px'}}>Артикул</span><img alt='WILDBERRIES' src='img/logo/wildberries.svg'/></div>, backName: 'articleWB'},
    {name: <div className="centerFlex"><span style={{marginRight: '5px'}}>Штрихкод</span><img alt='штрихкод' src='img/logo/barcode.svg'/></div>, backName: 'vendorCode'}
  ]

  const filterNames = [
    {title: 'Организация', backName: 'organization', values: getOrganizationNames(), type: 'Equals'},
    {title: 'Код товара', backName: 'barcode', values: allBarcodes, type: 'Equals'},
    {title: 'Товар', backName: 'name', values: allNames, type: 'Equals'},
    /* {title: 'Количество на складе', backName: 'warehousesInfo', values: [], type: 'Range'}, */
    {title: <div className="centerFlex"><span className="sortName">Количество на </span><img alt='OZON' src='img/logo/ozon.svg'/></div>, backName: 'ozonCount', values: [], type: 'Range'},
    {title: <div className="centerFlex"><span className="sortName">Количество на </span><img alt='WILDBERRIES' src='img/logo/wildberries.svg'/></div>, backName: 'wbCount', values: [], type: 'Range'}
  ]

  const [checkedFilterArray, setCheckedFilterArray] = useState([
    {
      name: 'barcode',
      length: 0
    },
    {
      name: 'name',
      length: 0
    }
  ])

  const getOrgIdByName = (data: SortData) => {
    const result: number[] = []
    if (!data.filters){
      return result
    }
    for (const filter of data.filters){
      if (filter.field.name === 'organization' && filter.values.length !== 0 && filter.values.length !== allOrganizations.length){
        for (const organization of allOrganizations){
          for (const value of filter.values){
            if (organization.name === value){
              result.push(organization.id)
            }
          }
        }
      }
    }
    let backendString = ''
    for (let i = 0; i< result.length; i++){
      if (i === 0){
        backendString = backendString + `?organizationIds=${result[i]}`
      } else{
        backendString = backendString + `&organizationIds=${result[i]}`
      }
    }
    return backendString
  }
  const deleteOrganizationInFilter = (data: SortData) => {
    let newData = JSON.parse(JSON.stringify(data))
    if (!newData.filters){
      return data
    }
    for (let i=0;i<newData.filters.length; i++){
      if (newData.filters[i].field.name === 'organization'){
        newData.filters = removeElementListByValue(newData.filters, newData.filters[i])
        return newData
      }
    }
    return data
  }

  const getAccountings = async (data : SortData = {take: countRows, skip: 0}) => {
    
    try {
      setIsAccountingLoading(true)
      const optimizeFilter = getOptimizeFilter(data, checkedFilterArray)
      const response = await axios.post<{ data: Accounting[], totalCount: number }>(
          baseUrl + `/find${getOrgIdByName(optimizeFilter)}`, 
          deleteOrganizationInFilter(optimizeFilter),{
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const accountings = response.data.data
      let result: TableAccounting[] = []
      accountings.map((el) => (
        result.push({
          "barcode": el.barcode,
          "name": el.name,
          "warehouseCount": el.warehousesInfo,
          "marketPlaceCount": {ozonCount: el.ozonCount, wbCount: el.wbCount},
          "articleOzon": el.articleOzon,
          "articleWB": el.articleWB,
          "vendorCode": el.vendorCode,
          "isSyncWB": el.isSyncWB,
          "isSyncOzon": el.isSyncOzon,
          "photos": el.photos,
          "rateOfDecreases": el.rateOfDecreases,
          "organizationName": el.organizationName,
          "ozonCount": el.ozonCount,
          "wbCount": el.wbCount
        })
      ))
      setAccountings(result)

      setRemainsCount(response.data.totalCount)
      setActiveFilter(data)
      if (!activeStage){
        setActiveStage(response.data.totalCount > data.take ? 1 : null)
      }
      setIsAccountingLoading(false)
    } catch (error: any) {
      setAccountings([])
      console.log(error)
/*       if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      } */
    }
  }
  useEffect(() => {
    getAccountings()
    getAllProducts().then(result => {
      if (!result){
        return
      }
      let names:string[] = []
      let barcodes:string[] = []
      let newFilters = {...activeFilter}
      for (let point of result){
        names.push(point.name)
        barcodes.push(point.barcode.toString())
      }
      newFilters.filters = []
      newFilters.filters.push({field:{name: 'barcode'}, filterType: 'Equals', values: barcodes})
      newFilters.filters.push({field:{name: 'name'}, filterType: 'Equals', values: names})
      let newCheckedArray = checkedFilterArray.slice()
      newCheckedArray[0].length = barcodes.length
      newCheckedArray[1].length = names.length
      setCheckedFilterArray(newCheckedArray)
      setActiveFilter(newFilters)
      setAllBarcodes(barcodes)
      setAllNames(names)
    })
  }, [])

  const changeActiveStage = (toStage: number) => {
    let newSortData: SortData = JSON.parse(JSON.stringify(activeFilter))
    newSortData.skip = countRows * toStage
    getAccountings(newSortData)
    setActiveStage(toStage + 1)
  }

  const getShipmentByBarcode = async (barcode: number) => {
    try {
      let response = await axios.get<SelectAccounting[]>(`${PagesURl.SHIPMENT}/get/byProductBarcode/?productBarcode=${barcode}`,{
        headers: {
          'Content-Type': 'application/json',
        },
      })
      setSelectAccounting(response.data)
      setIsGetAccounting(true)
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }

  const changeSelectedValue = (newValue: number) => {
    setActiveStage(null)
    setCountRows(newValue)
    getAccountings({take: newValue, skip: 0})
  }

  const changeSort = (backName: string | null) => {
    setIsDeletingSort(false)
    if (!backName){
      setActiveFilter({...activeFilter, sort: undefined})
      submitSort({...activeFilter, sort: undefined})
    } else {
      setActiveFilter({...activeFilter, sort: {field:{name:backName}, direction: 'Ascending'}})
      submitSort({...activeFilter, sort: {field:{name:backName}, direction: 'Ascending'}})
    }
  }

  const changeFilter = (newValues: string[], backName: string, type: 'Range' | 'Equals') => {
    setIsDeletingFilters(false)
    let flag = false
    let newFilters = { ...activeFilter };
    if (!newFilters.filters) {
      newFilters.filters = []
    }
    for (let filter of newFilters.filters) {
      if (filter.field.name === backName) {
        filter.values = newValues;
        if (newValues.length === 0){
          newFilters.filters.filter((filter)=>(filter.values.length !== 0))
        }
        flag = true
      }
    }
    if (!flag){
      newFilters.filters.push({field:{name:backName}, filterType: type, values: newValues})
    }
    setActiveFilter(newFilters);
  }
  const deleteFilters = () => {
    if (!allBarcodes || !allNames){
      return 
    }
    let newFilters = {...activeFilter}
    newFilters.filters = []
    newFilters.filters.push({field:{name: 'barcode'}, filterType: 'Equals', values: allBarcodes})
    newFilters.filters.push({field:{name: 'name'}, filterType: 'Equals', values: allNames})
    setActiveFilter(newFilters)
    setIsDeletingFilters(true)
    return newFilters
  }

  const submitSort = (activeFilter: SortData) => {
    if (!activeFilter.sort){
      getAccountings()
      return
    }
    if (!activeFilter.sort.field){
      getAccountings()
      return 
    }
    const newSortData:SortData = {
      take: countRows,
      skip: 0,
      sort: activeFilter.sort
    }
    getAccountings(newSortData)
  }
  const deleteSort = () => {
    setIsDeletingSort(true)
    return {...activeFilter, sort: undefined}
  }

  const deleteAndUpdate = (isSort:boolean) => {
    getAccountings(isSort ? deleteSort() : deleteFilters())
  }

  const submitAll = () => {
    getAccountings({...activeFilter, take: countRows, skip: 0})
  }

  return (
    <>
      <Helmet>
        <title>Товароучет</title>
      </Helmet>
      <div className="accounting-container">
        <PaginationBlock
          onChangeSelectedValue={changeSelectedValue}
          activeStage={activeStage}
          getTotalStages={()=>{return getTotalStages(remainsCount, countRows)}}
          countRows={countRows}
          changeActiveStage={changeActiveStage}
          children={!isAccountingLoading ?
            <AccountingFilterTable activeFilter={activeFilter} onFilter={getAccountings} content={accountings} getPointInfo={getShipmentByBarcode}
            /> : <Spinner />}
        />
        {isDisplayFilters ?
          <div className="accounting__filtersContainer">
            <img onClick={() => { setDisplayFilters(false) }} className="pointer" alt='' src='img/close.svg' />
            <div className="accounting__filtersContent">
              <div className="accounting__filtersSort">
                <h3 className="accounting__filtersTitle">Сортировка</h3>
                <div className="accounting__filtersSortContent">
                  <FilterRadio isDeletingSort={isDeletingSort} changeValue={changeSort} content={sortNames} />
                </div>
              </div>
              <div className="accounting__filters">
                <h3 className="accounting__filtersTitle">Фильтр</h3>
                {filterNames.map((filter) => (
                  filter.type === 'Range' ?
                    <FilterRange
                      isDeletingFilter={isDeletingFilters}
                      changeFilterValue={(newValues: string[]) => { changeFilter(newValues, filter.backName, 'Range') }}
                      key={filter.backName} title={filter.title} /> :
                    filter.values &&
                    <FilterOptions
                      isDeletingFilter={isDeletingFilters}
                      changeFilterValue={(newValues: string[]) => { changeFilter(newValues, filter.backName, 'Equals') }}
                      values={filter.values} key={filter.backName} title={filter.title} />
                ))}
                <div className='accounting__filter__applybtns centerFlex'>
                  <button style={{ marginBottom: '10px' }} onClick={submitAll} className='filter__apply-btn'>Применить фильтры</button>
                  <span onClick={deleteFilters} className='fliter__toDefault pointer'>Сбросить фильтры</span>
                </div>
              </div>
            </div>
            {/*             <div className='accounting__filter__applybtns centerFlex'>
              <button style={{ marginBottom: '10px', marginTop: '20px' }} onClick={submitAll} className='filter__apply-btn'>Применить все</button>
              <span onClick={()=>{deleteFilters();deleteSort()}} className='fliter__toDefault pointer'>Сбросить все</span>
            </div> */}
          </div> : 
        <div className="accounting__filtersIcons centerFlex">
            <img onClick={()=>{setDisplayFilters(true)}} className="pointer" alt='' src='img/accounting/active-filters.svg'/>
            <img onClick={()=>{deleteAndUpdate(false)}} className="pointer" alt='' src='img/accounting/disable-filters.svg'/>
            <img onClick={()=>{deleteAndUpdate(true)}} className="pointer" alt='' src='img/accounting/disable-sort.svg'/>
        </div>
        }
        <Modal
          isOpen={isGetAccounting}
          ariaHideApp={false}
          contentLabel="Get Accounting Info"
          className="modal"
          overlayClassName="modal-overlay"
          onRequestClose={() => { setIsGetAccounting(false) }}
        >
          <div>
            {selectAccounting && <div className='modal-contentAccounting'>
              <div className="closeInfo__button" onClick={() => { setIsGetAccounting(false) }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(0.719608 0.69438 -0.719608 0.69438 0.392899 2.15039)" stroke="black" strokeWidth="2" />
                  <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(-0.719608 0.69438 -0.719608 -0.69438 21.6071 1)" stroke="black" strokeWidth="2" />
                </svg>
              </div>
              {selectAccounting.length !== 0 ? <table>
                <thead>
                  <tr>
                    <th>Код товара</th>
                    <th>Товар</th>
                    <th>Количество на складе</th>
                    <th>Количество на маркетплейсе</th>
                  </tr>
                </thead>
                <tbody>
                  {selectAccounting.map((el) => (
                    <tr key={el.barcode}>
                      <td>{el.barcode}</td>
                      <td>{el.name}</td>
                      <td>{el.warehouseCount}</td>
                      <td>{el.marketPlaceCount}</td>
                    </tr>
                  ))}
                </tbody>
              </table> : 'Истории нет'}
            </div>
            }
          </div>
        </Modal>
      </div>
    </>
  )
}