import { useEffect, useState } from 'react'
import CreateButton from '../../components/buttons/create-button/create-button'
import EditBlock from '../../components/edit-block/edit-block'
import Spinner from '../../components/spinner/spinner'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { addParticipantsAction, deleteParticipantsAction, editActiveOrganizationAction, getActiveOrganizationAction, getOrganizationsAPI, getParticipantsAction } from '../../store/api-actions/organization-api-actions'
import { getErrorMessage, getIsLoadParticipants, getParticipants, getStateActiveOrganization } from '../../store/organization-process/selectors'
import { getUserInfo } from '../../store/user-process/selectors'
import { Participant } from '../../types/organization-model'
import './organization-page.css'
import ParticipantsFilterTable from '../../components/filter-table/participants-filter-table/participants-filter-table'
import MainBlockModal from '../../components/main-block/main-block-modal/main-block-modal'
import { changeStateActiveOrganization } from '../../store/organization-process/organization-actions'
import SynchronizationBlock from '../../components/synchronization-block/synchronization-block'
import axios, { PagesURl } from '../../services/api';
import PaginationBlock from '../../components/pagination-block/pagination-block'
import { stageList } from '../../consts'
import { getTotalStages } from '../../utils/filter-table'
import Warning from '../../components/warning/warning'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import ParticipantRoleFields from './participant-role-fields'
import { Entities, ParticipantRole } from '../../types/enums/organization-type'
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from '../../components/custom-input/phone-input/phone-input'
import ButtonContainer from '../../components/buttons/button-container/button-container'
import { dropOrganization, getOrganization } from '../../services/organization'
import { redirectToRoute } from '../../store/action'

export default function OrganizationPage() {
  const notify = (message: string) => toast(message);

  const dispatch = useAppDispatch()

  const error = useAppSelector(getErrorMessage)
  const activeOrganization = useAppSelector(getStateActiveOrganization)
  const participants = useAppSelector(getParticipants)
  const user = useAppSelector(getUserInfo)
  const isGetparticipants = useAppSelector(getIsLoadParticipants)

  const [activeStage, setActiveStage] = useState<number | null>(1)
  const [productCount, setProductCount] = useState(participants.length)
  const [countRows, setCountRows] = useState<number>(stageList[0])
  const [activeUser, setActiveUser] = useState<Participant | null>(null)
  const [addUserInfo, setAddUserInfo] = useState<{ role: ParticipantRole, entities: Entities[] }>({ role: 0, entities: [] })
  const [loginToAdd, setLoginToAdd] = useState<string | null>(null)
  const [displayAddUserInfo, setDisplayAddUserInfo] = useState(false)

  const [organizationNameValue, setOrganizationNameValue] = useState(activeOrganization?.name)
  const [isActiveEditOrganizationName, setIsActiveEditOrganizationName] = useState(false)

  const [wbKeyValue, setWbKeyValue] = useState(activeOrganization?.wbApiKey)
  const [ozonApiValue, setOzonApiValue] = useState(activeOrganization?.ozonApiKey)
  const [ozonIdValue, setOzonIdValue] = useState(activeOrganization?.ozonClientId)
  const [isActiveEditBlock, setIsActiveEditBlock] = useState(false)

  const [editUser, setEditUser] = useState(false)
  const [isActiveKeys, setIsActiveKeys] = useState(false)
  const [isActiveWarning, setIsActiveWarning] = useState(false)
  const [isActiveOzonWarning, setIsActiveOzonWarning] = useState(false)
  const [addUsers, setAddUsers] = useState(false)

  const [onDeleteUser, setOnDeleteUser] = useState<boolean>(false)

  const handleChangeOrganizationName = (name: string) => {
    if (activeOrganization) {
      dispatch(editActiveOrganizationAction({ id: activeOrganization.id, name: name }))
    }
  }

  const handleAddPartisipants = (phone: string) => {
    if (activeOrganization) {
      dispatch(addParticipantsAction({ 
        organizationId: activeOrganization.id,
        userPhone: phone, 
        role: addUserInfo.role, 
        entities: addUserInfo.entities.reduce((total, current) => total + current, 0)
      }))
      onCloseAddUser()
    }
  }

  const changeMarketAPI = () => {
    if (activeOrganization) {
      dispatch(editActiveOrganizationAction({ 
        id: activeOrganization.id, 
        wbApiKey: wbKeyValue,
        ozonApiKey: ozonApiValue,
        ozonClientId: ozonIdValue
      }))
    }
  }

  const displayEditUser = (number: string) => {
    for (let participant in participants) {
      if (participants[participant].phone === number) {
        setActiveUser(participants[participant])
        setEditUser(true)
        return
      }
    }
  }

  const handleSynchronizationWB = async () => {
    try{
      notify('Данные синхронизируются...')
      await axios.post(PagesURl.ExternalData + '/full-synchronize/byWBApi')
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
    
  }
  const handleSynchronizationOzon = async () => {
    try{
      notify('Данные синхронизируются...')
      await axios.post(PagesURl.ExternalData + '/full-synchronize/byOzonApi')
    }catch (error: any) {
      console.log(error)
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }
  const handleDeleteYourself = () => {
    const activeOrganization = getOrganization()
    if (!activeOrganization || !user){
      return
    }
    dropOrganization()
    dispatch(deleteParticipantsAction({organizationId: activeOrganization, phonesToDelete:[user.phone]}))
    dispatch(changeStateActiveOrganization(null))
    dispatch(getOrganizationsAPI())
    dispatch(redirectToRoute('./'))
  }

  const changeSelectedValue = (newValue: number) => {
    setCountRows(newValue)
  }

  const changeActiveStage = (toStage: number) => {
    setActiveStage(toStage + 1)
  }

  const getTableParticipants = () => {
    if (participants && activeStage) {
      return participants.slice((activeStage - 1) * countRows, activeStage * countRows)
    }
    return participants
  }

  const onCloseAddUser = () => {
    setAddUsers(false); 
    setAddUserInfo({ role: 0, entities: [] }); 
    setDisplayAddUserInfo(false)
    setLoginToAdd(null)
  }

  const onEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && organizationNameValue) {
      handleChangeOrganizationName(organizationNameValue)
      setIsActiveEditOrganizationName(false)
    }
  };

  useEffect(() => {
    if (activeOrganization) {
      dispatch(changeStateActiveOrganization(activeOrganization))
      dispatch(getParticipantsAction(activeOrganization?.id))
      setOrganizationNameValue(activeOrganization.name)
      setWbKeyValue(activeOrganization.wbApiKey)
      setOzonApiValue(activeOrganization.ozonApiKey)
      setOzonIdValue(activeOrganization.ozonClientId)
    } else {
      dispatch(getActiveOrganizationAction())
    }
  }, [activeOrganization, dispatch])

  useEffect(() => { setProductCount(participants.length) }, [participants])

  useEffect(() => { error && notify(error) }, [error])

  if (!user) {
    return <Spinner />
  }


  return (
    <>
      <Helmet>
        <title>{activeOrganization ? activeOrganization.name : 'Организация'}</title>
      </Helmet>
      {activeOrganization && 
        <div className='organization__container'>
          <div className='mainBlock__editName'>
            {!isActiveEditOrganizationName ?
              <div className='pointer centerFlex' onClick={()=>{setIsActiveEditOrganizationName(true)}}>
                <p className='organization__name'>{organizationNameValue}</p>
                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.3623 17.4441H18.3716M2.3623 17.4441V13.4719L10.3669 5.52746M2.3623 17.4441H6.36462L14.3692 9.49966M10.3669 5.52746L13.2372 2.67872L13.239 2.67703C13.634 2.2849 13.8319 2.08848 14.0601 2.01491C14.2611 1.95011 14.4775 1.95011 14.6785 2.01491C14.9065 2.08843 15.1042 2.28462 15.4987 2.6762L17.2395 4.40392C17.6357 4.79719 17.834 4.99391 17.9082 5.22066C17.9735 5.4201 17.9734 5.63494 17.9082 5.83439C17.8341 6.06097 17.6361 6.2574 17.2404 6.6501L17.2395 6.65094L14.3692 9.49966M10.3669 5.52746L14.3692 9.49966" stroke="#1A1B1C" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div> : <input className='editInput__textInputActive' onKeyUp={(e) => { onEnter(e) }} value={organizationNameValue} onChange={(e)=>{setOrganizationNameValue(e.target.value)}}/>
            }
          </div>
          <div className='organization__firstLine flex'>
            <div className='organization__keysBlock pointer'>
              <div onClick={() => { setIsActiveKeys(!isActiveKeys);setIsActiveEditBlock(false) }} className='centerFlex organization__keysContent'>
                <p className='organization__keysBlockText'>Ключи доступа к МП</p>
                <div>
                  {isActiveKeys && (isActiveEditBlock && user?.role === 3 ? 
                    <img onClick={changeMarketAPI} alt='' src='img/save-button.svg'/> : 
                    <img onClick={(e)=>{e.stopPropagation();setIsActiveEditBlock(true)}} src='img/edit-pencil-button.svg' alt=''/>)}
                  <img className={`organization__keysImg ${isActiveKeys ? 'transform180' : ''}`} alt='' src='img/select-list-arrow-border.svg' />
                </div>
              </div>
              {isActiveKeys && wbKeyValue!==undefined && ozonApiValue!==undefined && ozonIdValue!==undefined &&
                <div className='organization__keysBlockKeys'>
                  <div className='flex mainBlock__keysBlock'>
                    <EditBlock
                      valueStyle={{ color: '#000', fontSize: '16px', fontWeight: '400' }}
                      value={wbKeyValue}
                      onChange={(value: string) => { setWbKeyValue(value) }}
                      name='Api-Ключ WB'
                      isActive={isActiveEditBlock}
                    />
                  </div>
                  <div className='flex mainBlock__keysBlock'>
                    <EditBlock
                      valueStyle={{ color: '#000', fontSize: '16px', fontWeight: '400' }}
                      value={ozonApiValue}
                      onChange={(value: string) => { setOzonApiValue(value) }}
                      name='Api-Ключ Ozon'
                      isActive={isActiveEditBlock}
                    />
                  </div>
                  <div className='flex mainBlock__keysBlock'>
                    <EditBlock
                      valueStyle={{ color: '#000', fontSize: '16px', fontWeight: '400' }}
                      value={ozonIdValue}
                      onChange={(value: string) => { setOzonIdValue(value) }}
                      name='ID Клиента OZON'
                      isActive={isActiveEditBlock}
                    />
                  </div>
                </div>}
            </div>
            {(user.entities.includes(64) || user.entities.includes(32)) &&
              <Link to='/warehouse' className='organization__buttonsBlock pointer link'>
                <div className='organization__button centerFlex'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="25" viewBox="0 0 23 25" fill="none">
                    <path d="M21.25 7.52092L13.75 0.953774C13.0625 0.339565 12.1724 0 11.25 0C10.3276 0 9.43758 0.339565 8.75006 0.953774L1.25011 7.52092C0.853073 7.87558 0.53624 8.31067 0.320706 8.79719C0.105172 9.28372 -0.00411567 9.81053 0.000118492 10.3425V21.2545C0.000118492 22.2479 0.395204 23.2005 1.09846 23.903C1.80172 24.6054 2.75554 25 3.75009 25H18.75C19.7445 25 20.6984 24.6054 21.4016 23.903C22.1049 23.2005 22.5 22.2479 22.5 21.2545V10.3301C22.5024 9.80014 22.3923 9.27574 22.1768 8.7915C21.9613 8.30725 21.6454 7.87419 21.25 7.52092ZM13.75 22.503H8.75006V16.2605C8.75006 15.9293 8.88175 15.6118 9.11617 15.3776C9.35059 15.1435 9.66853 15.012 10 15.012H12.5C12.8315 15.012 13.1495 15.1435 13.3839 15.3776C13.6183 15.6118 13.75 15.9293 13.75 16.2605V22.503ZM20 21.2545C20 21.5856 19.8683 21.9032 19.6339 22.1373C19.3994 22.3715 19.0815 22.503 18.75 22.503H16.25V16.2605C16.25 15.2671 15.8549 14.3144 15.1517 13.612C14.4484 12.9096 13.4946 12.5149 12.5 12.5149H10C9.00549 12.5149 8.05167 12.9096 7.34842 13.612C6.64516 14.3144 6.25007 15.2671 6.25007 16.2605V22.503H3.75009C3.41857 22.503 3.10063 22.3715 2.86622 22.1373C2.6318 21.9032 2.5001 21.5856 2.5001 21.2545V10.3301C2.50033 10.1528 2.53834 9.9776 2.61162 9.81614C2.6849 9.65469 2.79176 9.51067 2.9251 9.39367L10.425 2.83902C10.6532 2.63886 10.9464 2.52847 11.25 2.52847C11.5537 2.52847 11.8469 2.63886 12.075 2.83902L19.575 9.39367C19.7083 9.51067 19.8152 9.65469 19.8885 9.81614C19.9617 9.9776 19.9998 10.1528 20 10.3301V21.2545Z" fill="white" />
                  </svg>
                  <span className='organization__buttonText'>Склады</span>
                </div>
              </Link>}
            {(user.entities.includes(64) || user.entities.includes(1)) &&
              <Link to='/products' className='organization__buttonsBlock pointer link'>
                <div className='organization__button centerFlex'>
                  <img alt='продукты' src="img/icons/product.svg" />
                  <span className='organization__buttonText'>Продукты</span>
                </div>
              </Link>}
          </div>
          <div className='organization__synchronization'>
            {user.role > 0 && user.entities.includes(64) &&
             <div className='organization__synchronizationFirst'>
              <SynchronizationBlock isActive={activeOrganization.wbApiKey ? true : false} 
              text='Полная синхронизация с WB' onSynchronization={() => { setIsActiveWarning(true) }} />
            </div>}
            {user.role > 0 && user.entities.includes(64) &&
             <SynchronizationBlock isActive={activeOrganization.ozonApiKey && activeOrganization.ozonClientId ? true : false} 
             text='Полная синхронизация с OZON' onSynchronization={()=>{setIsActiveOzonWarning(true)}}/>}
          </div>
          <div className='mainBlock__participants participants'>
            <span className='participants__name'>Участники:</span>
            <div className='participants__buttons'>
              {user?.role === 3 && <CreateButton icon='/img/icons/add-participants.svg' text='Добавить участника' createAction={() => { setAddUsers(true) }} />}
              <ButtonContainer onClickAction={()=>{setOnDeleteUser(true)}} style={{width: '182px'}}>
                <div className='participants__exit'>
                  <img className={'participants__exit_icon'} src='img/icons/exit.svg'/>
                  <p className='participants__exit_text'>Выйти</p>
                </div>
              </ButtonContainer>
            </div>
          </div>
          {
            <PaginationBlock
              onChangeSelectedValue={changeSelectedValue}
              activeStage={activeStage}
              getTotalStages={() => { return getTotalStages(productCount, countRows) }}
              countRows={countRows}
              changeActiveStage={changeActiveStage}
              children={<>{!isGetparticipants ? <Spinner /> :
                participants.length !== 0 &&
                <div>
                  <ParticipantsFilterTable changeParticipant={(number: string) => { displayEditUser(number) }} content={getTableParticipants()} />
                </div>}</>}
            />
          }
        </div>}
      {addUsers &&
        <div className='organizationPage__modalContainer'>
          <div className='organizationPage__modalContent'>
            <div className='organizationPage__addTitleBlock centerFlex'>
              <h1 className='organizationPage__addTitle'>Добавить участника</h1>
              <svg onClick={onCloseAddUser} className='pointer' width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(0.719608 0.69438 -0.719608 0.69438 0.392899 2.15039)" stroke="black" strokeWidth="2" />
                <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(-0.719608 0.69438 -0.719608 -0.69438 21.6071 1)" stroke="black" strokeWidth="2" />
              </svg>
            </div>
            <div className='organizationPage__addPhoneBlock centerFlex'>
              <div className='organizationPage__addPhoneInput'>
                <span className='organizationPage__addPhoneName'>Номер телефона: <span className='organizationPage__addPhoneNameRed'>*</span></span>
                <PhoneInput value={loginToAdd ? loginToAdd : ''} onChangeValue={(e)=>{setLoginToAdd(e)}} nameOfClass='organizationPage__addPhone'/>
              </div>
            </div>
            {
              <div className='organizationPage__addInfo'>
                <div className='organizationPage__addInfoLine'></div>
                <ParticipantRoleFields changeUserInfo={setAddUserInfo} userInfo={addUserInfo} />
              </div>}
            <button onClick={() => {loginToAdd && handleAddPartisipants(loginToAdd) }} className='organizationPage__addSubmitButton pointer'>Сохранить</button>
          </div>
        </div>}
      {editUser && activeUser && <MainBlockModal closeModal={() => { setEditUser(false) }} user={activeUser} />}
      {isActiveWarning &&
        <Warning
          title='Внимание!'
          text='Полная синхронизация с маркетплейсом может привести к потере данных. Рекомендуется пользоваться этой функцией только на начальных этапах введения данных.'
          onClose={() => { setIsActiveWarning(false) }}
          onConfirm={handleSynchronizationWB}
        />}
      {isActiveOzonWarning &&
        <Warning
          title='Внимание!'
          text='Полная синхронизация с маркетплейсом может привести к потере данных. Рекомендуется пользоваться этой функцией только на начальных этапах введения данных.'
          onClose={() => { setIsActiveOzonWarning(false) }}
          onConfirm={handleSynchronizationOzon}
        />}
      {onDeleteUser && 
        <Warning 
          title='Вы уверены, что хотите выйти из организации?' 
          text='Это действие нельзя отменить.' 
          onClose={()=>{setOnDeleteUser(false)}}
          onConfirm={handleDeleteYourself}/>}
    </>
  )
}