export enum TaskState {
  Enqueued = 'В очереди',
  Processing = 'В процессе',
  Succeeded = 'Выполнено',
  Recurring = 'Запланирована',
  Failed = 'Ошибка выполнения'
}

export enum TaskDate {
  CreatedAt = 'Дата Создания',
  EnqueuedAt = '',
  StartedAt = 'Дата начала',
  SucceededAt = 'Дата завершения',
  NextExecution = 'Дата следующего запуска',
  LastExecution = 'Дата последнего запуска',
  FailedAt = 'Дата создания'
}