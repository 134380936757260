import './synchronization-block.css'

type SynchronizationProps = {
  text: string,
  onSynchronization: () => void
  isActive: boolean
}

export default function SynchronizationBlock({ text, onSynchronization, isActive }: SynchronizationProps) {
  const sync = () => {
    isActive && onSynchronization()
  }
  return (
    <>
      <div title={!isActive ? 'Заполните данные в карточке организации' : undefined} onClick={sync} className={`${!isActive ? 'synchronization__container_inactive' : 'synchronization__container'} centerFlex`}>
        <p className='synchronization__text'>{text}</p>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
          <path d="M17.91 14.51H13.38C13.1148 14.51 12.8604 14.6154 12.6729 14.8029C12.4854 14.9904 12.38 15.2448 12.38 15.51C12.38 15.7752 12.4854 16.0296 12.6729 16.2171C12.8604 16.4046 13.1148 16.51 13.38 16.51H15.78C14.6769 17.6627 13.2544 18.4593 11.6952 18.7974C10.1359 19.1355 8.51121 18.9996 7.02978 18.4072C5.54834 17.8149 4.27787 16.7931 3.38159 15.4732C2.48531 14.1532 2.00418 12.5955 2 11C2 10.7348 1.89464 10.4804 1.70711 10.2929C1.51957 10.1054 1.26522 10 1 10C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11C0.00528666 12.9528 0.582221 14.8613 1.6596 16.49C2.73699 18.1187 4.26767 19.3964 6.06274 20.1652C7.85782 20.9341 9.83873 21.1605 11.761 20.8166C13.6833 20.4727 15.4628 19.5735 16.88 18.23V20C16.88 20.2652 16.9854 20.5196 17.1729 20.7071C17.3604 20.8946 17.6148 21 17.88 21C18.1452 21 18.3996 20.8946 18.5871 20.7071C18.7746 20.5196 18.88 20.2652 18.88 20V15.5C18.8775 15.2416 18.7752 14.9943 18.5943 14.8097C18.4135 14.6251 18.1683 14.5177 17.91 14.51ZM10 1C7.43639 1.00731 4.97349 1.99891 3.12 3.77V2C3.12 1.73478 3.01464 1.48043 2.82711 1.29289C2.63957 1.10536 2.38522 1 2.12 1C1.85478 1 1.60043 1.10536 1.41289 1.29289C1.22536 1.48043 1.12 1.73478 1.12 2V6.5C1.12 6.76522 1.22536 7.01957 1.41289 7.20711C1.60043 7.39464 1.85478 7.5 2.12 7.5H6.62C6.88522 7.5 7.13957 7.39464 7.32711 7.20711C7.51464 7.01957 7.62 6.76522 7.62 6.5C7.62 6.23478 7.51464 5.98043 7.32711 5.79289C7.13957 5.60536 6.88522 5.5 6.62 5.5H4.22C5.32247 4.34787 6.74409 3.5515 8.3024 3.21311C9.86071 2.87472 11.4846 3.00975 12.9656 3.60086C14.4466 4.19198 15.7172 5.21221 16.6142 6.5306C17.5113 7.849 17.9938 9.40539 18 11C18 11.2652 18.1054 11.5196 18.2929 11.7071C18.4804 11.8946 18.7348 12 19 12C19.2652 12 19.5196 11.8946 19.7071 11.7071C19.8946 11.5196 20 11.2652 20 11C20 9.68678 19.7413 8.38642 19.2388 7.17317C18.7362 5.95991 17.9997 4.85752 17.0711 3.92893C16.1425 3.00035 15.0401 2.26375 13.8268 1.7612C12.6136 1.25866 11.3132 1 10 1Z" fill="#0042E4" />
        </svg>
      </div>
    </>
  )
}