export {};

declare global {
  interface Array<T> {
    fullClone(): Array<T>;
  }
}

if (!Array.prototype.fullClone) {
  Array.prototype.fullClone = function<T>(this: T[]): T[] {
    return JSON.parse(JSON.stringify(this));
  }
}