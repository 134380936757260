import { useRef, useState } from "react"
import '../filter-table.css'
import useOutsideClick from "../../../hooks/useOutsideClick"
import { MarketProduct, Product, WarehouseProduct } from "../../../types/product-model"
import ModalComponent from "../../modal-component/modal-component"

type TableFullBlockProps = {
  fullContent: WarehouseProduct[] | MarketProduct[] | string
  briefContent: string
  title: string
}
export default function TableFullBlock({fullContent, briefContent, title}:TableFullBlockProps){
  const blockRef = useRef(null)
  const [isDisplayFull, setIsDisplayFull] = useState(false)

  useOutsideClick(blockRef, (e)=>{e.stopPropagation();setIsDisplayFull(false)})

  const fullContentIsWarehouseProduct = (fullContent: any): fullContent is WarehouseProduct => {
    return typeof fullContent.name === 'string'
  }


  return (
    <div ref={blockRef} className="tableFullBlock__container centerFlex">
      <p onClick={(e) => { e.stopPropagation(); setIsDisplayFull(!isDisplayFull) }} className="table__content_products">{briefContent}</p>
      {isDisplayFull &&
        <ModalComponent onClose={()=>{setIsDisplayFull(!isDisplayFull)}} title={title}>
          <>
          {typeof fullContent === 'string' ? <p>{fullContent}</p>:
          <div>
            {fullContent.map((el)=>(
              fullContentIsWarehouseProduct(el) ? 
              <div key={el.productBarcode} className="tableFullBlock__line">
                <p className="tableFullBlock__text">{el.name}</p>
                <p className="tableFullBlock__quantity">{el.quantity} шт</p>
              </div> : 
              <div key={el.productBarcode} className="tableFullBlock__line">
                <p className="tableFullBlock__text">{el.productName}</p>
                <p className="tableFullBlock__quantity">{el.totalQuantity} шт</p>
              </div>
            ))}
          </div>}
          </>
        </ModalComponent>}
    </div>
  )

}