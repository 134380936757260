import { useState } from "react"
import { Product } from "../../types/product-model"
import './modal-window.css';
import { Warehouse } from "../../types/warehouse-model";

type CustomSelectProps = {
  changeValue: (element: number | string, name: string) => void;
  options: string[] | Product[] | Warehouse[] | undefined;
  name: string;
  selectedOpt?: string | Product | Warehouse | undefined
}

export default function CustomSelect({ changeValue, options, name, selectedOpt}: CustomSelectProps) {
  const [displayOptions, setDisplayOptions] = useState(false)

  const isProduct = (option: any): option is Product => {
    return typeof option.barcode === 'number'
  }

  const [selectedOption, setSelectedOption] = useState(selectedOpt ? typeof selectedOpt === 'string' ? selectedOpt :  isProduct(selectedOpt) ? `${selectedOpt.name}[${selectedOpt.barcode}]` : selectedOpt.name : '')

  const submitChange = (index: number, el: string | Product | Warehouse) =>{
    setDisplayOptions(false)
    if (typeof el === 'string'){
      changeValue(index, name)
      setSelectedOption(el)
    } else if (options){
      const option = options[index]
      if (typeof option !== 'string'){
        isProduct(option) ? changeValue(option.barcode, name) : changeValue(option.id, name)
        setSelectedOption(el.name)
      }
    }
  }

  return (
    <>
      <div className={`close__select ${displayOptions && 'close__selectActive'}`} onClick={() => { setDisplayOptions(!displayOptions) }}>
        {selectedOption !== '' && selectedOption}
        <svg className="close__selectSvg" width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.0641657 2.39908L5.32107 8.98908C5.40801 9.09788 5.59185 9.09788 5.67868 8.98908L10.9359 2.39874C10.9985 2.31992 11.017 2.20231 10.9833 2.10016C10.9763 2.07888 10.9685 2.0624 10.962 2.05028C10.9219 1.97592 10.8447 1.92936 10.7601 1.92936L0.239426 1.92936C0.155228 1.92936 0.0776648 1.97569 0.0378537 2.05028C0.0311041 2.06263 0.0233249 2.07922 0.0168041 2.09901C-0.0171726 2.20231 0.00124577 2.32003 0.0641657 2.39908Z" fill={displayOptions ? "#007BFF" : "#808080"} />
        </svg>
      </div>
      {displayOptions &&<div className="close__selectList">
        {options && options.map((el, index)=>(<div key={index} onClick={()=>{submitChange(index, el)}} className="close__selectListPoint">{typeof el === 'string' ? el :  isProduct(el) ? `${el.name}[${el.barcode}]` : el.name}</div>))}
      </div>}
    </>
  )
}