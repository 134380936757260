import axios, { BACKEND_URL, PagesURl } from '../services/api';
import { getToken } from '../services/token';
import { ChangeProduct, CreateProduct, Product } from '../types/product-model';
import axios2 from 'axios'

export const getAllProducts = async (): Promise<Product[] | undefined> => {
  try {
      // Отправка запроса на сервер для получения списка товаров
      const response = await axios2.post(BACKEND_URL + PagesURl.PRODUCT + '/find', null,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + getToken()
        },
      });
      const products  = response.data.data;
      return products
  } catch (error) {
      console.log('Error:', error);
  }
};

export const getAllProductsFildNames = async (field: keyof Product) => {
  try {
      const response = await axios.post<{data: Product[], totalCount: number}>(PagesURl.PRODUCT + '/find', null,{
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const products = response.data.data;
      let result = []
      for (let product in products){
        result.push(products[product][field])
      }
      return result
  } catch (error) {
      console.error('Error:', error);
  }
};



export function checkCreateProduct(fields: any): fields is CreateProduct{
  return (
    typeof fields.name === "string" &&
    typeof fields.barcode === "number" &&
    typeof fields.articleOzon === 'number' &&
    typeof fields.articleWB === 'number'
  )
}
export function checkChangeProduct(fields: any): fields is ChangeProduct{
  return (
    typeof fields.name === "string" &&
    typeof fields.barcode === "number" &&
    typeof fields.articleOzon === 'number' &&
    typeof fields.articleWB === 'number'
  )
}