import { NameSpace } from '../../consts';
import { State } from '../../types/state';

export const getStateOrganizations = (state: Pick<State, typeof NameSpace.Organization>) => 
  state[NameSpace.Organization].organizations;
export const getStateActiveOrganization = (state: Pick<State, typeof NameSpace.Organization>) => 
  state[NameSpace.Organization].activeOrganization
export const getParticipants = (state: Pick<State, typeof NameSpace.Organization>) => 
  state[NameSpace.Organization].users
export const getIsLoadParticipants = (state: Pick<State, typeof NameSpace.Organization>) => 
  state[NameSpace.Organization].isGetUsers
export const getIsLoadOrganizations = (state: Pick<State, typeof NameSpace.Organization>) => 
  state[NameSpace.Organization].isGetOrganizations
export const getIsLoadActiveOrganization = (state: Pick<State, typeof NameSpace.Organization>) => 
  state[NameSpace.Organization].isGetActiveOrganization
export const getErrorMessage = (state: Pick<State, typeof NameSpace.Organization>) => 
  state[NameSpace.Organization].errorMessage
export const getInvitationsLength = (state: Pick<State, typeof NameSpace.Organization>) => 
  state[NameSpace.Organization].notificationLength
export const getSyncErrorsLength = (state: Pick<State, typeof NameSpace.Organization>) => 
  state[NameSpace.Organization].syncErrorsLength
export const getTasksLength = (state: Pick<State, typeof NameSpace.Organization>) => 
  state[NameSpace.Organization].tasksLength
