import { Product, TableProduct } from './../product-model';

export const ProductNames: { [K in keyof TableProduct]: string } = {
  name: 'Название',
  photos: 'Товар',
  barcode: 'Баркод(ШК)',
  articleWB: 'Артикул WB',
  articleOzon: 'Артикул Ozon'
}

export const AddWarehouseProductsNames = {
  barcode: 'Баркод(ШК)',
  name: 'Название продукта',
  quantity: 'Количество',
  netCost: 'Себестоимость',
  total: 'Сумма',
  articleOzon: 'Артикул Ozon',
  articleWB: 'Артикул WB',
  warehouseInfo: 'Остаток на складах'
}

export const AddMarketplaceProductNames = {
  productName: 'Наименование',
  totalQuantity: 'Количество',
  articleOzon: 'Артикул Ozon',
  articleWb: 'Артикул WB',
  productBarcode: 'Штрих код',
  warehouseCount: 'Остаток на складе'
}