import { Participant } from '../../../types/organization-model'
import '../filter-table.css'
import { getText } from '../../../utils'
import DetailsBlock from '../details-block/details-block'
import { getOrganization } from '../../../services/organization'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { deleteParticipantsAction } from '../../../store/api-actions/organization-api-actions'
import { Entities, ParticipantRole } from '../../../types/enums/organization-type'
import { getUserInfo, getUserLoading } from '../../../store/user-process/selectors'
import Spinner from '../../spinner/spinner'

type ParticipantsFilterTableProps = {
  content: Participant[]
  changeParticipant: (number: string) => void
}

export default function ParticipantsFilterTable({ content, changeParticipant }: ParticipantsFilterTableProps) {
  const dispatch = useAppDispatch()
  const user = useAppSelector(getUserInfo)
  const isUserLoading = useAppSelector(getUserLoading)
  const deleteParticipant = (index: number) => {
    const activeOrganization = getOrganization()
    if (activeOrganization){
      const data = {organizationId: activeOrganization, phonesToDelete: [content[index].phone]}
      dispatch(deleteParticipantsAction(data))
    }
  }

  return (
    <>
       {isUserLoading ? <Spinner/> : <table className='borderTable table'>
          <thead className='thead'>
            <tr className="table__tr">
              <th key={'Номер телефона'} className="table__th">Номер телефона</th>
              <th key={'Имя участника'} className="table__th">Имя участника</th>
              <th key={'Роль'} className="table__th">Роль</th>
            </tr>
          </thead>
          <tbody>
            {content.map((el, indexLen) => (
              <tr key={`${el.name}--${indexLen}`} style={{ cursor: 'pointer' }}>
                 {Object.keys(el).map((key, index) => (
                 Object.keys(el).length !== index + 1 &&
                 <td className={`table__td ${indexLen === content.length - 1 ? 'last__td' : '' }`} key={key}>
                  <div className='table__content'>
                    {key === 'role' ? <div className='flex'><span style={{marginRight: '15px'}}>{ParticipantRole[el[key]]}:</span> <p>{el.entities.map((el)=>Entities[el]).join(", ")}</p></div> : getText(el[key as keyof Participant])}
                    {index + 2 === Object.keys(el).length && user?.role === 3 && 
                      <DetailsBlock changeText='Изменить права доступа' displayEdit={true} changeAction={()=>{changeParticipant(el.phone)}} deleteAction={deleteParticipant} index={indexLen}/>
                    }
                  </div>
                </td>))}
              </tr>
            ))
            }
          </tbody>
        </table>}
    </>
  )
}