import { createSlice } from "@reduxjs/toolkit";
import { AuthorizationStatus } from "../../types/enums/account-type";
import { UserProcess } from "../../types/state";
import { NameSpace } from "../../consts";
import { checkAuthAction, getUserInfoAction, loginAction, registrationAction } from "../api-actions/user-api-actions";
import { dropToken } from "../../services/token";
import { dropOrganization } from "../../services/organization";
import { deleteFirstLoginAction, logoutAction } from "./user-action";

const initialState: UserProcess = {
  authorizationStatus: AuthorizationStatus.Unknown,
  userInfo: null,
  userRoles: null,
  isUserLoading: true,
  isUserRolesLoading: true,
  isRegistration: false,
  isFirstLogin: false,
  error: null
}

export const userProcess = createSlice({
  name: NameSpace.User,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(checkAuthAction.rejected, (state) => {
        state.authorizationStatus = AuthorizationStatus.NoAuth
        dropToken()
        dropOrganization()
        window.location.href = 'login'
      })
      .addCase(checkAuthAction.fulfilled, (state, action) => {
        state.authorizationStatus = action.payload
      })
      .addCase(registrationAction.pending, (state) => {
        state.isRegistration = true
      })
      .addCase(registrationAction.fulfilled, (state, action) => {
        state.isRegistration = false
        if (!action.payload){
          state.isFirstLogin = true
        }
        state.error = action.payload ?? null;
      })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.authorizationStatus = action.payload ? AuthorizationStatus.NoAuth : AuthorizationStatus.Auth;
        state.error = action.payload ?? null;
      })
      .addCase(logoutAction, (state) => {
        state.authorizationStatus = AuthorizationStatus.NoAuth
        dropToken()
        dropOrganization()
        state.userInfo = null
        window.location.href = 'login'
      })
      .addCase(getUserInfoAction.pending, (state) => {
        state.isUserLoading = true
        state.isUserRolesLoading = true
      })
      .addCase(getUserInfoAction.rejected, (state) => {
        state.authorizationStatus = AuthorizationStatus.NoAuth
        dropToken()
        dropOrganization()
        state.isUserLoading = false
        window.location.href = 'login'
      })
      .addCase(getUserInfoAction.fulfilled, (state, action) => {
        state.userInfo = action.payload
        if (action.payload.role !== null){
          state.userRoles = {role: action.payload.role, entities: action.payload.entities}
          state.isUserRolesLoading = false
        }
        state.isUserLoading = false
      })
      .addCase(deleteFirstLoginAction, (state)=>{
        state.isFirstLogin = false
      })
  },
});