import { CreateSelling, GetSelling, Selling, SoldSelling } from "../types/selling-model"
import axios, { PagesURl } from '../services/api';


export const getAllSelling = async ():  Promise<Selling> => {
  try {
    const response = await axios.post(PagesURl.SELLING + '/find', null,{
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const sellings  = response.data.data;
    return sellings
} catch (error) {
    console.error('Error:', error);
    throw error;
}
}

export function checkCreateSelling(fields: any): fields is CreateSelling{
  return (
    typeof fields.productBarcode === 'number' &&
    typeof fields.quantity === 'number' &&
    typeof fields.shipmentNumber === 'string' &&
    typeof fields.soldAt === 'string'
  )
}
export function checkGetSelling(fields: any): fields is GetSelling{
  return (
    typeof fields.sellingId === 'string'
  )
}
export function checkSoldSelling(fields: any): fields is SoldSelling{
  return (
    typeof fields.sellingId === 'string'
  )
}