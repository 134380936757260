import { formatNumber, getDataValuePaymentHistory } from '../../utils'
import './payment-history-point.css'

type PaymentHistoryPointProps = {
  date: string,
  id: string,
  oldPay: number,
  newPay: number
  price: number,
  description?: string
  onDelete: () => void
}

export default function PaymentHistoryPoint({ date, id, price, description, oldPay, newPay, onDelete }: PaymentHistoryPointProps) {
  return (
    <div className='paymentHistoryPoint__container'>
      <img onClick={onDelete} className='paymentHistoryPoint__delete pointer' alt='' src='img/payment/history-delete.svg'/>
      <div className='paymentHistoryPoint__info centerFlex'>
        <div className='paymentHistoryPoint__leftInfo'>
          <p className='paymentHistoryPoint__date'>Оплата от {getDataValuePaymentHistory(date)}:</p>
          <p className='paymentHistoryPoint__id'>{`${formatNumber(oldPay)} -> ${formatNumber(newPay)}`}</p>
        </div>
        <span className='paymentHistoryPoint__price'>{formatNumber(price)} ₽</span>
      </div>
      {description &&
        <>
          <div className='paymentHistoryPoint__line'></div>
          <div className='paymentHistoryPoint__description flex'>
            <p className='paymentHistoryPoint__descriptionText'>Описание:</p>
            <p className='paymentHistoryPoint__descriptionValue'>{description}</p>
          </div>
        </>
      }
    </div>
  )
}