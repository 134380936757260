import { useEffect, useState } from "react"
import axios, { PagesURl } from '../../services/api'
import { CreateWarehouse, Warehouse } from "../../types/warehouse-model"
import './warehouse-menu.css'
import SynchronizationBlock from "../../components/synchronization-block/synchronization-block"
import Spinner from "../../components/spinner/spinner"
import Card from "../../components/card/card"
import ModalWindow from "../../components/modal-window/modal-window"
import { toast } from "react-toastify"
import { Helmet } from "react-helmet-async"
import { useAppSelector } from "../../hooks"
import { getStateActiveOrganization } from "../../store/organization-process/selectors"
import Warning from "../../components/warning/warning"

export default function WarehouseMenu() {
  const notify = (message: string) => toast(message);

  const [warehouse, setWarehouse] = useState<Warehouse[] | null>(null)

  const [activeChangeWarehouse, setActiveChangeWarehouse] = useState<Warehouse | null>(null)

  const [createWarehouse, setCreateWarehouse] = useState(false)
  const [changeWarehouse, setChangeWarehouse] = useState(false)

  const [isActiveWarning, setIsActiveWarning] = useState(false)
  const [isActiveOzonWarning, setIsActiveOzonWarning] = useState(false)

  const activeOrganization = useAppSelector(getStateActiveOrganization)
 
  const handleFindWarehouse = async () => {
    try{
      const { data } = await axios.post<{ data: Warehouse[], totalCount: number }>(PagesURl.WAREHOUSE + '/find')
      setWarehouse(data.data)
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }
  const handleCreateWarehouse = async (data: CreateWarehouse) => {
    try{
      await axios.post(PagesURl.WAREHOUSE + '/create', data)
      setWarehouse(null)
      handleFindWarehouse()
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }
  const handleChangeWarehouse = async (data: CreateWarehouse) => {
    if (activeChangeWarehouse){
      const newData = {
        ...data,
        id: activeChangeWarehouse.id
      }
      try{
        await axios.put(PagesURl.WAREHOUSE + '/edit', newData)
        setWarehouse(null)
        handleFindWarehouse()
      } catch (error: any) {
        if (error.response.data.match(/Message = (.*?),/)){
          notify(error.response.data.match(/Message = (.*?),/)[1])
        }
      }
    }
  }
  const handleDeleteWarehouse = async (id: string) => {
    try{
      await axios.delete(PagesURl.WAREHOUSE + `/delete?id=${id}`)
      setWarehouse(null)
      handleFindWarehouse()
    } catch (error: any) {
      notify(error.response.data.match(/Message = (.*?),/)[1])
    }
  }
  const handleSynchronizeWB = async () => {
    notify('Данные синхронизируются...')
    await axios.post(PagesURl.ExternalData + '/full-synchronize/byWBApi')
  }
  const handleSynchronizeOzon = async () => {
    notify('Данные синхронизируются...')
    await axios.post(PagesURl.ExternalData + '/full-synchronize/byOzonApi')
  }

  const checkCreateOrganization = (data: any): data is CreateWarehouse => {
    return typeof data.name === 'string' &&
      typeof data.address === 'string' &&
      typeof data.description === 'string'
  }

  useEffect(() => { handleFindWarehouse() }, [])
  if (!warehouse) {
    return <Spinner />
  }
  return (
    <div>
        <Helmet>
          <title>Склады</title>
        </Helmet>
      <div style={{ marginBottom: '13px' }}>
        <SynchronizationBlock isActive={activeOrganization?.wbApiKey ? true : false} text="Cинхронизация с WB" onSynchronization={() => {handleSynchronizeWB()}} />
      </div>
      <SynchronizationBlock isActive={activeOrganization?.ozonApiKey && activeOrganization.ozonClientId ? true : false} text="Cинхронизация с OZON" onSynchronization={() => {handleSynchronizeOzon()}} />
      <div className="warehouseMenu__cards centerFlex">
        {warehouse.map((el) => (
          el.isActive &&
          <Card
            key={el.id}
            onClickAction={() => { }}
            onDeleteAction={() => {handleDeleteWarehouse(el.id)}}
            onChangeAction={()=>{setActiveChangeWarehouse(el); setChangeWarehouse(true)}}
            title={el.name}
            secondLine={el.address}
            centralContent={el.description}
          />
        ))}
        <div onClick={() => { setCreateWarehouse(true) }} className='card__container centerFlex mainBlock__createCard'>
          <div className='createCard__content flex pointer'>
            <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="97" height="97" rx="8.42105" fill="#D9E5FF" />
              <line x1="48.5" y1="18" x2="48.5" y2="79" stroke="#0042E4" strokeWidth="5" />
              <line x1="79" y1="48.5" x2="18" y2="48.5" stroke="#0042E4" strokeWidth="5" />
            </svg>
            <p className='mainBlock__createCardTitle'>Создать склад</p>
          </div>
        </div>
      </div>
      <ModalWindow
        isOpen={createWarehouse}
        onRequestClose={()=>{setCreateWarehouse(false)}}
        title="Создать склад"
        btnText="Создать"
        onSubmit={handleCreateWarehouse}
        fields={[
          {
            name: 'Название',
            backName: 'name',
            type: 'text'
          },
          {
            name: 'Адрес',
            backName: 'address',
            type: 'text',
            required: false
          },
          {
            name: 'Описание',
            backName: 'description',
            type: 'text',
            required: false
          }
        ]}
        checkFields={checkCreateOrganization}
      />
      {activeChangeWarehouse &&
      <ModalWindow
        isOpen={changeWarehouse}
        onRequestClose={()=>{setChangeWarehouse(false)}}
        title="Изменить склад"
        btnText="Изменить"
        onSubmit={handleChangeWarehouse}
        fields={[
          {
            name: 'Название',
            backName: 'name',
            type: 'text',
            value: activeChangeWarehouse?.name
          },
          {
            name: 'Адрес',
            backName: 'address',
            type: 'text',
            value: activeChangeWarehouse?.address
          },
          {
            name: 'Описание',
            backName: 'description',
            type: 'text',
            value: activeChangeWarehouse?.description
          }
        ]}
        checkFields={checkCreateOrganization}
      />}
      {isActiveWarning &&
        <Warning
          title='Внимание!'
          text='Полная синхронизация с маркетплейсом может привести к потере данных. Рекомендуется пользоваться этой функцией только на начальных этапах введения данных.'
          onClose={() => { setIsActiveWarning(false) }}
          onConfirm={handleSynchronizeWB}
        />}
      {isActiveOzonWarning &&
        <Warning
          title='Внимание!'
          text='Полная синхронизация с маркетплейсом может привести к потере данных. Рекомендуется пользоваться этой функцией только на начальных этапах введения данных.'
          onClose={() => { setIsActiveOzonWarning(false) }}
          onConfirm={handleSynchronizeOzon}
        />}
    </div>
  )
}