import { useState } from "react";

type AdditionalComponentProps = {
  action: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  text: string,
  img: string,
  hoverImg: string
}

export default function AdditionalComponent({ action, text, img, hoverImg }: AdditionalComponentProps) {

  const [isHover, setIsHover] = useState(false)

  return (
    <div onMouseEnter={() => { setIsHover(true) }} onMouseLeave={() => { setIsHover(false) }} onClick={action} className="details__content">
      <img alt="" src={isHover ? hoverImg : img} />
      <span className="details__text">{text}</span>
    </div>
  )
}