import { NameSpace } from '../../consts';
import { State } from '../../types/state';

export const getStateAuthorizationStatus = (state: Pick<State, typeof NameSpace.User>) => 
  state[NameSpace.User].authorizationStatus; 
export const getUserInfo = (state: Pick<State, typeof NameSpace.User>) => 
  state[NameSpace.User].userInfo
export const getUserLoading = (state: Pick<State, typeof NameSpace.User>) => 
  state[NameSpace.User].isUserLoading
  export const getUserRoles = (state: Pick<State, typeof NameSpace.User>) => 
  state[NameSpace.User].userRoles
export const getUserRolesLoading = (state: Pick<State, typeof NameSpace.User>) => 
  state[NameSpace.User].isUserRolesLoading
export const getLoginError = (state: Pick<State, typeof NameSpace.User>) => 
  state[NameSpace.User].error
export const getRegistrationStatus = (state: Pick<State, typeof NameSpace.User>) => 
  state[NameSpace.User].isRegistration
export const getIsFirstLogin = (state: Pick<State, typeof NameSpace.User>) => 
  state[NameSpace.User].isFirstLogin

