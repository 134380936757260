import { CSSProperties } from 'react';
import styles from './button-container.module.css'

type ButtonContainerProps = {
  children: JSX.Element
  onClickAction: ()=>void;
  style?:CSSProperties;
}

export default function ButtonContainer({children, onClickAction, style}:ButtonContainerProps){
  return (
    <div onClick={onClickAction} className={styles.container} style={style}>
      {children}
    </div>
  )
}