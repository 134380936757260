import { useEffect, useRef, useState } from 'react'
import './filter-blocks.css'
import SearchFilter from './search-filter'
import { SortData } from '../../types/main'
import { removeElementListByValue } from '../../utils'

type FilterBlockProps = {
  data: SortData
  allNames: string[]
  columnName: string
  changeFilterContent: (data: SortData | null) => void;
  isDisplayOptions?:boolean
}

export default function FilterBlock({ changeFilterContent, columnName, data, allNames, isDisplayOptions}: FilterBlockProps) {
  const container = useRef<HTMLDivElement>(null)

  function checkDateFormat(dateString: string): boolean {
    const pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    const pattern2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\Z$/;
    const match = pattern.test(dateString);
    const match2 = pattern2.test(dateString)
    if (match){
      return match;
    }
    return match2;
  }

  function findMinMaxDates(dateStrings: string[]) {
    const dates = dateStrings.map(dateString => new Date(dateString));
    const minDate = new Date(Math.min(...dates.map(date => date.getTime())));
    const maxDate = new Date(Math.max(...dates.map(date => date.getTime())));
    return {min: minDate.toISOString().slice(0, -8), max: maxDate.toISOString().slice(0, -8)};
  }
  const [sortData, setSortData] = useState<SortData>(!data.filters && !data.sort ? {
    take: data.take,
    skip: 0,
    sort: {
      field: {
        name: columnName
      },
      direction: null
    },
    filters: [{
      field: {
        name: columnName
      },
      filterType: checkDateFormat(allNames[0]) ? 'Range' : 'Equals',
      values: checkDateFormat(allNames[0]) ? [findMinMaxDates(allNames).min, findMinMaxDates(allNames).max] : [...new Set(allNames)]
    }]
  } : data)

  const checkCheckedField = (name: string) => {
    if (sortData.filters){
      for (let i in sortData.filters[0].values){
        if (sortData.filters){
          if (sortData.filters[0].values[i] === name){
            return true
          }
        }
      }
    }
    return false
  }

  useEffect(() => {
    const block = container.current;
    if (block) {
      const rects = block.getBoundingClientRect()
      const { innerWidth } = window;
      if (
        rects.right + rects.width >= innerWidth
      ) {
        block.style.left = 'auto'
        block.style.right = '-30px'
      } else {
        block.style.left = '100%'
        block.style.right = '20px'
      }
    }
  }, [])
  const [tableNames, setTableNames] = useState<{ name: string, isActive: boolean }[]>(allNames.map((el) => ({ name: el, isActive: checkCheckedField(el) })))

  const changeNames = (name: string, isAdd: boolean) => {
    let newTableNames = tableNames.fullClone()
    if (sortData.filters){
      let newNames = sortData.filters[0].values
      isAdd ? newNames?.push(name) : newNames = removeElementListByValue<string>(newNames, name)
      setSortData({ ...sortData, filters: [{ filterType: 'Equals', values: newNames, field: {name: columnName} }] })
    } 
    newTableNames.forEach((el) => {
      if (el.name === name) {
        el.isActive = isAdd
      }
    })
    setTableNames(newTableNames)
  }

  const changeDate = (value: string, isMin: boolean) => {
    if (sortData.filters){
      let newNames = sortData.filters[0].values
      if (newNames){
        isMin ? newNames[0] = value : newNames[1] = value
        setSortData({ ...sortData, filters: [{ filterType: 'Range', values: newNames, field: {name: columnName} }] })
      }
    }
  }

  const changeAllNames = (isAdd: boolean) => {
    let newTableNames = tableNames.fullClone()
    setSortData({...sortData, filters: isAdd ? [{filterType: 'Equals', values: [...new Set(allNames)], field: {name: columnName} }] : [{filterType: 'Equals', values: [], field: {name: columnName}}]})
    newTableNames.forEach((el) => {
      el.isActive = isAdd
    })
    setTableNames(newTableNames)
  }

  const handleSort = () => {
    if (!sortData.filters){
      return
    }
    if (sortData.filters[0].values.length === 0 && sortData.sort?.direction === null){
      return 
    }
    let newSortData = {...sortData}
    if (sortData.filters[0].values.length === 0) {
      newSortData.filters = undefined
    }
    else if (sortData.sort?.direction === null) {
      newSortData.sort = undefined
    }
    newSortData.skip = 0
    changeFilterContent(newSortData)

  }
  if (!sortData.filters){
    return <></>
  }
  return (
    <div ref={container} className="filter__container">
      <div className="filter__sort">
        <h3 className='filter__title'>Сортировка</h3>
        <div className='filter__buttons'>
          <div className='filter__sortBlock'  onClick={() => { setSortData({ ...sortData, sort: { direction: 'Ascending', field: {name: columnName} } }) }}>
            <span className='filter__sortText pointer'>От А до Я</span>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.5 12.1875C10.0888 12.1875 12.1875 10.0888 12.1875 7.5C12.1875 4.91116 10.0888 2.8125 7.5 2.8125C4.91116 2.8125 2.8125 4.91116 2.8125 7.5C2.8125 10.0888 4.91116 12.1875 7.5 12.1875ZM7.5 13.125C10.6066 13.125 13.125 10.6066 13.125 7.5C13.125 4.3934 10.6066 1.875 7.5 1.875C4.3934 1.875 1.875 4.3934 1.875 7.5C1.875 10.6066 4.3934 13.125 7.5 13.125Z" fill="#004AFF" />
              {sortData.sort?.direction === 'Ascending' &&
                <path d="M7.5 10.7812C9.31218 10.7812 10.7812 9.31218 10.7812 7.5C10.7812 5.68782 9.31218 4.21875 7.5 4.21875C5.68782 4.21875 4.21875 5.68782 4.21875 7.5C4.21875 9.31218 5.68782 10.7812 7.5 10.7812Z" fill="#004AFF"/>
              }
            </svg>
          </div>
          <div className='filter__sortBlock pointer'  onClick={() => { setSortData({ ...sortData, sort: { direction: 'Descending', field: {name: columnName} } }) }}>
            <span className='filter__sortText'>От Я до А</span>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.5 12.1875C10.0888 12.1875 12.1875 10.0888 12.1875 7.5C12.1875 4.91116 10.0888 2.8125 7.5 2.8125C4.91116 2.8125 2.8125 4.91116 2.8125 7.5C2.8125 10.0888 4.91116 12.1875 7.5 12.1875ZM7.5 13.125C10.6066 13.125 13.125 10.6066 13.125 7.5C13.125 4.3934 10.6066 1.875 7.5 1.875C4.3934 1.875 1.875 4.3934 1.875 7.5C1.875 10.6066 4.3934 13.125 7.5 13.125Z" fill="#004AFF" />
              {sortData.sort?.direction === 'Descending' &&
                <path d="M7.5 10.7812C9.31218 10.7812 10.7812 9.31218 10.7812 7.5C10.7812 5.68782 9.31218 4.21875 7.5 4.21875C5.68782 4.21875 4.21875 5.68782 4.21875 7.5C4.21875 9.31218 5.68782 10.7812 7.5 10.7812Z" fill="#004AFF"/>
              }
            </svg>
          </div>
          <div className='filter__sortBlock pointer'  onClick={() => { setSortData({ ...sortData, sort: { direction: null, field: {name: columnName} } }) }}>
            <span className='filter__sortText'>Нет сортировки</span>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.5 12.1875C10.0888 12.1875 12.1875 10.0888 12.1875 7.5C12.1875 4.91116 10.0888 2.8125 7.5 2.8125C4.91116 2.8125 2.8125 4.91116 2.8125 7.5C2.8125 10.0888 4.91116 12.1875 7.5 12.1875ZM7.5 13.125C10.6066 13.125 13.125 10.6066 13.125 7.5C13.125 4.3934 10.6066 1.875 7.5 1.875C4.3934 1.875 1.875 4.3934 1.875 7.5C1.875 10.6066 4.3934 13.125 7.5 13.125Z" fill="#004AFF" />
              {sortData.sort?.direction === null &&
                <path d="M7.5 10.7812C9.31218 10.7812 10.7812 9.31218 10.7812 7.5C10.7812 5.68782 9.31218 4.21875 7.5 4.21875C5.68782 4.21875 4.21875 5.68782 4.21875 7.5C4.21875 9.31218 5.68782 10.7812 7.5 10.7812Z" fill="#004AFF"/>
              }
            </svg>
          </div>
        </div>
      </div>
      {(sortData.filters[0].filterType !== 'Range' && isDisplayOptions!==true) || <div className='fliter__line'></div>}
      {sortData.filters[0].filterType === 'Range' &&
      <div>
        <h3>Фильтрация</h3>
        <div className="filter__border">
          <div className="filter__border-block">
            <span>от</span>
            <input value={sortData.filters[0].values[0]} onChange={(e)=>{changeDate(e.target.value, true)}} type={'datetime-local'} className="filter__border-input"/>
          </div>
          <div className="filter__border-block">
            <span>до</span>
            <input value={sortData.filters[0].values[1]} onChange={(e)=>{changeDate(e.target.value, false)}} type={'datetime-local'} className="filter__border-input"/>
          </div>
        </div>
      </div>}
      {sortData.filters[0].filterType !== 'Range' && isDisplayOptions!==false && <div className='filter__search'>
        <h3 className='filter__title'>Фильтры</h3>
        <SearchFilter changeAllNames={changeAllNames} changeNames={changeNames} content={tableNames} />
      </div>}
      <div className='filter__applybtns'>
        <span onClick={()=>{changeFilterContent(null)}} className='fliter__toDefault'>Сбросить фильтр</span>
        <button onClick={handleSort} className='filter__apply-btn'>Применить фильтры</button>
      </div>
    </div>
  )
}