import { Link } from 'react-router-dom';
import './Sidebar.css';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getUserRoles } from '../../store/user-process/selectors';
import { Entities, SidebarLinks } from '../../types/enums/organization-type';
import Spinner from '../spinner/spinner';
import { getStateOrganizations } from '../../store/organization-process/selectors';
import { getOrganizationsAPI } from '../../store/api-actions/organization-api-actions';

function Sidebar() {
  let location = useLocation();
  const dispatch = useAppDispatch()
  const [isHide, setIsHide] = useState(true)
  const user = useAppSelector(getUserRoles)
  const organizations = useAppSelector(getStateOrganizations)
  const [links, setLinks] = useState<{ name: string, value: string }[]>([])

  const getLinks = () => {
    if (user) {
      if (user.entities[0] === 64) {
        setLinks(SidebarLinks)
        return
      }
      let newLinks: { name: string, value: string }[] = []
      for (let i in user.entities) {
        for (let link in SidebarLinks) {
          if (Entities[user.entities[i]] === SidebarLinks[link].value) {
            newLinks.push(SidebarLinks[link])
          } else if (Entities[user.entities[i]] === 'Продукты' && SidebarLinks[link].value === 'Товароучет'){
            newLinks.push(SidebarLinks[link])
          }
        }
      }
      setLinks(newLinks)
    }
  }

  useEffect(() => {
    if (user !== null) {
      getLinks()
    }
  }, [user])

  useEffect(() => {
    dispatch(getOrganizationsAPI())
  }, [])

  return (
    organizations.length === 0 ? <></> : !user ? <Spinner /> :
      <div onMouseOver={() => { setIsHide(false) }} onMouseLeave={() => { setIsHide(true) }} className={`sidebar ${isHide ? 'hide' : 'full'}`}>
        <Link to='/'>
          <h1 className='sidebar__title'>
            {isHide && <img className='sidebar__logo' alt='proStock' src='/img/proStock.svg'/>}
            {isHide ? '' : 'ProStock'}
          </h1></Link>
        <ul className={`sidebar__ul ${isHide ? 'hide__ul' : ''}`}>
          {links.map((el) => (
            <Link key={el.name} to={`/${el.name}`}><li className={`${location.pathname === `/${el.name}` ? 'activeLi' : ''} sidebar__li ${isHide ? 'hide__li' : 'full__li'}`}>
              <div className={`sidebar__point ${location.pathname === `/${el.name}` ? 'active' : ''}`}>
                <img className='sidebar__icon' alt='Продукты' src={`/img/sidebar/${el.name}-icon.svg`} />
                {isHide || <span className='sidebar___pointText'>{el.value}</span>}
              </div>
            </li></Link>
          ))}
        </ul>
      </div>
  );
}

export default Sidebar;
