import './error-block.css'

type ErrorBlockProps = {
  text: string
}
export default function ErrorBlock({text}:ErrorBlockProps){
  return (
    <div className="errorBlock__container centerFlex">
      <div className='errorBlock__content centerFlex'>
        <div className='errorBlock__circle'></div>
        <span className='errorBlock__text'>{text}</span>
      </div>
    </div>
  )
}