import { useEffect, useRef, useState } from "react";

type CodeBlockProps = {
  changeCode: (number: number) => void
  isError: boolean
}

export default function CodeBlock({changeCode, isError}:CodeBlockProps){
  const [numbers, setNumbers] = useState(['','','','','',''])

  const [activeNumber, setActiveNumber] = useState(0)

  const getIntCode = (code: string[]) => {
    let result = ''
    for (let num of code){
      result += num
    }
    return parseInt(result)
  }

  const changeNumber = (number: string, index: number) => {
    if (number.length > 1){
      let newNumbers = numbers.slice()
      for (let index = 0; index < number.split('').length; index++){
        newNumbers[index] = number[index]
      }
      setNumbers(newNumbers)
      if (number.split('').length === 6){
        changeCode(getIntCode(newNumbers))
      }
      return
    }
    let newNumber = numbers.slice()
    newNumber[index] = number
    if (index !== 5){
      if (number.length !== 0){
        setActiveNumber(index + 1)
      }
    }
    else {
      changeCode(getIntCode(newNumber))
      setActiveNumber(-1)
    }
    setNumbers(newNumber)
  }

  return (
    <div className="flex">
    {numbers.map((num, index)=>(
      <Input 
        isError={isError}
        key={index}
        activeIndex={activeNumber}
        changeActive={(index:number)=>setActiveNumber(index)} 
        value={num} 
        index={index}
        changeNumber={(value: string, index: number)=>{changeNumber(value, index)}}/>
    ))}
    </div>
  )
}

type InputProps = {
  index: number,
  activeIndex: number
  changeActive: (index: number)=>void
  changeNumber: (value: string, index: number)=>void
  value: string
  isError: boolean
}

function Input({index, activeIndex, value, changeActive, changeNumber, isError}:InputProps){
  const input = useRef<HTMLInputElement>(null)

  useEffect(()=>{
    if (input.current) {
      index === activeIndex ? input.current.focus() : input.current.blur()
    }
  }, [activeIndex, index]);

  return <input 
  ref={input}
  type="number"
  className={`code__input centerFlex ${isError ? 'code__input_error' : 'code__input_notError'}`}
  tabIndex={index}
  onClick={()=>{changeActive(index)}} 
  value={value} 
  autoFocus={index === activeIndex}
  onChange={(e)=>{changeNumber(e.target.value, index)}}/>
}