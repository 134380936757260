import moment from "moment";

export const getCurrentDate = () => {
  const currentDate = new Date();
  return currentDate.toISOString();
}

export const getCurrentDateUTCNumber = () => {
  const currentDate = new Date().toISOString();
  return new Date(currentDate).getTime();
}

export const getCurrentDateForInput = () => {
  const currentDate = new Date();
  return moment(currentDate).format('YYYY-MM-DD HH:mm');
}

export const getDateForInput = (date:string) => {
  return moment(date).utcOffset('+00:00').format('YYYY-MM-DD HH:mm');
}

export const getCurrentDateForInputWithoutTime = () => {
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().slice(0, 10);
  return `${formattedDate}`;
}

export const getDateForInputWithoutTime = (date:string) => {
  return moment(date).utcOffset('+00:00').format('YYYY-MM-DD');
}

export const getDataValue = (data: string) => {
  return moment(data).utcOffset('+00:00').format('DD.MM.YYYY HH.mm')
}

export const getDataValuePaymentHistory = (data: string) => {
  return moment(data).utcOffset('+00:00').format('DD/MM/YYYY HH:mm')
}

export const getDataValueInput = (dateTimeString: string) => {
  var parts = dateTimeString.split(' ');
  var dateParts = parts[0].split('.');
  var timeParts = parts[1].split('.');
  return dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0] + ' ' + timeParts[0] + ':' + timeParts[1]
}

export const getDataValueInputAccounting = (date: string) => {
  const parsedDate = new Date(date);
  return parsedDate.toISOString().slice(0, 19).replace('T', ' ');
}

export const getDataNumberUTCFromDouble = (value: string | null) => {
  if (value) {
    const date = new Date()
    date.setTime((parseFloat(value) - 25569) * 24 * 3600 * 1000)
    let isoDate = new Date(date).toISOString()
    return new Date(isoDate).getTime()
  }
  return 0
}

export function prettyDate(inputDate: string) {
  const date = new Date(inputDate);
  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric' };
  const formattedDate = date.toLocaleDateString('ru-RU', options);
  return formattedDate;
}


export function getKeyByValue(object: any, value: any) {
  return Object.keys(object).find(key => object[key] === value);
}

export const getHeaderContent = <T extends object>(key: keyof T, filterContent: T[]) => {
  let result = []
  for (let i in filterContent) {
    result.push(filterContent[i][key])
  }
  return result;
}

export const getText = (value: any) => {
  if (typeof value === 'boolean')
    return value ? 'Да' : 'Нет'
  if (typeof value === 'number'){
    return formatNumber(value)
  }
  if (Array.isArray(value)){
    return value.join(', ')
  }
  return value
}

export const removeElementListByValue = <T>(list: T[], value: T) => {
  let newList = list.slice()
  var index = list.indexOf(value);
  if (index > -1) {
    newList.splice(index, 1);
  }
  return newList
}

export const enumValuesToList = (value: any) => {
  const list = Object.keys(value)
  return list.slice(list.length / 2)
}


export function formatNumber(number: number | string | undefined) {
  if (!number){
    return 0
  }
  if (typeof number === 'number'){
    return number;
  }
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function formatString(number: string) {
  return parseInt(number.replace(/\s/g, ""));
}