import './add-table.css'
import CreateButton from '../../buttons/create-button/create-button'
import { AddMarketProducts, AddProducts } from '../../../types/shipment-model'
import { formatNumber } from '../../../utils'

type AddTableProps = {
  onAddProducts: () => void
  content: AddProducts[] | AddMarketProducts[]
}

export default function AddTable({ onAddProducts, content }: AddTableProps) {

  function isWarehouseArray(data: any): data is AddProducts[]{
    return typeof data[0].quantity === 'number'
  }

  if (typeof content === 'string' || typeof content === 'boolean' || typeof content === 'number') {
    return <></>
  }

  return (
    <div>
      {content.length === 0 ?
        <div className='addTable__create flex'>
          <CreateButton icon='img/sidebar/marketshipment-icon.svg' text='Добавить продукт' createAction={onAddProducts} />
        </div> :
        <div className='addTable__container'>
          <table className='addTable__products'>
            <tbody>
              <tr className='addTable__productsTr'>
                <td className='addTable__productsTd'>Товары</td>
                <td className='addTable__productsTd'>{content.length} шт.</td>
              </tr>
              <tr className='addTable__productsTr'>
                <td className='addTable__productsTd'>Объем поставки</td>
                <td className='addTable__productsTd'>{isWarehouseArray(content) ? formatNumber(content.reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0)) : formatNumber(content.reduce((accumulator, currentValue) => accumulator + currentValue.totalQuantity, 0))} ед.</td>
              </tr>
              {isWarehouseArray(content) && <tr className='addTable__productsTr'>
                <td className='addTable__productsTd'>Общая стоимость</td>
                <td className='addTable__productsTd'>{formatNumber(content.reduce((accumulator, currentValue) => accumulator + currentValue.netCost * currentValue.quantity, 0))} руб.</td>
              </tr>}
            </tbody>
          </table>
          <svg onClick={onAddProducts} className='addTable__edit pointer' width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.30016 4.45322C1.30016 2.5147 2.87164 0.943223 4.81016 0.943223H15.0102C16.9487 0.943223 18.5202 2.5147 18.5202 4.45322V14.6532C18.5202 16.5917 16.9487 18.1632 15.0102 18.1632H4.81016C2.87164 18.1632 1.30016 16.5917 1.30016 14.6532V4.45322Z" fill="#0042E4" stroke="#0042E4" strokeWidth="0.78" />
            <path d="M5.16602 13.8333H13.8327M5.16602 13.8333V11.6666L9.49935 7.33332M5.16602 13.8333H7.33268L11.666 9.49997M9.49935 7.33332L11.0532 5.77946L11.0541 5.77854C11.268 5.56464 11.3751 5.45751 11.4986 5.41738C11.6075 5.38203 11.7246 5.38203 11.8334 5.41738C11.9568 5.45748 12.0639 5.56449 12.2774 5.77808L13.2198 6.72047C13.4343 6.93498 13.5416 7.04229 13.5818 7.16597C13.6172 7.27476 13.6172 7.39194 13.5818 7.50073C13.5417 7.62432 13.4346 7.73146 13.2203 7.94566L13.2198 7.94612L11.666 9.49997M9.49935 7.33332L11.666 9.49997" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      }
    </div>
  )
}