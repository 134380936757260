import { useEffect, useRef, useState } from 'react'
import './errors.css'
import axios, { PagesURl } from '../../services/api'
import { Error } from '../../types/notifications'
import Spinner from '../../components/spinner/spinner'
import useOutsideClick from '../../hooks/useOutsideClick'
import { Entities } from '../../types/enums/organization-type'
import InfoContainer from '../../components/info-container/info-container'
import { stageList } from '../../consts'
import { SortData } from '../../types/main'
import { getTotalStages } from '../../utils/filter-table'
import { toast } from 'react-toastify'
import { removeElementListByValue } from '../../utils'

export default function Errors() {

  const [errors, setErrors] = useState<Error[]>()

  const [activeError, setActiveError] = useState<Error>()

  const [countRows, setCountRows] = useState<number>(stageList[0])
  const [activeStage, setActiveStage] = useState<number | null>(null)
  const [errorsCount, setErrorsCount] = useState(0)
  const [activeFilter, setActiveFilter] = useState<SortData>({ take: countRows, skip: 0 })
  const [isNew, setIsNew] = useState(true)

  const errorRef = useRef<HTMLDivElement>(null)
 
  const handleGetErrors = async (data: SortData = { take: countRows, skip: 0 }, isNew: boolean = true, actStage: number | null = activeStage) => {
    try {
      const response = await axios.post(PagesURl.ExternalData + `/find/errors?isNew=${isNew}`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const products = response.data.data;
      setErrors(products)
      setErrorsCount(response.data.totalCount)
      setActiveFilter(data)
      if (actStage === null) {
        setActiveStage(response.data.totalCount > data.take ? 1 : null)
      }
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        toast(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }
  const handleReadAllErrors = async () => {
    try {
      await axios.get(PagesURl.ExternalData + `/error/read/all`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      handleGetErrors({ take: countRows, skip: 0 }, isNew)
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        toast(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }

  const handleGetError = async (id: string) => {
    const { data } = await axios.get<Error>(PagesURl.ExternalData + `/get/error?id=${id}`)
    setActiveError(data)
    handleGetErrors({ take: countRows, skip: 0 }, isNew)
  }

  const openLog = (error: Error) => {
    handleGetError(error.id)
  }

  const changeIsNew = (displayAll: boolean) => {
    setIsNew(displayAll)
    handleGetErrors({ take: countRows, skip: 0 }, displayAll, null)
  }

  const changeSelectedValue = (newValue: number) => {
    setCountRows(newValue)
    handleGetErrors({ take: newValue, skip: 0 }, isNew)
  }

  const changeActiveStage = (toStage: number) => {
    let newSortData: SortData = JSON.parse(JSON.stringify(activeFilter))
    newSortData.skip = countRows * toStage
    handleGetErrors(newSortData, isNew)
    setActiveStage(toStage + 1)
  }

  useEffect(() => {
    handleGetErrors()
  }, [])

  useOutsideClick(errorRef, ()=>{setActiveError(undefined)})

  if (!errors) {
    return <Spinner />
  }

  return (
    <>
    <InfoContainer
        title='Уведомления'
        pointCount={errorsCount}
        changeContainer={{changeState: changeIsNew, firstText: 'Непрочитанные', secondText: 'Все'}}
        onChangeSelectedValue={changeSelectedValue}
        activeStage={activeStage}
        getTotalStages={() => { return getTotalStages(errorsCount, countRows) }}
        countRows={countRows}
        changeActiveStage={changeActiveStage}
        onReadAll={handleReadAllErrors}>
        <>
        <div className='notifications__content'>
          {errors.map((error) => (
            <div key={error.id} onClick={() => { openLog(error) }} className='notifications__notification centerFlex pointer'>
            <div className='notifications__text centerFlex'>
              {error.isNew && <div className='notifications__new'></div>}
              <div className='notifications__user'>
                <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="20.5" cy="20.5" r="19.5" fill="#D9E5FF" stroke="#2051C9" strokeWidth="2" />
                  <path d="M18.3613 14.2738V14.0838C18.3613 13.4038 17.6513 12.9738 17.0413 13.2638C16.8513 13.3538 16.6813 13.4638 16.5013 13.5638L17.9613 15.0238C18.2013 14.8438 18.3613 14.5738 18.3613 14.2738ZM28.3613 20.5338C28.3613 18.3238 27.4513 16.3338 26.0013 14.8938L27.5113 13.3838C27.8213 13.0738 27.6013 12.5338 27.1513 12.5338H22.3613V17.3238C22.3613 17.7738 22.9013 17.9938 23.2113 17.6738L24.6013 16.2838C25.6813 17.3838 26.3613 18.8738 26.3613 20.5338C26.3613 21.3838 26.1813 22.1938 25.8613 22.9238L27.3413 24.4038C27.9813 23.2538 28.3613 21.9438 28.3613 20.5338ZM11.9313 13.2338C11.5413 13.6238 11.5413 14.2538 11.9313 14.6438L13.5813 16.2938C12.8113 17.5338 12.3613 18.9738 12.3613 20.5338C12.3613 22.7438 13.2713 24.7338 14.7213 26.1738L13.2113 27.6838C12.9013 27.9938 13.1213 28.5338 13.5713 28.5338H17.8613C18.1413 28.5338 18.3613 28.3138 18.3613 28.0338V23.7438C18.3613 23.2938 17.8213 23.0738 17.5113 23.3938L16.1213 24.7838C15.0413 23.6838 14.3613 22.1938 14.3613 20.5338C14.3613 19.5338 14.6213 18.6038 15.0513 17.7738L23.1213 25.8438C23.1113 25.8638 23.1113 25.8638 23.1113 25.8838C22.6813 26.0038 22.3613 26.3638 22.3613 26.7938V26.9738C22.3613 27.6538 23.0713 28.0838 23.6813 27.7938C23.9913 27.6538 24.2913 27.4838 24.5813 27.3038L26.4513 29.1738C26.8413 29.5638 27.4713 29.5638 27.8613 29.1738C28.2513 28.7838 28.2513 28.1538 27.8613 27.7638L13.3413 13.2338C12.9513 12.8438 12.3213 12.8438 11.9313 13.2338Z" fill="#2051C9" />
                </svg>
              </div>
              <div className='notifications__textContent'>
                <p className='notifications__textName'>{`Ошибка синхронизации`}</p>
                <p className='notifications__textRole'>{Entities[error.entity]}</p>
              </div>
            </div>
            <div className='notifications__buttons centerFlex'>
              <span className='error__number'>{error.count}</span>
              <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="28" height="28" rx="14" fill="#2051C9" />
                <path d="M20.4424 10.5769H18.1323C17.7623 9.9357 17.2526 9.38488 16.636 8.9656L17.9761 7.62555L16.8169 6.46637L15.0329 8.25036C14.6547 8.15993 14.2683 8.1106 13.8655 8.1106C13.4627 8.1106 13.0763 8.15993 12.7063 8.25036L10.9141 6.46637L9.75492 7.62555L11.0867 8.9656C10.4784 9.38488 9.96867 9.9357 9.59872 10.5769H7.28857V12.2212H9.0068C8.96569 12.4925 8.93281 12.7638 8.93281 13.0433V13.8654H7.28857V15.5096H8.93281V16.3318C8.93281 16.6113 8.96569 16.8826 9.0068 17.1539H7.28857V18.7981H9.59872C10.4537 20.2697 12.0404 21.2644 13.8655 21.2644C15.6906 21.2644 17.2773 20.2697 18.1323 18.7981H20.4424V17.1539H18.7242C18.7653 16.8826 18.7982 16.6113 18.7982 16.3318V15.5096H20.4424V13.8654H18.7982V13.0433C18.7982 12.7638 18.7653 12.4925 18.7242 12.2212H20.4424V10.5769ZM17.154 16.3318C17.154 16.5126 17.1293 16.7181 17.0964 16.9072L17.0142 17.4416L16.71 17.976C16.1181 18.9954 15.0329 19.6202 13.8655 19.6202C12.6981 19.6202 11.6129 18.9872 11.021 17.976L10.7168 17.4498L10.6346 16.9155C10.6017 16.7264 10.577 16.5208 10.577 16.3318V13.0433C10.577 12.8542 10.6017 12.6487 10.6346 12.4678L10.7168 11.9334L11.021 11.3991C11.2676 10.9716 11.6129 10.6016 12.0157 10.3221L12.4843 10.0015L13.0927 9.85348C13.3476 9.78772 13.6106 9.75483 13.8655 9.75483C14.1286 9.75483 14.3834 9.78772 14.6465 9.85348L15.2055 9.98502L15.707 10.3303C16.1181 10.6098 16.4552 10.9716 16.7018 11.4073L17.0142 11.9417L17.0964 12.476C17.1293 12.6569 17.154 12.8624 17.154 13.0433V16.3318ZM12.2213 15.5096H15.5097V17.1539H12.2213V15.5096ZM12.2213 12.2212H15.5097V13.8654H12.2213V12.2212Z" fill="white" />
              </svg>
            </div>
          </div>
          ))}
        </div>
        {activeError &&
        <div ref={errorRef} className='errors__logContainer'>
          <h1 className='logContainer__title'>Error Log</h1>
          <div className='logContainer__list'>
            {activeError.messages.map((el, index) => (
              <div key={el} className='logContainer__error flex'>
                <span className='logContainer__number'>{index + 1}</span>
                <p className='logContainer__text'>{el}</p>
              </div>
            ))}
          </div>
        </div>}
        </>
      </InfoContainer>
    </>
  )
}