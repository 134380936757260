import { useEffect, useState } from 'react'
import './auto-links.css'
import { Links } from '../../types/enums/links-type'
import { useAppSelector } from '../../hooks'
import { getStateActiveOrganization } from '../../store/organization-process/selectors'
import { Link } from 'react-router-dom'

export default function AutoLinks(){
  const [links, setLinks] = useState<string[] | null>(null)
  const activeOrganization = useAppSelector(getStateActiveOrganization)

  useEffect(()=>{
    if (window.location.pathname === '/'){
      setLinks(['Организации'])
      return 
    }
    if (window.location.pathname === '/cabinet'){
      return 
    }
    const result = []
    let paths = window.location.pathname.slice(1).split('/')
    if (activeOrganization && window.location.pathname === `/${activeOrganization.id}`){
      result.push('Организации')
      result.push(activeOrganization.name)
      setLinks(result)
      return
    } else if (activeOrganization  && (paths.includes('warehouse') || paths.includes('products'))){
      result.push(activeOrganization.name)
    }
    for (let i in paths){
      if (paths[i] !== '' && (paths[i] === 'warehouse' || paths[i] === 'products')){
        result.push(paths[i])
      }
    }
    setLinks(result)
  }, [activeOrganization, window.location.pathname])

  const getLinkHref = (link:string) => {
    if (Links.hasOwnProperty(link)){
      return link
    } else if (link === activeOrganization?.name.toString()) {
      return `./${activeOrganization.id.toString()}`
    } return './'
  }

  const getLinkName = (link:string) => {
    if (link === activeOrganization?.name.toString()) {
      return activeOrganization.name
    }
    else if (Links.hasOwnProperty(link)){
      return Links[link as keyof typeof Links]
    }  return link
  }

  if (!links){
    return <></>
  }

  return (
    <div className='autoLinks__container'>
      {links.map((link) => (link !== '' && <Link key={link} className='autoLinks__link' to={getLinkHref(link)}><div className='autoLinks__point'>{<span className='autoLinks__text'>{getLinkName(link)}</span>}</div></Link>))}
    </div>
  )
}