import './cabinet.css'
import 'react-toastify/dist/ReactToastify.css';
import CabinetInput from './cabinet-input';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Spinner from '../../components/spinner/spinner';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getUserInfo } from '../../store/user-process/selectors';
import { editUserInfoAction } from '../../store/api-actions/user-api-actions';
import axios, { PagesURl } from '../../services/api'
import { ParticipantRole } from '../../types/enums/organization-type';

export default function Cabinet() {
  const dispatch = useAppDispatch()

  const userInfo = useAppSelector(getUserInfo)

  const [isActiveNameInput, setIsActiveInput] = useState(false)
  const [nameValue, setNameValue] = useState<string | null>(null)

  const [isActiveInputs, setIsActiveInputs] = useState(false)

  const [roles, setRoles] = useState<{organizationName: string, role: ParticipantRole}[] | null>(null)

  const changeCabinetInput = (value: string, index: number) => {
    if (cabinetInputs) {
      let newInputs = cabinetInputs.slice()
      newInputs[index].inputValue = value
      setCabinetInputs(newInputs)
    }
  }

  const [cabinetInputs, setCabinetInputs] = useState<{
    text: string;
    inputValue: string;
    name: string;
  }[] | null>(null)

  const changeInputValue = () => {
    if (cabinetInputs) {
      let data: { [key: string]: string; } = {}
      cabinetInputs.forEach((input) => { data[input.name] = input.inputValue })
      dispatch(editUserInfoAction(data))
    }
  }

  const getLowUserName = (name: string) => {
    const parts = name.split(' ')
    if (parts[0] && parts[1]) {
      return parts[0][0] + parts[1][0]
    }
    return ''
  }

  const onEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && nameValue) {
      dispatch(editUserInfoAction({ 'name': nameValue }))
      setIsActiveInput(false)
    }
  }

  const handleGetRoles = async () => {
    const {data} = await axios.get<{organizationName: string,role: ParticipantRole}[]>(PagesURl.USER + '/get/roles')
    setRoles(data)
  }

  useEffect(() => {
    if (userInfo) {
      setNameValue(userInfo.name)
      setCabinetInputs([
        {
          text: 'Номер телефона:',
          inputValue: userInfo?.phone ? userInfo.phone : 'не указано',
          name: 'phone'
        },
        {
          text: 'Адрес электронной почты:',
          inputValue: userInfo?.mail ? userInfo.mail : 'не указано',
          name: 'mail'
        },
        {
          text: 'Город:',
          inputValue: userInfo?.city ? userInfo.city : 'не указано',
          name: 'city'
        },
      ])
      handleGetRoles()
    }
    
  }, [userInfo])

  if (!userInfo || !roles) {
    return <Spinner />
  }

  return (
    <>
      <Helmet>
        <title>Личный кабинет</title>
      </Helmet>
      <div className='cabinet__container'>
        <div className='cabinet__content'>
          <div className='cabinet__userNameBlock centerFlex'>
            <div className='cabinet__img centerFlex'>
              {userInfo.name && getLowUserName(userInfo.name)}
            </div>
            <div className='cabinetInput__container'>
              {isActiveNameInput ? <input onChange={(e) => { setNameValue(e.target.value) }} onKeyUp={(e) => { onEnter(e) }} className='cabinetInput__textInputActive' type={'text'} value={nameValue ? nameValue : ''} /> :
              <div className='centerFlex'>
                <p onClick={() => { setIsActiveInput(true) }} className='cabinet__textInput' style={{ textAlign: 'center' }}>{nameValue ? nameValue : 'ФИО'}</p>
                <img width={14} height={14} alt='' src='img/edit-pencil.svg'/>
              </div>}
            </div>
          </div>
          <div>
            <div className='cabinet__inputs'>
              <div className='cabinet__inputsFirstLine centerFlex'>
                <h2 className='cabinet__inputsTitle'>Учётные данные</h2>
                <svg className='pointer' onClick={() => { setIsActiveInputs(!isActiveInputs) }} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.44241 6.56262C1.44241 3.70585 3.75828 1.38998 6.61504 1.38998H21.6466C24.5034 1.38998 26.8193 3.70585 26.8193 6.56261V21.5942C26.8193 24.451 24.5034 26.7668 21.6466 26.7668H6.61505C3.75828 26.7668 1.44241 24.451 1.44241 21.5942V6.56262Z" fill="#0042E4" stroke="#0042E4" strokeWidth="1.14947" />
                  <path d="M7.13965 20.3859H19.9116M7.13965 20.3859V17.1929L13.5256 10.8069M7.13965 20.3859H10.3326L16.7186 13.9999M13.5256 10.8069L15.8155 8.51705L15.8169 8.5157C16.132 8.20049 16.2899 8.0426 16.4719 7.98347C16.6323 7.93137 16.805 7.93137 16.9653 7.98347C17.1472 8.04256 17.3049 8.20026 17.6197 8.51503L19.0084 9.90381C19.3245 10.2199 19.4827 10.3781 19.5419 10.5603C19.594 10.7207 19.594 10.8933 19.5419 11.0537C19.4828 11.2358 19.3249 11.3937 19.0092 11.7094L19.0084 11.71L16.7186 13.9999M13.5256 10.8069L16.7186 13.9999" stroke="white" strokeWidth="2.21053" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              {cabinetInputs ? <div className='cabinet__inputsContent flex'>
                {cabinetInputs.map((el, index) => (
                  <CabinetInput onChangeValue={(value: string) => { changeCabinetInput(value, index) }} changeIsActive={(toActive: boolean) => { setIsActiveInputs(toActive) }} isActive={isActiveInputs} changeInputsValue={changeInputValue} key={`${el}--${index}`} content={el} index={index} />
                ))}
              </div> : <Spinner />}
            </div>
            <div className='cabinet__inputs cabinet__organizationContainer'>
              <h2 className='cabinet__oragnizationTitle'>Организации</h2>
              {roles.map((role)=>(
                <div className='cabinet__oragnization centerFlex'>
                <span className='cabinet__oragnizationName'>{role.organizationName}</span>
                <div className='cabinet__organizationBlock'>
                  <p className='cabinet__organizationRights'>Права доступа:</p>
                  <p className='cabinet__oragnizationName'>{ParticipantRole[role.role]}</p>
                </div>
              </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}