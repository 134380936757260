import { ShipmentState } from "../../types/enums/shipment-type";

type MissingLineProps = {
  isReadOnly: boolean;
  changeValue: (e: string, shipmentNumber: string, newState: number ) => void;
  newState: number;
  shipmentNumber: string
}

export default function MissingLine({ isReadOnly, changeValue, newState, shipmentNumber}: MissingLineProps) {
  return (
    <tr>
      <td>{ShipmentState[newState]}</td>
      <td>{isReadOnly || <input onBlur={(e) => { changeValue(e.target.value, shipmentNumber, newState) }} type='datetime-local' />}</td>
    </tr>
  )
}