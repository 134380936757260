import { useRef, useState } from 'react'
import './dropdown-pagination.css'
import useOutsideClick from '../../hooks/useOutsideClick'

type DropdownPaginationProps = {
  selectedValue: number
  list: number[]
  changeSelectedValue: (value: number) => void
}

export default function DropdownPagination({ selectedValue, list, changeSelectedValue }: DropdownPaginationProps) {

  const blockRef = useRef<HTMLDivElement>(null)

  const [isActiveList, setIsActiveList] = useState(false)

  useOutsideClick(blockRef, ()=>(setIsActiveList(false)))

  return (
    <div ref={blockRef} className='dropdownPagination__container centerFlex'>
      <p className='dropdownPagination__title'>Показывать на странице: </p>
      <div onClick={()=>{setIsActiveList(!isActiveList)}} className='dropdownPagination__list centerFlex pointer'>
        <p className='dropdownPagination__selected'>{selectedValue}</p>
        <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_527_1870)">
            <path d="M0.0367903 2.11774L3.05091 5.77648C3.10076 5.83688 3.20616 5.83688 3.25595 5.77648L6.27026 2.11755C6.30614 2.07379 6.31677 2.0085 6.29742 1.95178C6.29342 1.93997 6.28896 1.93082 6.28522 1.92409C6.26219 1.8828 6.21798 1.85695 6.16945 1.85695L0.137278 1.85695C0.0890021 1.85695 0.0445302 1.88268 0.0217039 1.92409C0.017834 1.93095 0.0133737 1.94016 0.00963489 1.95114C-0.00984614 2.0085 0.000714282 2.07385 0.0367903 2.11774Z" fill="black" />
          </g>
          <defs>
            <clipPath id="clip0_527_1870">
              <rect width="6.10714" height="6.307" fill="white" transform="matrix(0 -1 1 0 0 6.89307)" />
            </clipPath>
          </defs>
        </svg>
        {isActiveList && 
        <div className='dropdownPagination__values'>
          {list.map((el)=>(<p className='dropdownPagination__value' onClick={()=>{changeSelectedValue(el)}} key={el}>{el}</p>))}
        </div>}
      </div>
    </div>
  )
}