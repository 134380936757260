import { useEffect, useState } from "react"
import { SellingTableNames } from "../../../types/enums/selling-type"
import { SortData } from "../../../types/main"
import { TableSelling } from "../../../types/selling-model"
import { getKeyByValue, getText } from "../../../utils"
import DetailsBlock from "../details-block/details-block"
import HeaderPoint from "../header-point"
import { getAllSelling } from "../../../utils/selling"
import { getActiveFilter } from "../../../utils/filter-table"

type SellingFilterTableProps = {
  content: TableSelling[],
  getPointInfo: (id: string) => void
  onDeleteSelling: (id: string) => void
  onChangeSelling: (id: string) => void
  onFilter: (data?: SortData) => void
  activeFilter: SortData
  isEdit: boolean
}

export default function SellingFilterTable({ content, getPointInfo, onDeleteSelling, onFilter, onChangeSelling, activeFilter, isEdit }: SellingFilterTableProps) {
  const tableHeader = SellingTableNames
  const [allFilterNames, setAllFilterNames] = useState<{ [K in keyof TableSelling]: (string | number | boolean)[] }>({
    id: [],
    organizationName: [],
    shipmentNumber: [],
    productBarcode: [],
    quantity: [],
    marketplaceType: [],
    soldAt: [],
  })

  const getAllNamesByField = (field: keyof TableSelling) => {
    if (allFilterNames) {
      return allFilterNames[field]
    }
    return []
  }

  const getAllNames = async () => {
    let newFilterNames: { [K in keyof TableSelling]: (string | number | boolean)[] } = JSON.parse(JSON.stringify(allFilterNames))
    if (newFilterNames.quantity.length !== 0) {
      return
    }
    const sellings = await getAllSelling()
    /*     for (let shipment of shipments){
          for (let key in shipment){
            const shipmentKey = key as keyof ParentTableShipment;
            newFilterNames[shipmentKey].push(shipment[shipmentKey])
          }
        } */
    Object.values(sellings).forEach(selling => {
      newFilterNames.id.push(selling.id);
      newFilterNames.productBarcode.push(selling.productBarcode);
      newFilterNames.quantity.push(selling.quantity);
      newFilterNames.shipmentNumber.push(selling.shipmentNumber);
      newFilterNames.marketplaceType.push(selling.marketplaceType);
      newFilterNames.organizationName.push(selling.organizationName);
      newFilterNames.soldAt.push(selling.soldAt);
    });
    setAllFilterNames(newFilterNames)
  }

  useEffect(() => {
    getAllNames()
  }, [])


  const changeSelling = (index: number) => {
    onChangeSelling(content[index].id)
  }

  const deleteSelling = (index: number) => {
    onDeleteSelling(content[index].id)
  }

  const changeFilterContent = async (data: SortData | null) => {
    if (!data) {
      onFilter()
    } else {
      onFilter(data)
    }
  }

  return (
    <>
      <div>
        <div className="scrolltable__container">
          <div className={`table__container ${content.length < 5 && content.length !== 0 ? 'padding__table' : ''}`}>
            <table className='table borderTable'>
              <thead className='thead'>
                <tr className="table__tr">
                  {Object.values(tableHeader).map((el, index) => (index !== 0 && 
                    <HeaderPoint 
                      blockedFilter={content.length === 0}
                      allNames={getAllNamesByField(getKeyByValue(tableHeader, el) as keyof TableSelling)} 
                      filterData={getActiveFilter(activeFilter, getKeyByValue(tableHeader, el) as string)} 
                      changeFilterContent={changeFilterContent} 
                      columnName={getKeyByValue(tableHeader, el) as string} name={el} key={`${el}--${index}`} />))}
                </tr>
              </thead>
              <tbody className='tbody'>
                {content.length !== 0 ? content.map((el, indexLen) => (
                  <tr key={`${el.id}--${indexLen}`} className='body__tr' onClick={() => {isEdit && getPointInfo(el.id) }}>
                    {Object.keys(el).map((key, index) => (
                      key !== 'id' &&
                      <td key={`${key}--${index}`} className={`table__td ${indexLen === content.length - 1 ? 'last__td' : ''}`}>
                        <div className='table__content'>
                          {getText(el[key as keyof TableSelling])}
                          {index + 1 === Object.keys(el).length && isEdit &&
                            <DetailsBlock displayEdit changeAction={changeSelling} deleteAction={deleteSelling} index={indexLen} />
                          }
                        </div>
                      </td>))}
                  </tr>
                )) : <td colSpan={Object.values(tableHeader).length} className='body__notData'>Нет данных</td>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}