import { useEffect, useState } from 'react'
import './filter-blocks.css'
import CheckboxPoint from './checkbox-point'

type SearchFilterProps = {
  content: { name: string, isActive: boolean }[]
  changeNames: (name: string, isAdd: boolean) => void
  changeAllNames: (isAdd: boolean) => void
}

export default function SearchFilter({ content, changeNames, changeAllNames}: SearchFilterProps) {
  const [value, setValue] = useState('')
  const [filteredContent, setFilteredContent] = useState<{ name: string, isActive: boolean }[]>(content)
  function removeDuplicateObjects(arr: any[], compareFn: (a: any, b: any) => boolean): any[] {
    return arr.filter((item, index, self) =>
      index === self.findIndex((t) => compareFn(t, item))
    );
  }
  const getFilteredContent = (e: string) => {
    setValue(e)
    if (e === '') {
      setFilteredContent(content)
      return
    }
    let newFilteredContent: { name: string, isActive: boolean }[] = []
    for (let point in content) {
      if (content[point].name.toLocaleLowerCase().includes(e.toLocaleLowerCase())) {
        newFilteredContent.push(content[point])
      }
    }
    setFilteredContent(newFilteredContent)
  }
  const getBoolAll = () => {
    const content = [...new Set(filteredContent)]
    let count = 0;
    content.map((el) => (el.isActive && count++))
    if (count === content.length) {
      return true
    }
    return false
  }

  useEffect(()=>{
    getFilteredContent(value)
  }, [content])

  return (
    <div className='searchFilter__container'>
      {content.length > 0 && <div className='searchFilter__inputBlock'>
        <img className='searchFilter__searchIcon' alt='' src='img/search-icon.svg' />
        <input className='searchFilter__input' onChange={(e) => { getFilteredContent(e.target.value) }} value={value} type='text' placeholder='Поиск...' />
      </div>}
      {content.length > 0 && <div style={{paddingLeft: '14px'}}>
        <CheckboxPoint changeAllNames={changeAllNames} text={'Выбрать все'} isActive={getBoolAll()} />
      </div>}
      {filteredContent.length > 0 &&
      <div className='variants'>
        <div className='variants__content'>
          {removeDuplicateObjects(filteredContent, (a, b) => 
            JSON.stringify(a) === JSON.stringify(b)).map((el, index) => 
            (<CheckboxPoint 
              changeNames={changeNames} 
              key={`${el}-${index}`} 
              text={typeof el.name === 'boolean' ? el ? 'Да' : 'Нет' : el.name} 
              isActive={el.isActive} 
            />))}
        </div>
      </div>}
    </div>
  )
}