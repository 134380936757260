import { useState } from "react";
import { Metric } from "../../../types/metrics-model";
import '../filter-table.css'
import Spinner from "../../spinner/spinner";
import { SortData } from "../../../types/main";
import axios, { PagesURl } from '../../../services/api';

type MetricsFilterTableProps = {
  content: Metric[];
}

export default function MetricsFilterTable({ content }: MetricsFilterTableProps) {
  const [isFilterChange, setIsFilterChange] = useState(false)
  const [filterContent, setFilterContent] = useState(content)
  const changeFilterContent = async (data: SortData | null) => {
    setIsFilterChange(true)
    try {
      const response = await axios.post(PagesURl.METRICS + '/allProducts/findMetric?productMetrics=All', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      setFilterContent(response.data)
      setIsFilterChange(false)
    } catch (error) {
    }
  }
  return (
    <>
    {isFilterChange ? <Spinner/> :
    <table className='table borderTable'>
      <thead className='thead'>
        <tr className="table__tr">
          <th className="table__th">Код продукта</th>
          {Object.keys(content[0].metrics).map((metric)=>(
            <th className="table__th">{metric}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {filterContent.length !== 0 ? filterContent.map((el) => (
          <tr style={{ cursor: 'pointer' }}>
            <td className="table__content__text">{el.productBarcode}</td>
            {Object.values(el.metrics).map((metric)=>(<td className="table__content__text">{metric}</td>))}
          </tr>
        )): <td colSpan={Object.keys(content[0].metrics).length} className='body__notData'>Нет данных</td>
        }
      </tbody>
    </table>}
    </>
  )
}