import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import Sidebar from '../sidebar/Sidebar';
import ProductsPage from '../../pages/product/products-page';
import SellingPage from '../../pages/selling/selling-page';
import MarketShipmentPage from '../../pages/shipment/market-shipment-page';
import WareShipmentPage from '../../pages/shipment/Ware-shipment-page';
import AccountingPage from '../../pages/accounting/accounting-page';
import MetricsPage from '../../pages/metrics/metrics-page';
import AccountBlock from '../account-block/account-block';
import { useEffect } from 'react';
import { AuthorizationStatus } from '../../types/enums/account-type';
import Spinner from '../spinner/spinner';
import Login from '../../pages/login/login';
import Registration from '../../pages/registration/registration';
import Cabinet from '../../pages/cabinet/cabinet';
import NotFound from '../../pages/not-found/not-found-page';
import Error from '../../pages/error-page/error-page';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MainBlock from '../main-block/main-block';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getLoginError, getStateAuthorizationStatus } from '../../store/user-process/selectors';
import { getUserInfoAction } from '../../store/api-actions/user-api-actions';
import { dropOrganization } from '../../services/organization';
import { ToastContainer, toast } from 'react-toastify';
import AutoLinks from '../auto-links/auto-links';
import OrganizationPage from '../../pages/organization/organization-page';
import WarehouseMenu from '../../pages/warehouse-menu/warehouse-menu';
import Notifications from '../../pages/notifications/notifications';
import { getInvitationsLengthAction, getSyncErrorsLengthAction, getTasksLengthAction } from '../../store/api-actions/organization-api-actions';
import Errors from '../../pages/errors/errors';
import Tasks from '../../pages/tasks/tasks';
import News from '../../pages/news/news';

function App() {
  const authorization = useAppSelector(getStateAuthorizationStatus)
  const dispatch = useAppDispatch()
  const notify = (message: string) => toast(message);


  const error = useAppSelector(getLoginError)

  useEffect(()=>{
    if (authorization === AuthorizationStatus.Auth){
      dispatch(getUserInfoAction())
      dispatch(getInvitationsLengthAction())
      dispatch(getSyncErrorsLengthAction())
      dispatch(getTasksLengthAction())
    } else if (authorization === AuthorizationStatus.NoAuth){
      dropOrganization()
    }
  }, [authorization, dispatch])


  useEffect(()=>{
    if (error){
      notify(error)
    }
  },[error])

  function OutletWrapper() {
    const navigate = useNavigate()
    useEffect(() => {
      if (authorization === AuthorizationStatus.NoAuth) {
        dropOrganization()
        !window.location.pathname.includes('/login') && navigate('/login')
      }
    }, [])
    return (
      <>
        <ToastContainer/>
        <Helmet>
          <title>Главная</title>
        </Helmet>
        <div className='app_container'>
          <main className="content">
            <Sidebar />
            <div className='app__right'>
              <AccountBlock/>
              <div className='line'></div>
              <div className='line__autoLinks'><AutoLinks/></div>
              <div className='page'>
                {window.location.pathname !== '/' ? <Outlet /> :
                 <MainBlock/>}
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }

  if (authorization === AuthorizationStatus.Unknown) {
    return <Spinner />
  }

  return (
    <HelmetProvider>
        <ToastContainer/>
        <Routes>
          <Route path='/login' element={<Login/>} />
          <Route path='/registration' element={<Registration/>} />
          <Route path='/' element={<OutletWrapper/>}>
            <Route path="/products" element={<ProductsPage />} />
            <Route path='/accounting' element={<AccountingPage />} />
            <Route path="/warehouseshipment" element={<WareShipmentPage />} />
            <Route path="/marketshipment" element={<MarketShipmentPage />} />
            <Route path="/selling" element={<SellingPage />} />
            <Route path="/metrics" element={<MetricsPage />} />
            <Route path='/cabinet' element={<Cabinet/>} />
            <Route path='/:id' element={<OrganizationPage/>}/>
            <Route path='/warehouse' element={<WarehouseMenu/>}/>
            <Route path='/notifications' element={<Notifications/>}/>
            <Route path='/errors' element={<Errors/>}/>
            <Route path='/tasks' element ={<Tasks/>}/>
            <Route path='/news' element={<News/>}/>
          </Route>
          <Route path='*' element={<NotFound />} />
          <Route path='/error' element={<Error />} />
        </Routes>
    </HelmetProvider>
  );
}

export default App;