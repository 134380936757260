import axios from 'axios';
import { dropToken, getToken } from './token';
import { dropOrganization, getOrganization } from './organization';

export const BACKEND_URL = 'https://supplement.holaf-tech.ru/v1/api/';


export const BACKEND_TESTURL = ' https://supplement.dev.holaf-tech.ru/v1/api/'

export const PagesURl = {
  AUTH: 'auth',
  USER: 'user',
  SHIPMENT: 'shipment',
  PRODUCT: 'product',
  REMAINS: 'remains',
  SELLING: 'sale',
  PAYMENT: 'payment',
  METRICS: 'metrics',
  ORGANIZATION: 'organization',
  ExternalData: 'external-data',
  WAREHOUSE: 'warehouse',
  BACKGROUND: 'background-job'
}

const REQUEST_TIMEOUT = 10000;


const instance = axios.create({
  baseURL: BACKEND_URL,
  timeout: REQUEST_TIMEOUT
});

const checkUploadOrganization = (url: string) => {
  return !(url.includes('organization') ||
  url.includes('metrics') || url.includes('remains/find'))
}


instance.interceptors.response.use(
  response => response,
  error => {
    console.log(error)
    if (error.message === `timeout of ${10000}ms exceeded`){
      console.log(error)
    } else {
      switch (error.response.status){
        case 401:
          dropToken()
          break
        case 404:
          window.location.href = 'notFound'
          break
        case 403:
          window.location.href = '/'
          break
        case 400:
          dropOrganization()
          break
/*         case 500:
          window.location.href = 'error'
          break */
        default: console.log(error)
      if (error.code === 'ECONNABORTED'){
        window.location.href = 'error'
      }
      }
    }
    
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  config => {
    config.timeout = REQUEST_TIMEOUT;
    config.headers.Authorization = 'Bearer ' + getToken();
    if (config.url){
      if (checkUploadOrganization(config.url)){
        config.params = {
          ...config.params,
          organizationId: getOrganization()
        }
      }
    }
    //console.log(config)
    return config;
  },
/*   error => Promise.reject(error) */
);

export default instance;