import DropDownList from '../../components/dropdown-list/dropdown-list'
import { Entities, ParticipantRole } from '../../types/enums/organization-type'
import { enumValuesToList } from '../../utils'
import './organization-page.css'

type ParticipantRoleFieldsProps = {
  userInfo: { role: ParticipantRole, entities: Entities[] }
  changeUserInfo: (newInfo: { role: ParticipantRole, entities: Entities[] }) => void
}

export default function ParticipantRoleFields({userInfo, changeUserInfo}: ParticipantRoleFieldsProps) {

  const getActiveValuesEntities = () => {
    let result = []
    for (let i in userInfo.entities){
      result.push(Entities[userInfo.entities[i]])
    }
    return result.join(', ')
  }

  const getList = (select: 'role' | 'entities') => {
    const list = enumValuesToList(select === 'role' ? ParticipantRole : Entities)
    let result: { value: string, isActive: boolean }[] = []
    for (let i in list) {
      if (typeof list[i] === 'string'){
        if (select === 'role') {
          if (list[i] === ParticipantRole[userInfo.role]) {
            result.push({ value: list[i], isActive: true })
          } else {
            result.push({ value: list[i], isActive: false })
          }
        } else if (select === 'entities') {
          if (userInfo.entities[userInfo.entities.length - 1] === 64) {
            for (let i of list) {
              if (i !== 'Все') {
                result.push({ value: i, isActive: true })
              }
            }
            return result
          }
          let flag = false
          for (let entity of userInfo.entities) {
            if (list[i] === Entities[entity] && list[i] !== 'Все') {
              result.push({ value: list[i], isActive: true })
              flag = true
              break
            }
          }
          if (!flag && list[i] !== 'Все') {
            result.push({ value: list[i], isActive: false })
          }
        }
      }
    }
    return result
  }

  const onChangeRole = (value: string) => {
    const enumValues = enumValuesToList(ParticipantRole)
    for (let i = 0; i < enumValues.length; i++) {
      if (enumValues[i] === value) {
        changeUserInfo({ ...userInfo, role: i })
      }
    }
  }

  const onChangeEntities = (value: string, isAdd?: boolean) => {
    if (typeof isAdd === 'boolean') {
      const enumValues = enumValuesToList(Entities)
      if (isAdd) {
        if (userInfo.entities.length === enumValues.length - 2) {
          changeUserInfo({ ...userInfo, entities: [64] })
          return
        } else {
          let newEntities = userInfo.entities.slice()
          newEntities.push(Entities[value as keyof typeof Entities])
          newEntities = newEntities.filter(item => item !== 64);
          changeUserInfo({ ...userInfo, entities: newEntities })
          return
        }
      } else {
        let newEntities = userInfo.entities.slice()
        if (newEntities.length === 1 && newEntities[0] === 64) {
          newEntities = [];
          const list = enumValuesToList(Entities)
          let count = 0
          let i = 1
          while (count < list.length){
            count++
            newEntities.push(i)
            i *= 2
          }
          newEntities = newEntities.filter(item => item !== 64);
        }
        newEntities = newEntities.filter(item => item !== Entities[value as keyof typeof Entities]);
        changeUserInfo({ ...userInfo, entities: newEntities })
        return
      }
    }
  }

  return (
    <>
      <div className='mainModalBlock__select'>
        <DropDownList type='radio' key={'role'} onChangeValue={onChangeRole} list={getList('role')} isRequired activeValue={ParticipantRole[userInfo.role]} title={'Права:'} />
      </div>
      <DropDownList type='checkbox' key={'entities'} onChangeValue={onChangeEntities} list={getList('entities')} isRequired activeValue={getActiveValuesEntities()} title={'Доступ к разделам:'} />
    </>
  )
}