import axios, { PagesURl } from '../services/api';
import { MarketRemains, Remains } from '../types/product-model';
export const getAllRemainsWarehouse = async () => {
  try{
    const response = await axios.post<{data:Remains[], totalCount: number}>(PagesURl.REMAINS + '/find');
    return response.data.data
  }catch(error){
    console.log(error)
    return []
  }
}

export const getAllRemainsMarketplace = async (warehouseId: string) => {
  try{
    const response = await axios.post<{data:MarketRemains[], totalCount: number}>(PagesURl.REMAINS + `/find/warehouse?warehouseId=${warehouseId}`);
    return response.data.data
  }catch(error){
    console.log(error)
    return []
  }
}