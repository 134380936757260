import { useEffect, useState } from 'react';
import axios, { PagesURl } from '../../services/api';
import './products-page.css';
import ModalWindow from '../../components/modal-window/modal-window';
import Modal from 'react-modal';
import { ChangeProduct, CreateProduct, Product } from '../../types/product-model';
import { checkChangeProduct, checkCreateProduct } from '../../utils/product';
import Spinner from '../../components/spinner/spinner';
import ProductFilterTable from '../../components/filter-table/product-filter-table/product-filter-table';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateButton from '../../components/buttons/create-button/create-button';
import FileInput from '../../components/custom-input/file-input/file-input';
import { Helmet } from 'react-helmet-async';
import { getTotalStages } from '../../utils/filter-table';
import { SortData } from '../../types/main';
import { useNavigate } from 'react-router-dom';
import { getOrganization } from '../../services/organization';
import { useAppSelector } from '../../hooks';
import { getStateActiveOrganization } from '../../store/organization-process/selectors';
import excelAxios from 'axios';
import { getToken } from '../../services/token';
import { getUserRoles } from '../../store/user-process/selectors';
import { stageList } from '../../consts';
import PaginationBlock from '../../components/pagination-block/pagination-block';
import SynchronizationBlock from '../../components/synchronization-block/synchronization-block';
import ControlPanel from '../../components/control-panel/control-panel';

function ProductsPage() {
  const navigate = useNavigate()
  useEffect(() => {
    if (!getOrganization()) {
      navigate('/')
    }
  })

  const user = useAppSelector(getUserRoles)
  const activeOrganization = useAppSelector(getStateActiveOrganization)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDisplayInfo, setIsDisplayInfo] = useState(false)
  const [isDisplayChange, setIsDisplayChange] = useState(false)
  const [selectProduct, setSelectProduct] = useState<Product | null>(null)
  const [products, setProducts] = useState<Product[]>([]);
  const [productsIsLoading, setProductsIsLoading] = useState(true)
  const [productCount, setProductCount] = useState(0)
  const [activeStage, setActiveStage] = useState<number | null>(null)
  const [countRows, setCountRows] = useState<number>(stageList[0])
  const [activeFilter, setActiveFilter] = useState<SortData>({ take: countRows, skip: 0 })

  const notify = (message: string) => toast(message);

  const baseUrl = PagesURl.PRODUCT

  const handleGetProducts = async (data: SortData = { take: countRows, skip: 0 }) => {
    setProductsIsLoading(true)
    try {
      const response = await axios.post(PagesURl.PRODUCT + '/find', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const products = response.data.data;
      setProducts(products)
      setProductCount(response.data.totalCount)
      setActiveFilter(data)
      if (!activeStage) {
        setActiveStage(response.data.totalCount > data.take ? 1 : null)
      }
      setProductsIsLoading(false)
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  };

  const handleCreateSubmit = async (data: CreateProduct) => {
    await axios
      .post(baseUrl + '/create', data, {
        headers: {
          'Content-Type': 'application/json'
        },
      })
      .then(() => {
        handleGetProducts();
      })
      .catch((error: any) => {
        if (error.response.data.match(/Message = (.*?),/)){
          notify(error.response.data.match(/Message = (.*?),/)[1])
        }
      });
  };
  const handleChangeProduct = async (data: ChangeProduct) => {
    await axios
      .put(baseUrl + '/edit', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        handleGetProducts();
      })
      .catch((error) => {
        if (error.response.data.match(/Message = (.*?),/)){
          notify(error.response.data.match(/Message = (.*?),/)[1])
        }
      });
  };
  const hadleGetProductInfo = (obj: number): void => {
    const barcode = obj
    for (let product of products) {
      if (product.barcode === barcode) {
        setSelectProduct(product)
        setIsDisplayInfo(true)
        return
      }
    }
  }
  const handleUploadProducts = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    if (e.target.files) {
      for (let i in e.target.files) {
        if (!isNaN(parseInt(i))) {
          formData.append("excelFiles", e.target.files[i])
        }
      }
    }
    try {
      await excelAxios.post(
        PagesURl.ExternalData + '/product/create/byExcel',
        formData,
        {
          headers: {
            "accept": "application/json",
            "Content-type": "multipart/form-data",
            "Authorization": 'Bearer ' + getToken()
          },
        });
      handleGetProducts()
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }
  const handleDeleteProduct = async (barcode: number) => {
    try {
      await axios.delete(baseUrl + `/delete?productBarcode=${barcode}`, {
        headers: {
          'Content-Type': 'application/json',
        }
      },
      ).then(() => { handleGetProducts() })
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }
  const handleSynchronizeWb = async () => {
    if (activeOrganization) {
      await axios.post(PagesURl.ExternalData + `/product/create/byWBApi?organizationId=${activeOrganization.id}`)
      handleGetProducts()
    }
  }
  const handleSynchronizeOzon = async () => {
    if (activeOrganization) {
      await axios.post(PagesURl.ExternalData + `/product/create/byOzonApi?organizationId=${activeOrganization.id}`)
      handleGetProducts()
    }
  }

  const displayChangeProduct = () => {
    setIsDisplayInfo(false)
    setIsDisplayChange(true)
  }

  const openChangeModal = (product: Product) => {
    setSelectProduct(product)
    setIsDisplayChange(true)
  }

  const changeActiveStage = (toStage: number) => {
    let newSortData: SortData = JSON.parse(JSON.stringify(activeFilter))
    newSortData.skip = countRows * toStage
    handleGetProducts(newSortData)
    setActiveStage(toStage + 1)
  }

  const changeSelectedValue = (newValue: number) => {
    setCountRows(newValue)
    handleGetProducts({ take: newValue, skip: 0 })
  }

  useEffect(() => {
    handleGetProducts();
  }, []);

  if (!user) {
    return <Spinner />
  }

  return (
    <>
      <Helmet>
        <title>Товары</title>
      </Helmet>
      <div className="products-container">
        {user.role > 0 && <div className='flex products__buttonsBlock'>
          <div className='centerFlex'>
            <div className='products__createButtonContainer'>
              <CreateButton icon='/img/sidebar/products-icon.svg' text='Создать продукт' createAction={() => { setIsCreateModalOpen(true) }} />
            </div>
          </div>
          <div className='products__synchronizeBlock'>
              <ControlPanel 
                content={[{
                  title: 'Панель управления',
                  data: <div>
                  <div className='flex synchronizeBlock'>
                    <FileInput displayPattern onUploadFiles={handleUploadProducts} />
                  </div>
                </div>
                },
                {
                  title: 'Синхронизация',
                  data: <>
                  <SynchronizationBlock isActive={activeOrganization?.wbApiKey ? true : false} text='Cинхронизация с WB' onSynchronization={()=>{handleSynchronizeWb()}}/>
                  <SynchronizationBlock isActive={activeOrganization?.ozonApiKey && activeOrganization.ozonClientId ? true : false} text='Cинхронизация с OZON' onSynchronization={()=>{handleSynchronizeOzon()}}/>
                  </>
                }
              ]}
                />
          </div>
        </div>}
          <PaginationBlock
            onChangeSelectedValue={changeSelectedValue}
            activeStage={activeStage}
            getTotalStages={() => { return getTotalStages(productCount, countRows) }}
            countRows={countRows}
            changeActiveStage={changeActiveStage}
            children={!productsIsLoading ?
                <ProductFilterTable isEdit={user.role > 1} activeFilter={activeFilter} onFilter={handleGetProducts} onDeleteProduct={handleDeleteProduct} changeProductModal={openChangeModal} content={products} getPointInfo={hadleGetProductInfo} />
                : <Spinner />}
          />
        <ModalWindow
          isOpen={isCreateModalOpen}
          title={'Создание продукта'}
          btnText='Создать'
          fields={[
            {
              name: 'Название',
              backName: 'name',
              type: 'text'
            },
            {
              name: 'Баркод(ШК)',
              backName: 'barcode',
              type: 'number'
            },
            {
              name: 'Артикул Ozon',
              backName: 'articleOzon',
              type: 'number',
              required: false
            },
            {
              name: 'Артикул Wildberries',
              backName: 'articleWB',
              type: 'number',
              required: false
            },
            {
              name: 'Код поставщика',
              backName: 'vendorCode',
              type: 'text',
              required: false
            }
          ]}
          onRequestClose={() => { setIsCreateModalOpen(false) }}
          checkFields={checkCreateProduct}
          onSubmit={handleCreateSubmit} />
        <Modal
          isOpen={isDisplayInfo}
          ariaHideApp={false}
          contentLabel="Product Info"
          className="modal"
          overlayClassName="modal-overlay"
          onRequestClose={() => { setIsDisplayInfo(false) }}
        >
          <div>
            {selectProduct && <div className='modal-content'>
              <div className="closeInfo__button" onClick={() => { setIsDisplayInfo(false) }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(0.719608 0.69438 -0.719608 0.69438 0.392899 2.15039)" stroke="black" strokeWidth="2" />
                  <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(-0.719608 0.69438 -0.719608 -0.69438 21.6071 1)" stroke="black" strokeWidth="2" />
                </svg>
              </div>
              <div className='modal__line'>
                <p className='modal__name'>Имя:</p>
                <p className='modal__text'>{selectProduct.name}</p>
              </div>
              <div className='modal__line'>
                <p className='modal__name'>Код товара:</p>
                <p className='modal__text'>{selectProduct.barcode}</p>
              </div>
              <div className='modal__line'>
                <p className='modal__name'>Активен:</p>
                <p className='modal__text'>{selectProduct.isActive ? 'Да' : 'Нет'}</p>
              </div>
              <button className="addButton" onClick={displayChangeProduct}>Редактировать</button>
            </div>
            }
          </div>

        </Modal>
        {selectProduct &&
          <ModalWindow
            title='Редактирование товара'
            btnText='Редактировать'
            fields={[
              {
                name: 'Назание',
                backName: 'name',
                type: 'text',
                value: selectProduct.name
              },
              {
                name: 'Баркод(ШК)',
                backName: 'barcode',
                type: 'number',
                value: selectProduct.barcode
              },
              {
                name: 'Артикул Ozon',
                backName: 'articleOzon',
                type: 'number',
                value: selectProduct.articleOzon
              },
              {
                name: 'Артикул Wildberries',
                backName: 'articleWB',
                type: 'number',
                value: selectProduct.articleWB
              },
              {
                name: 'Код поставщика',
                backName: 'vendorCode',
                type: 'text',
                value: selectProduct.vendorCode,
                required: true
              }
            ]}
            isOpen={isDisplayChange}
            onRequestClose={() => { setIsDisplayChange(false) }}
            checkFields={checkChangeProduct}
            onSubmit={handleChangeProduct}
          />}
      </div>
    </>
  );
}

export default ProductsPage;
