import { useState } from 'react'
import { Entities, ParticipantRole } from '../../../types/enums/organization-type'
import { Participant } from '../../../types/organization-model'
import './main-block-modal.css'
import { useAppDispatch } from '../../../hooks'
import { editParticipantAction } from '../../../store/api-actions/organization-api-actions'
import ParticipantRoleFields from '../../../pages/organization/participant-role-fields'

type MainBlockModalProps = {
  user: Participant
  closeModal: () => void
}

export default function MainBlockModal({ user, closeModal }: MainBlockModalProps) {

  const dispatch = useAppDispatch()

  const [newInfo, setNewInfo] = useState<{ role: ParticipantRole, entities: Entities[] }>({
    role: user.role,
    entities: user.entities
  })

  const handleSubmitInfo = () => {
    if (user.role !== newInfo.role || (JSON.stringify(user.entities) !== JSON.stringify(newInfo.entities))){
      let newInfoData = {...newInfo, phone: user.phone, entities: newInfo.entities.reduce((total, current) => total + current, 0)}
      dispatch(editParticipantAction(newInfoData))
    }
    closeModal()
  }

  return (
    <div className='mainModalBlock__container'>
      <div className='mainModalBlock__content'>
        <h1 className='mainModalBlock__title'>Изменение прав доступа</h1>
        <ParticipantRoleFields changeUserInfo={setNewInfo} userInfo={newInfo}/>
        <button onClick={handleSubmitInfo} className='mainModalBlock__submitButton pointer'>Сохранить</button>
      </div>
    </div>
  )
}