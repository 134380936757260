import { useEffect, useState } from 'react'
import InfoContainer from '../../components/info-container/info-container'
import { stageList } from '../../consts'
import styles from './tasks.module.css'
import { SortData } from '../../types/main'
import { getTotalStages } from '../../utils/filter-table'
import axios, { PagesURl } from '../../services/api'
import { Task } from '../../types/task'
import { toast } from 'react-toastify'
import Spinner from '../../components/spinner/spinner'
import { getOrganization } from '../../services/organization'
import { TaskDate, TaskState } from '../../types/enums/task-type'
import { prettyDate } from '../../utils'
import ModalComponent from '../../components/modal-component/modal-component'

export default function Tasks(){

  const [countRows, setCountRows] = useState<number>(stageList[0])
  const [activeStage, setActiveStage] = useState<number | null>(null)
  const [tasksCount, setTasksCount] = useState(0)
  const [activeFilter, setActiveFilter] = useState<SortData>({ take: countRows, skip: 0 })
  const [tasks, setTasks] = useState<Task[]>()
  const [activeTask, setActiveTask] = useState<Task>()
  const [isNew, setIsNew] = useState(true)

  const handleGetTasks = async (data: SortData = { take: countRows, skip: 0 }, isNew: boolean = true, actStage: number | null = activeStage) => {
    try {
      const response = await axios.post(PagesURl.BACKGROUND + `/find/organization`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
        params: {
          organizationId: getOrganization(),
          isAll: !isNew
        }
      });
      const products = response.data.data;
      setTasks(products)
      setTasksCount(response.data.totalCount)
      setActiveFilter(data)
      if (!actStage) {
        setActiveStage(response.data.totalCount > data.take ? 1 : null)
      }
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        toast(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }

  const changeSelectedValue = (newValue: number) => {
    setCountRows(newValue)
    handleGetTasks({ take: newValue, skip: 0 }, isNew)
  }

  const changeActiveStage = (toStage: number) => {
    let newSortData: SortData = JSON.parse(JSON.stringify(activeFilter))
    newSortData.skip = countRows * toStage
    handleGetTasks(newSortData, isNew)
    setActiveStage(toStage + 1)
  }

  const changeIsAll = (displayAll: boolean) => {
    setIsNew(displayAll)
    handleGetTasks({ take: countRows, skip: 0 }, displayAll, null)
  }

  useEffect(()=>{
    handleGetTasks()
  },[])

  if (!tasks){
    return <Spinner/>
  }
   
  return (
    <div>
      <InfoContainer
        title='Фоновые задачи'
        pointCount={tasksCount}
        onChangeSelectedValue={changeSelectedValue}
        activeStage={activeStage}
        getTotalStages={() => { return getTotalStages(tasksCount, countRows) }}
        countRows={countRows}
        changeActiveStage={changeActiveStage}
        changeContainer={{changeState: changeIsAll, firstText: 'Активные', secondText: 'Все'}}>
        <div className='notifications__content'>
          {tasks.map((task, index) => (
            <div key={`${index}--${task.organizationName}`} className='notifications__notification centerFlex'>
              <div className='notifications__text centerFlex'>
                <img alt='' src={`img/tasks/${task.state !== 'Failed' ? 'tasks' : 'task_failed'}.svg`} className={styles.img}/>
                <div className='notifications__textContent'>
                  <p className='notifications__textName'>{task.method}</p>
                  <p className='notifications__textRole'>{`Организация: ${task.organizationName}`}</p>
                  <p className='notifications__textRole'>{`Инициатор: ${task.phone}`}</p>
                </div>
              </div>
              <div className={styles.rightBlock}>
                <div className={styles.dates}>
                {Object.entries(task.datesInfo).map(([key, value], index)=>(
                  <div className={styles.date} key={`${key}--${index}`}>
                    <span className={styles.dates__name}>{`${TaskDate[key as keyof typeof TaskDate]}:`}</span>
                    <span className={styles.dates__value}>{prettyDate(value)}</span>
                  </div>
                ))}
                </div>
                <div className={`${styles.task__state} centerFlex`}>
                  <img alt={task.state} src={`img/tasks/${task.state.toLowerCase()}.svg`} />
                  <span onClick={()=>{task.state === 'Failed' ? setActiveTask(task) : {}}} className={task.state !== 'Failed' ? styles.state : styles.state_failed}>{TaskState[task.state]}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </InfoContainer>
      {activeTask && 
      <ModalComponent title='Error Log' onClose={()=>{setActiveTask(undefined)}}>
          <div className={styles.errorBlock}><p className={styles.errorBlock__text}>{activeTask.message}</p></div>
      </ModalComponent>}
    </div>
  )
}