import './create-button.css'

type CreateButtonProps = {
  icon: string
  text: string,
  createAction: ()=> void
}

export default function CreateButton({text, icon, createAction}: CreateButtonProps){
  return (
    <div onClick={createAction} className='container createButton__container'>
      <img className='icon' alt={text} src={icon}/>
      <span className='text'>{text}</span>
      <img alt='create' src='/img/create.svg'/>
    </div>
  )
}