import { useEffect, useState } from 'react';
import './shipment-page.css';
import axios, { PagesURl } from '../../services/api';
import Modal from 'react-modal';
import { ShipmentState } from "../../types/enums/shipment-type";
import { BackChangeParentShipment, BackCreateParentShipment, ChangeParentShipment, CreateParentShipment, HistoryTableShipment, ParentShipment, ParentTableShipment, ShipmentsHistoryDto } from '../../types/shipment-model';
import { getAllProducts } from '../../utils/product';
import { Product } from '../../types/product-model';
import moment from 'moment';
import Spinner from '../../components/spinner/spinner';
import { checkCreateParentShipment, getMissingLines, sortActivityLog } from '../../utils/shipment';
import { getDataValue, getDataValueInput } from '../../utils';
import MissingLine from './missing-line';
import WareShipmnentFilterTable from '../../components/filter-table/ware-shipment-filter-table/ware-shipment-filter-table';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateButton from '../../components/buttons/create-button/create-button';
import { Helmet } from 'react-helmet-async';
import { SortData } from '../../types/main';
import { getTotalStages } from '../../utils/filter-table';
import { useNavigate } from 'react-router-dom';
import { getOrganization } from '../../services/organization';
import { getUserRoles } from '../../store/user-process/selectors';
import { useAppSelector } from '../../hooks';
import { stageList } from '../../consts';
import CreateWareShipmentModalWindow from '../../components/modal-window/shipment/create-warehouse-shipment';
import PaginationBlock from '../../components/pagination-block/pagination-block';
import { Warehouse } from '../../types/warehouse-model';

function WareShipmentPage() {
  const navigate = useNavigate()
  useEffect(()=>{
    if (!getOrganization()){
      navigate('/')
    }
  })

  const user = useAppSelector(getUserRoles)

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDisplayHistory, setIsDisplayHistory] = useState(false);
  const [isHistoryChange, setIsHistoryChange] = useState(false)
  const [isChangeShipment, setIsChangeShipment] = useState(false)

  const [shipments, setShipments] = useState<ParentShipment[]>([]);
  const [activeShipment, setActiveShipment] = useState<ParentShipment | null>(null)
  const [isShipmentsLoading, setIsShipmentsLoading] = useState(true);
  const [paymentsIds, setPaymentsIds] = useState<string[]>([])

  const [tableShipments, setTableShipments] = useState<ParentTableShipment[]>([]);
  const [history, setHistory] = useState<HistoryTableShipment[] | null>(null);
  const [products, setProducts] = useState<Product[]>([])
  const [warehouses, setWarehouses] = useState<Warehouse[]>([])

  const [activeStage, setActiveStage] = useState<number | null>(null)
  const [countRows, setCountRows] = useState<number>(stageList[0])
  const [activeFilter, setActiveFilter] = useState<SortData>({take: countRows, skip: 0})
  const [shipmentCount, setShipmentCount] = useState(0)

  const notify = (message: string) => toast(message);

  const baseUrl = `${PagesURl.SHIPMENT}/parent`;
  const historyUrl = `${PagesURl.SHIPMENT}/history/parent`

  const hadndleFindShipments = async (data : SortData = {take: countRows, skip: 0}) => {
    setIsShipmentsLoading(true)
    try {
      const response = await axios.post<{data:ParentShipment[], totalCount: number}>(baseUrl + "/find",data,{
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setShipments(response.data.data);
      const result: ParentTableShipment[] = [];
      const payments = []
      for (const shipment of response.data.data) {
        payments.push(shipment.payment.id)
        result.push({
          id: shipment.id,
          name: shipment.name,
          createdAt: getDataValue(shipment.createdAt),
          description: shipment.description,
          products: shipment.products,
          warehouseName: shipment.warehouseName,
          state: ShipmentState[shipment.state],
          totalAmount: shipment.totalAmount,
          paidAmount: shipment.paidAmount,
          paidPercent: shipment.paidPercent,
        });
      }
      setPaymentsIds(payments)
      setTableShipments(result)

      setShipmentCount(response.data.totalCount)
      setActiveFilter(data)
      if (!activeStage){
        setActiveStage(response.data.totalCount > data.take ? 1 : null)
      }

      setIsShipmentsLoading(false)
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  };

  useEffect(() => {
    hadndleFindShipments()
  
  }, []);

  const changeActiveStage = (toStage: number) => {
    let newSortData: SortData = JSON.parse(JSON.stringify(activeFilter))
    newSortData.skip = countRows * toStage
    hadndleFindShipments(newSortData)
    setActiveStage(toStage + 1)
  }

  const handleCreateSubmit = async (data: CreateParentShipment) => {
    const newData:BackCreateParentShipment  = {
      name: data.name,
      warehouseId: data.warehouseId,
      products:data.products.map((product)=>({productBarcode:product.productBarcode, quantity: product.quantity, netCost: product.netCost})),
      createdAt:data.createdAt,
      payment:{
        logisticCost:data.logisticCost,
        paymentDeadline:data.paymentDeadline
      }
    }
    await axios
      .post(baseUrl + '/create', newData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        setTableShipments([])
        hadndleFindShipments();
      })
      .catch((error: any) => {
        if (error.response.data.match(/Message = (.*?),/)){
          notify(error.response.data.match(/Message = (.*?),/)[1])
        }
      });
  };

  const handleChangeShipment = async (data: CreateParentShipment) => {
    if (!activeShipment){
      setIsChangeShipment(false)
      setTableShipments([])
      hadndleFindShipments()
      return
    }
    const newData:BackChangeParentShipment  = {
      id: activeShipment.id,
      name: data.name,
      warehouseId: data.warehouseId,
      products:data.products.map((product)=>({productBarcode:product.productBarcode, quantity: product.quantity, netCost: product.netCost})),
      createdAt:data.createdAt,
      description:data.description,
      payment:{
        logisticCost:data.logisticCost,
        paymentDeadline:data.paymentDeadline
      }
    }
    await axios
      .put(baseUrl + '/edit', newData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        setIsChangeShipment(false)
        setTableShipments([])
        hadndleFindShipments();
      })
      .catch((error: any) => {
        if (error.response.data.match(/Message = (.*?),/)){
          notify(error.response.data.match(/Message = (.*?),/)[1])
        }
      });
  }

  const handleGetSubmit = async (shipmentNumber: string) => {
    try {
      const response = await axios.get<ShipmentsHistoryDto>(`${historyUrl}?id=${shipmentNumber}`,{
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data.shipmentsHistory
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        if (error.response.data.match(/Message = (.*?),/)){
          notify(error.response.data.match(/Message = (.*?),/)[1])
        }
      }
    }
  };

  const handleStateChangeSubmit = async (data: ChangeParentShipment) => {
    await axios
      .post(baseUrl + `/state/change`, data,{
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        setIsHistoryChange(true)
        getShipmentInfo(data.shipmentId)
        hadndleFindShipments();
        setIsHistoryChange(false)
      })
      .catch((error: any) => {
        if (error.response.data.match(/Message = (.*?),/)){
          notify(error.response.data.match(/Message = (.*?),/)[1])
        }
      });
  };

  const handleFindWareHouses = async () => {
    try{
       const {data} = await axios.post<{data: Warehouse[], totalCount: number}>(PagesURl.WAREHOUSE + '/find')
       setWarehouses(data.data)
    }catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }

  const getShipmentInfo = async (shipmentId: string) => {
    let emtyFlag = false;
    const shipment = await handleGetSubmit(shipmentId)
    let result: HistoryTableShipment[] = []
    // eslint-disable-next-line array-callback-return
    shipment?.map((point) => {
      if (point.newState === 0) {
        emtyFlag = true
      }
      result.push({
        'id': point.id,
        'shipmentNumber': point.shipmentNumber,
        'editDate': moment(point.editDate).utcOffset('+00:00').format('DD.MM.YYYY HH.mm'),
        'newState': point.newState,
      })
    })
    if (!emtyFlag) {
      for (let shipment in shipments) {
        if (shipments[shipment].id === shipmentId) {
          result.push({
            id: '0',
            shipmentNumber: shipmentId,
            editDate: moment(shipments[shipment].createdAt).utcOffset('+00:00').format('DD.MM.YYYY HH.mm'),
            newState: 0,
          })
        }
      }
    }
    result = sortActivityLog(result) as HistoryTableShipment[]
    setHistory(result)
    setIsDisplayHistory(true)
  }

  const openCreateModal = () => {
    handleFindWareHouses()
    getAllProducts().then((products) => {
      if (products){
        setProducts(products)
      }
    })
    setIsCreateModalOpen(true)
  }

  const changeDate = (newDate: string, shipmentId: string, targetState: number) => {
      handleStateChangeSubmit({
        'shipmentId': shipmentId,
        'editDate': newDate + ':00.000Z',
        'newState': targetState
      })
  }

  const handleDeleteShipment = async (shipmentNumber: string) => {
    try {
      await axios.delete(baseUrl + `/delete?id=${shipmentNumber}`,{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(() => { hadndleFindShipments() })
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }

  const changeSelectedValue = (newValue: number) => {
    setActiveStage(null)
    setCountRows(newValue)
    hadndleFindShipments({take: newValue, skip: 0})
  }

  const onChangeShipment = async (e: React.MouseEvent<HTMLOrSVGElement>, shipment: ParentTableShipment) => {
    e.stopPropagation()
    try {
      const {data} = await axios.get<{shipmentInfo: ParentShipment}>(baseUrl + `/get?id=${shipment.id}`,{
        headers: {
          'Content-Type': 'application/json',
        },
      })
      setActiveShipment(data.shipmentInfo)
      setIsChangeShipment(true)
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }

  if (!user){
    return <Spinner/>
  }

  return (
    <>
      <Helmet>
        <title>Поставки до Склада</title>
      </Helmet>
      <div className="shipment-container">
        <div className='shipment-content'>
        {user.role > 0 &&
              <CreateButton text='Создать поставку' createAction={openCreateModal} icon='/img/sidebar/warehouseshipment-icon.svg' />}
          <PaginationBlock
            onChangeSelectedValue={changeSelectedValue}
            activeStage={activeStage}
            getTotalStages={()=>{return getTotalStages(shipmentCount, countRows)}}
            countRows={countRows}
            changeActiveStage={changeActiveStage}
            children={<div className='shipment-table__container'>
            {!isShipmentsLoading ?
                <WareShipmnentFilterTable onChangeShipment={onChangeShipment} payments={paymentsIds} isEdit={user.role > 1} activeFilter={activeFilter} onFilter={hadndleFindShipments} onDeleteShipment={handleDeleteShipment} content={tableShipments} getPointInfo={getShipmentInfo}
                /> : 
              <Spinner />
            }
          </div>}
          />
        </div>

        <CreateWareShipmentModalWindow
          isOpen={isCreateModalOpen}
          title={'Создание поставки'}
          btnText='Создать'
          fields={[
            {
              name: 'Склад',
              backName: 'warehouseId',
              type: 'select',
              options: warehouses
            },
            {
              name: 'Стоимость логистики',
              backName: 'logisticCost',
              type: 'number',
              required: true
            },
            {
              name: 'Товар',
              backName: 'products',
              type: 'table',
              options: products
            },
            {
              name: 'Название поставки',
              backName: 'name',
              type: 'text'
            },
            {
              name: 'Срок оплаты',
              backName: 'paymentDeadline',
              type: 'datetime-local',
            },
            {
              name: 'Дата создания',
              backName: 'createdAt',
              type: 'datetime-local',
            },
          ]}
          onRequestClose={() => { setIsCreateModalOpen(false) }}
          onSubmit={handleCreateSubmit}
          checkFields={checkCreateParentShipment}
          onError={(error: string)=>{notify(error)}}
        />


        {activeShipment &&
        <CreateWareShipmentModalWindow
          isOpen={isChangeShipment}
          title={activeShipment.name}
          btnText='Создать'
          fields={[
            {
              name: 'Склад',
              backName: 'warehouseId',
              type: 'select',
              options: warehouses,
              value: activeShipment.warehouseName
            },
            {
              name: 'Стоимость логистики',
              backName: 'logisticCost',
              type: 'number',
              value: activeShipment.payment.logisticCost,
              required: true,
            },
            {
              name: 'Товар',
              backName: 'products',
              type: 'table',
              value: activeShipment.products
            },
            {
              name: 'Название поставки',
              backName: 'name',
              type: 'text',
              value: activeShipment.name
            },
            {
              name: 'Срок оплаты',
              backName: 'paymentDeadline',
              type: 'datetime-local',
              value: activeShipment.payment.paymentDeadline
            },
            {
              name: 'Дата создания',
              backName: 'createdAt',
              type: 'datetime-local',
              value: activeShipment.createdAt
            },
            {
              name: 'Описание поставки',
              backName: 'description',
              type: 'text',
              value: activeShipment.description
            },
          ]}
          onRequestClose={() => { setIsChangeShipment(false) }}
          onSubmit={handleChangeShipment}
          checkFields={checkCreateParentShipment}
          onError={(error: string)=>{notify(error)}}
        />}


        <Modal
          isOpen={isDisplayHistory}
          ariaHideApp={false}
          contentLabel="Product Info"
          className="modal"
          overlayClassName="modal-overlay"
          onRequestClose={() => { setIsDisplayHistory(false) }}
        >
          <div>
            {history && <div className='modal-content'>
            {isHistoryChange ? <Spinner/> :
            <>
              <div className="closeInfo__button" onClick={() => { setIsDisplayHistory(false) }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(0.719608 0.69438 -0.719608 0.69438 0.392899 2.15039)" stroke="black" strokeWidth="2" />
                  <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(-0.719608 0.69438 -0.719608 -0.69438 21.6071 1)" stroke="black" strokeWidth="2" />
                </svg>
              </div>

              {history.length !== 0 ? 
              <div className='history__tableContainer'>
              <table className='history__table borderTable'>
                <thead className='history__thead'>
                  <tr>
                    <th>Состояние</th>
                    <th>Время перехода в состояние</th>
                  </tr>
                </thead>
                <tbody>
                  {history.map((el) => (
                    <tr className='history__value' key={el.id}>
                      <td>{ShipmentState[el.newState]}</td>
                      <td>
                        {user.role > 1 ? <input className='history__value' onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { changeDate(e.target.value, el.shipmentNumber, el.newState) }} defaultValue={getDataValueInput(el.editDate)} type='datetime-local' /> : el.editDate}
                      </td>
                    </tr>
                  ))}
                  {getMissingLines(history.length, history[0].shipmentNumber, true).map((el) => <MissingLine key={el.newState} shipmentNumber={el.shipmentNumber} isReadOnly={user.role > 1 ? el.isReadOnly : true} newState={el.newState} changeValue={changeDate} />)}
                </tbody>
              </table></div> : 'Истории нет'}
              </>}
            </div>                        
            }
          </div>
        </Modal>
      </div>
    </>
  );
}

export default WareShipmentPage;
