import { stageList } from '../../consts'
import DropdownPagination from '../dropdown-pagination/dropdown-pagination'
import './pagination-block.css'

type PaginationBlockProps = {
  onChangeSelectedValue: (value: number) => void
  children: JSX.Element
  activeStage: number | null
  countRows: number
  getTotalStages: () => number
  changeActiveStage: (toStage: number) => void
}

export default function PaginationBlock({ children, onChangeSelectedValue, activeStage, countRows, getTotalStages, changeActiveStage }: PaginationBlockProps) {
  const getActivePaginationItems = (currentPage: number, totalPages: number, maxPagesToShow: number) => {
    let paginationItems = [];

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(i);
      }
    } else {
      let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
      let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

      if (endPage - startPage < maxPagesToShow - 1) {
        startPage = Math.max(1, endPage - maxPagesToShow + 1);
      }

      if (startPage > 1) {
        paginationItems.push(1);
        if (startPage > 2) {
          paginationItems.push('...');
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        paginationItems.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          paginationItems.push('...');
        }
        paginationItems.push(totalPages);
      }
    }
    return paginationItems;
  }
  return (
    <>
      {children}
      <div className='pagination__content centerFlex'>
        <div className='countRows__container'>
          <DropdownPagination selectedValue={countRows} list={stageList} changeSelectedValue={onChangeSelectedValue} />
        </div>
        {activeStage ?
          <div className='pagination__container centerFlex'>
            <div className='pagination__block'>
              <svg onClick={() => { activeStage !== 1 && changeActiveStage(activeStage - 2) }} className='pagination__prevButton pointer' width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1L2 7.82927L9 15" stroke="#0042E4" strokeWidth="2" />
              </svg>
              {getActivePaginationItems(activeStage - 1, getTotalStages(), 3).map((el, index) => (
                <div onClick={() => { typeof el !== 'string' && changeActiveStage(el - 1) }} className={`pagination__point centerFlex ${el !== '...' ? 'pointer' : 'default'} ${activeStage === el ? 'pagination__pointActive' : ''}`} key={index}>{el}</div>))}
              <svg onClick={() => { activeStage !== getTotalStages() && changeActiveStage(activeStage) }} className='pagination__toButton pointer' width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L8 7.82927L1 15" stroke="#0042E4" strokeWidth="2" />
              </svg>
            </div>
          </div>
        :
          <div className='pagination__container centerFlex'>
            <div className='pagination__block'>
              <svg className='pagination__prevButton pointer' width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 1L2 7.82927L9 15" stroke="#0042E4" strokeWidth="2" />
              </svg>
                <div className={`pagination__point centerFlex ${'default'} ${'pagination__pointActive'}`}>{1}</div>
              <svg className='pagination__toButton pointer' width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L8 7.82927L1 15" stroke="#0042E4" strokeWidth="2" />
              </svg>
            </div>
          </div>
        }
      </div>
    </>
  )
}