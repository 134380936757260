import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './modal-window.css';
import CustomSlider from '../slider/custom-slider';
import { Product } from '../../types/product-model';
import { formatNumber, formatString, getCurrentDate, getCurrentDateForInputWithoutTime, getDateForInput } from '../../utils';
import CustomSelect from './custom-select';
import { AddProducts } from '../../types/shipment-model';

interface CreateProductModalProps {
  isOpen: boolean;
  title: string;
  btnText: string;
  fields: { name: string, type: string, backName: string, value?: string | number, required?: boolean, options?: string[] | Product[] }[];
  children?: JSX.Element | undefined;
  onRequestClose: () => void;
  checkFields: (fields: { [key: string]: string | boolean | number | AddProducts[] }) => boolean;
  onSubmit: Function;
  onError?:(error: string) => void
}

function ModalWindow({ isOpen, onRequestClose, onSubmit, fields, title, btnText, checkFields, children, onError }: CreateProductModalProps) {
  let newFields: { [key: string]: string | boolean | number | AddProducts[] } = {}
  for (let i in fields) {
    switch (fields[i].type) {
      case 'checkbox':
        if (typeof fields[i].value === 'number') {
          newFields[fields[i].backName] = fields[i].value as unknown as string
        } else {
          newFields[fields[i].backName] = false
        }
        break
      case 'select':
        if (typeof fields[i].value === 'number') {
          newFields[fields[i].backName] = fields[i].value as number
        } else {
          newFields[fields[i].backName] = 0
        }
        break
      case 'number':
        if (typeof fields[i].value === 'number') {
          newFields[fields[i].backName] = fields[i].value as number
        } else {
          newFields[fields[i].backName] = 0
        }
        break
      case 'text':
        if (typeof fields[i].value === 'string') {
          newFields[fields[i].backName] = fields[i].value as string
        } else {
          newFields[fields[i].backName] = ''
        }
        break
      case 'datetime-local':
        if(fields[i].value){
          newFields[fields[i].backName] = fields[i].value as string
        } else{
          newFields[fields[i].backName] = getCurrentDate()
        }
        break
      case 'date':
        if(fields[i].value){
          newFields[fields[i].backName] = fields[i].value as string
        } else{
          newFields[fields[i].backName] = getCurrentDateForInputWithoutTime()
        }
        break
      case 'table':
        if(fields[i].value){
          newFields[fields[i].backName] = fields[i].value as string
        } else{
          newFields[fields[i].backName] = []
        }
        break
    }
  }
  const [inputs, setInputs] = useState(newFields)

  function isNotStringBooleanNumber(value: any): value is AddProducts[]  {
    return typeof value !== 'string' && typeof value !== 'boolean' && typeof value !== 'number';
  }

  const checkForNullTable = () => {
    for (let i in inputs){
      let point = inputs[i]
      if (isNotStringBooleanNumber(point)){
        for (let j in point){
          if (point[j].quantity === 0){
            return false
          }
        }
      }
    }
    return true
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (checkFields(inputs)) {
      if (checkForNullTable()) {
        onSubmit(inputs);
        for (let i in fields) {
          switch (fields[i].type) {
            case 'checkbox':
              newFields[fields[i].backName] = false
              break
            case 'select':
              newFields[fields[i].backName] = 0
              break
            case 'number':
              if (typeof fields[i].value === 'number') {
                newFields[fields[i].backName] = fields[i].value as number
              } else {
                newFields[fields[i].backName] = 0
              }
              break
            case 'text':
              newFields[fields[i].backName] = ''
              break
            case 'datetime-local':
              newFields[fields[i].backName] = getCurrentDate()
              break
            case 'date':
              newFields[fields[i].backName] = getCurrentDateForInputWithoutTime()
              break
            case 'table':
              newFields[fields[i].backName] = []
              break
          }
        }
        setInputs(newFields)
        children || onRequestClose();
      } else {
        if (onError){
          onError('количество товара в таблице должно быть больше 0')
        }
      }
    }
  };

  const changeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string, type: string) => {
    switch (type) {
      case 'number':
        setInputs({ ...inputs, [name]: formatString(e.target.value) })
        return
      case 'select':
        setInputs({ ...inputs, [name]: Number(e.target.value) - 1 })
        return
      case 'text':
        setInputs({ ...inputs, [name]: e.target.value })
        return
      case 'datetime-local':
        setInputs({ ...inputs, [name]: e.target.value + ':00.000Z' })
        return
      case 'date':
        setInputs({ ...inputs, [name]: e.target.value.slice(0, 10) })
    }
  }

  const changeCheckbox = (initialState: boolean, name: string) => {
    initialState ? setInputs({ ...inputs, [name]: false }) : setInputs({ ...inputs, [name]: true })
  }

  const changeSelect = (value: number | string, backName: string) => {
    setInputs({ ...inputs, [backName]: value })
  }
  
  const onCloseModal = () => {
    for (let i in fields) {
      switch (fields[i].type) {
        case 'checkbox':
          newFields[fields[i].backName] = false
          break
        case 'select':
          newFields[fields[i].backName] = 0
          break
        case 'number':
          if (typeof fields[i].value === 'number') {
            newFields[fields[i].backName] = fields[i].value as number
          } else {
            newFields[fields[i].backName] = 0
          }
          break
        case 'text':
          newFields[fields[i].backName] = ''
          break
        case 'datetime-local':
          newFields[fields[i].backName] = getCurrentDate()
          break
        case 'date':
          newFields[fields[i].backName] = getCurrentDateForInputWithoutTime()
          break
        case 'table':
          newFields[fields[i].backName] = []
          break
      }
    }
    setInputs(newFields)
    onRequestClose()
  }

  const getValueInput = (type: 'number' | 'datetime-local' | 'text' | 'date', backName: string) => {
    switch (type){
      case 'number':
        return formatNumber(inputs[backName] as number)
      case 'text':
        return inputs[backName] as string
      case 'date':
        return inputs[backName] as string
      case 'datetime-local':
        return getDateForInput(inputs[backName] as string)
    }
  }

  useEffect(()=>{
    let newFields: { [key: string]: string | boolean | number | AddProducts[] } = {}
    for (let i in fields) {
      switch (fields[i].type) {
        case 'checkbox':
          if (typeof fields[i].value === 'number') {
            newFields[fields[i].backName] = fields[i].value as unknown as string
          } else {
            newFields[fields[i].backName] = false
          }
          break
        case 'select':
          if (typeof fields[i].value === 'number') {
            newFields[fields[i].backName] = fields[i].value as number
          } else {
            newFields[fields[i].backName] = 0
          }
          break
        case 'number':
          if (typeof fields[i].value === 'number') {
            newFields[fields[i].backName] = fields[i].value as number
          } else {
            newFields[fields[i].backName] = 0
          }
          break
        case 'text':
          if (typeof fields[i].value === 'string') {
            newFields[fields[i].backName] = fields[i].value as string
          } else {
            newFields[fields[i].backName] = ''
          }
          break
        case 'datetime-local':
          if(fields[i].value){
            newFields[fields[i].backName] = fields[i].value as string
          } else{
            newFields[fields[i].backName] = getCurrentDate()
          }
          break
        case 'date':
          if(fields[i].value){
            newFields[fields[i].backName] = fields[i].value as string
          } else{
            newFields[fields[i].backName] = getCurrentDateForInputWithoutTime()
          }
          break
        case 'table':
          if(fields[i].value){
            newFields[fields[i].backName] = fields[i].value as string
          } else{
            newFields[fields[i].backName] = []
          }
          break
      }
    }
    setInputs(newFields)
  }, [fields])

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onCloseModal}
      contentLabel={title}
      className="modal"
      overlayClassName="modal-overlay"
    >
      <div className="modal-content">
        <div className='modal__firstLine'>
          <h2>{title}</h2>
          <div className="close__button" onClick={onCloseModal}>
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(0.719608 0.69438 -0.719608 0.69438 0.392899 2.15039)" stroke="black" strokeWidth="2" />
              <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(-0.719608 0.69438 -0.719608 -0.69438 21.6071 1)" stroke="black" strokeWidth="2" />
            </svg>
          </div>
        </div>
        <form onSubmit={handleSubmit} className='modal__form'>
          {fields.map((field) => (
            <div key={field.name} className='close__inputBlock'>
              {field.type === 'select' &&
                <>
                  <div className='close__inputLabel'>
                    {field.name}
                    {(field.required === true || field.required === undefined) && <span className='close__inputRequired'>*</span>}
                  </div>
                  {
                    <CustomSelect changeValue={changeSelect} options={field.options} name={field.backName} />
                  }
                </>
              }
              {field.type === 'checkbox' &&
                <div className='close__inputLabelCheckbox'>
                  <span>{field.name}:</span>
                  <input
                    className='close__inputCheckbox'
                    type={field.type}
                    onChange={(e) => { changeInput(e, field.backName, field.type) }}
                  />
                  <CustomSlider isActive={inputs[field.backName] as boolean} changeCheckbox={changeCheckbox} name={field.backName} />
                </div>}
              {(field.type === 'number' || field.type === 'datetime-local' || field.type === 'text' || field.type === 'date') &&
                <>
                  <div className='close__inputLabel'>
                    <span>{field.name}:</span>
                    {(field.required === true || field.required === undefined) && <span className='close__inputRequired'>*</span>}
                  </div>
                  <input
                    className='close__input'
                    type={field.type === 'number' ? 'text' : field.type}
                    value={getValueInput(field.type, field.backName)}
                    onChange={(e) => { changeInput(e, field.backName, field.type) }}
                    required={field.required !== undefined ? field.required : true}
                  />
                </>
              }
            </div>
          ))}
          <button className='submit__button' type="submit">{btnText}</button>
          {children}
        </form>
      </div>
    </Modal>
  );
}

export default ModalWindow;

