import { TableSelling } from "../selling-model";

export const SellingTableNames:{ [K in keyof TableSelling]: string } = {
  id: 'ID',
  organizationName: 'Организация',
  shipmentNumber: "Номер поставки",
  productBarcode: "Код продукта",
  quantity: "Количество",
  marketplaceType: "Маркетплейс",
  soldAt: "Дата продажи",
}