import '../filter-table.css'
import { ChildTableShipment } from '../../../types/shipment-model'
import { MarketShipmentTableNames, ShipmentState } from '../../../types/enums/shipment-type'
import { formatNumber, getKeyByValue } from '../../../utils'
import { SortData } from '../../../types/main'
import HeaderPoint from '../header-point'
import DetailsBlock from '../details-block/details-block'
import { useEffect, useState } from 'react'
import { getAllMarketShipment } from '../../../utils/shipment'
import { MarketProduct } from '../../../types/product-model'
import TableFullBlock from '../table-full-block/table-full-block'
import { getActiveFilter } from '../../../utils/filter-table'

type WareShipmnentFilterTableProps = {
  content: ChildTableShipment[],
  getPointInfo: (number: string) => void
  onDeleteShipment: (shipmentNumber: string) => void
  onFilter: (data?: SortData) => void
  onChangeShipment: (e: React.MouseEvent<HTMLOrSVGElement>, shipment: ChildTableShipment) => void
  activeFilter: SortData
  isEdit: boolean
}

export default function MarketShipmnentFilterTable({ content, getPointInfo, onDeleteShipment, onFilter, onChangeShipment, activeFilter, isEdit }: WareShipmnentFilterTableProps) {
  const tableHeader = MarketShipmentTableNames
  const [allFilterNames, setAllFilterNames] = useState<{ [K in keyof ChildTableShipment]: (string | number | boolean)[] }>({
    number: [],
    createdAt: [],
    description: [],
    products: [],
    marketplaceType: [],
    warehouseName: [],
    state: []
  })


  const getAllNamesByField = (field: keyof ChildTableShipment) => {
    if (allFilterNames) {
      return allFilterNames[field]
    }
    return []
  }

  const getAllNames = async () => {
    let newFilterNames: { [K in keyof ChildTableShipment]: (string | number | boolean)[] } = JSON.parse(JSON.stringify(allFilterNames))
    if (newFilterNames.createdAt.length !== 0) {
      return
    }
    const shipments = await getAllMarketShipment()
    /*     for (let shipment of shipments){
          for (let key in shipment){
            const shipmentKey = key as keyof ParentTableShipment;
            newFilterNames[shipmentKey].push(shipment[shipmentKey])
          }
        } */
    if (!shipments){
      return
    }
    Object.values(shipments).forEach(shipment => {
      newFilterNames.number.push(shipment.number);
      newFilterNames.createdAt.push(shipment.createdAt);
      newFilterNames.description.push(shipment.description);
      newFilterNames.products.push(shipment.products.join(', '))
      newFilterNames.warehouseName.push(shipment.warehouseName)
      newFilterNames.state.push(ShipmentState[shipment.state as unknown as number]);
      newFilterNames.marketplaceType.push(shipment.marketplaceType)
    });
    setAllFilterNames(newFilterNames)
  }

  useEffect(() => {
    getAllNames()
  }, [])

  const changeShipment = (index: number) => {
    return
  }

  const deleteShipment = (index: number) => {
    onDeleteShipment(content[index].number)
  }

  const changeFilterContent = async (data: SortData | null) => {
    if (!data) {
      onFilter()
    } else {
      onFilter(data)
    }
  }

  const isProduct = (value: any): value is MarketProduct[] => {
    if (Array.isArray(value) && value.length !== 0){
      return typeof value[0].productBarcode === 'number'
    }
    return false
  }

  const getProductText = (products: MarketProduct[]) => {
    let fullResult:string[] = []
    let briefResult: string = ''
    products.forEach((product)=>{return briefResult += `${product.productName}, `})
    briefResult = briefResult.slice(0, -2)
    products.forEach((product)=>{return fullResult.push(product.productName)})
    return <TableFullBlock title='Товары в поставке' fullContent={products} briefContent={briefResult}/>
  }

  const getText = (el: string | number | boolean | MarketProduct[], key: keyof ChildTableShipment) => {
    if (key === 'description' && typeof el === 'string' && el.length !== 0){
      return <TableFullBlock title='Описание поставки' fullContent={el} briefContent={el}/>
    }
    if (typeof el === 'boolean')
      return el ? 'Да' : 'Нет'
    if (typeof el === 'number'){
      return formatNumber(el)
    }
    if (Array.isArray(el)){
      return el.join(', ')
    }
    return el
  }

  const isBlockFilter = (columnName: string) => {
    switch (columnName){
      case 'Номер поставки': return true
      case 'Описание': return true
      case 'Товары в поставке': return true
      default: return false
    }
  }

  return (
    <div>
      <div className='scrolltable__container'>
        <div className={`table__container ${content.length < 5 && content.length !== 0  ? 'padding__table' : ''}`}>
          <table className='table borderTable'>
            <thead className='thead'>
              <tr className="table__tr">
                {Object.values(tableHeader).map((el, index) => (
                  <HeaderPoint 
                  blockedFilter={content.length !== 0 ? isBlockFilter(el) : true} 
                  allNames={getAllNamesByField(getKeyByValue(tableHeader, el) as keyof ChildTableShipment)} 
                  filterData={getActiveFilter(activeFilter, getKeyByValue(tableHeader, el) as string)} 
                  changeFilterContent={changeFilterContent} columnName={getKeyByValue(tableHeader, el) as string} name={el} key={`${el}--${index}`} />))}
              </tr>
            </thead>
            <tbody className='tbody'>
              {content.length !== 0 ? content.map((el, indexLen) => (
                <tr key={el.number} className='body__tr'>
                  {Object.keys(el).map((key, index) => (
                    <td style={index + 1 === Object.keys(el).length ? {cursor: 'pointer'} : {}} className={`table__td`} key={key}>
                      <div className='table__content' onClick={()=>{index + 1 === Object.keys(el).length && getPointInfo(el.number)}}>
                        {index === 0 &&
                        <svg style={{cursor: 'pointer'}} onClick={(e)=>{onChangeShipment(e, el)}} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="25" height="25" rx="6" fill="#0042E4"/>
                        <path d="M6 18.2193H18.6389M6 18.2193V15.0833L12.3194 8.81144M6 18.2193H9.15972L15.4792 11.9474M12.3194 8.81144L14.5854 6.56243L14.5869 6.5611C14.8987 6.25152 15.055 6.09645 15.2351 6.03837C15.3938 5.98721 15.5646 5.98721 15.7233 6.03837C15.9033 6.09641 16.0594 6.2513 16.3708 6.56044L17.7452 7.92443C18.058 8.2349 18.2145 8.39021 18.2731 8.56922C18.3247 8.72668 18.3246 8.89629 18.2731 9.05375C18.2145 9.23263 18.0583 9.3877 17.7459 9.69773L17.7452 9.6984L15.4792 11.9474M12.3194 8.81144L15.4792 11.9474" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>}
                        {isProduct(el[key as keyof ChildTableShipment]) ? getProductText(el[key as keyof ChildTableShipment] as MarketProduct[]) : getText(el[key as keyof ChildTableShipment], key as keyof ChildTableShipment)}
                        {index + 1 === Object.keys(el).length && isEdit &&
                          <DetailsBlock displayEdit={false} changeAction={changeShipment} deleteAction={deleteShipment} index={indexLen} />
                        }
                      </div>
                    </td>))}
                </tr>
              ))  : <td colSpan={Object.values(tableHeader).length} className='body__notData'>Нет данных</td>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}