import { useState } from 'react'
import './dropdown-menu.css'

type DropdownPointProps = {
  content: { src: string, hoverSrc: string, text: string, action: () => void, newSrc?: string, newHoverSrc?:string, isNew?:boolean }
}

export default function DropdownPoint({content}: DropdownPointProps) {

  const [isSvgActive, setIsSvgActive] = useState(false)

  const getSrc = () => {
    if (content.isNew !== undefined && content.newSrc && content.newHoverSrc){
      if (content.isNew){
        return isSvgActive ? content.newHoverSrc : content.newSrc
      }
    }
    return isSvgActive ? content.hoverSrc : content.src
  }
  
  return (
    <div onMouseOver={()=>{setIsSvgActive(true)}} onMouseLeave={()=>{setIsSvgActive(false)}} className='dropdownMenu__point' onClick={content.action}>
      <img src={getSrc()} className='dropdownMenu__svg' alt='' />
      <span className='dropdownMenu__text'>{content.text}</span>
    </div>
  )
}