import './account-block.css';
import { useNavigate } from 'react-router-dom';
import DropdownMenu from '../dropdown-menu/dropdown-menu';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getInvitationsLength, getStateActiveOrganization, getSyncErrorsLength, getTasksLength } from '../../store/organization-process/selectors';
import { getActiveOrganizationAction } from '../../store/api-actions/organization-api-actions';
import { getUserInfo } from '../../store/user-process/selectors';
import { logoutAction } from '../../store/user-process/user-action';
import { changeStateActiveOrganization } from '../../store/organization-process/organization-actions';
import { getOrganization } from '../../services/organization';
import useOutsideClick from '../../hooks/useOutsideClick';


export default function AccountBlock() {
  const dispatch = useAppDispatch()
  const activeOrganization = useAppSelector(getStateActiveOrganization)
  const userInfo = useAppSelector(getUserInfo)
  const notificationLength = useAppSelector(getInvitationsLength)
  const tasksLength = useAppSelector(getTasksLength)
  const errorsLength = useAppSelector(getSyncErrorsLength)
  const navigate = useNavigate();

  const [displayDropDown, setDisplayDropDown] = useState(false)

  const dropDown = useRef<HTMLDivElement>(null)

  const signOut = () => {
    dispatch(logoutAction())
    dispatch(changeStateActiveOrganization(null))
    setDisplayDropDown(false)
  }

  const navigateToCabinet = () => {
    navigate('/cabinet')
    setDisplayDropDown(false)
  }

  const navigateToMain = () => {
    navigate('/')
    setDisplayDropDown(false)
  }

  const navigateToWarehouse = () => {
    navigate('/warehouse')
    setDisplayDropDown(false)
  }

  const navigateToNotifications = () => {
    navigate('/notifications')
    setDisplayDropDown(false)
  }

  const navigateToTasks = () => {
    navigate('/tasks')
    setDisplayDropDown(false)
  }

  const navigateToNews = () => {
    navigate('/news')
    setDisplayDropDown(false)
  }

  const navigateToErrors = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation()
    navigate('/errors')
  }

  const dropdownContent = [
    {
      text: 'Личный кабинет',
      src: '/img/account/dropdown-icons/settings.svg',
      hoverSrc: '/img/account/dropdown-icons/hover-settings.svg',
      action: navigateToCabinet
    },
    {
      text: 'Организации',
      src: '/img/account/dropdown-icons/organization.svg',
      hoverSrc: '/img/account/dropdown-icons/hover-organization.svg',
      action: navigateToMain
    },
    {
      text: 'Склады',
      src: '/img/account/dropdown-icons/warehouse.svg',
      hoverSrc: '/img/account/dropdown-icons/hover-warehouse.svg',
      action: navigateToWarehouse
    },
    {
      text: 'Приглашения',
      src: '/img/account/dropdown-icons/invitations.svg',
      hoverSrc: '/img/account/dropdown-icons/hover-invitations.svg',
      action: navigateToNotifications,
      isNew: notificationLength > 0,
      newSrc: 'img/account/dropdown-icons/new-invitations.svg',
      newHoverSrc: 'img/account/dropdown-icons/hover-new-invitations.svg'
    },
    {
      text: 'Фоновые задачи',
      src: '/img/account/dropdown-icons/tasks.svg',
      hoverSrc: '/img/account/dropdown-icons/hover-tasks.svg',
      action: navigateToTasks,
      isNew: tasksLength > 0,
      newSrc: 'img/account/dropdown-icons/new-tasks.svg',
      newHoverSrc: 'img/account/dropdown-icons/hover-new-tasks.svg'
    },
    {
      text: 'Новости',
      src: '/img/account/dropdown-icons/news.svg',
      hoverSrc: '/img/account/dropdown-icons/hover-news.svg',
      action: navigateToNews,
    },
    {
      text: 'Выйти',
      src: '/img/account/dropdown-icons/logout.svg',
      hoverSrc: '/img/account/dropdown-icons/hover-logout.svg',
      action: signOut
    }
  ]

  useOutsideClick(dropDown, () => { setDisplayDropDown(false) })

  useEffect(() => {
    if (getOrganization()) {
      dispatch(getActiveOrganizationAction())
    }
  }, [dispatch])

  return (
    <>
      <div className='accountBlock__container'>
        <div className='accountBlock__organization'>
          <span className='accountBlock__titleValue'>{activeOrganization ? activeOrganization.name : 'Не выбрана'}</span>
        </div>
        <div className='accountInfo' ref={dropDown} onClick={() => { setDisplayDropDown(!displayDropDown) }}>
          <span className='accountText'>{userInfo?.name ? userInfo.name : userInfo?.phone}</span>
          <div className='accountInfo__notifications'>
            <svg onClick={(e)=>{navigateToErrors(e)}} style={{ marginRight: '26px' }} width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.7528 8.72118C17.7528 6.93861 17.0889 5.22905 15.9072 3.96859C14.7255 2.70812 13.1228 2 11.4517 2C9.7805 2 8.17779 2.70812 6.9961 3.96859C5.81441 5.22905 5.15055 6.93861 5.15055 8.72118C5.15055 16.5626 2 18.8029 2 18.8029H20.9033C20.9033 18.8029 17.7528 16.5626 17.7528 8.72118Z" stroke="#1A1B1C" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14 20C13.746 20.6083 13.3814 21.1133 12.9425 21.4641C12.5038 21.8152 12.0064 22 11.5 22C10.9936 22 10.4962 21.8152 10.0575 21.4641C9.61878 21.1133 9.25405 20.6083 9 20" stroke="#1A1B1C" strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            {errorsLength > 0 && <div className='accountInfo__newNot'></div>}
          </div>
          <div className='accountInfo__notifications'>
            <img alt='account' src='/img/account/account-icon.svg' />
            {notificationLength > 0 && <div className='accountInfo__newError'></div>}
          </div>
          {displayDropDown && <div className='account__dropdown'>
            <DropdownMenu content={dropdownContent} />
          </div>}
        </div>
      </div>
    </>
  )
}