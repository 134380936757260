import '../modal-window.css'
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import CustomSlider from '../../slider/custom-slider';
import { Product, Remains, TableRemains } from '../../../types/product-model';
import { formatNumber, formatString, getCurrentDate, getCurrentDateForInputWithoutTime, getDateForInput, getKeyByValue } from '../../../utils';
import CustomSelect from '.././custom-select';
import AddTable from '../add-table/add-table';
import { AddProducts } from '../../../types/shipment-model';
import { Warehouse } from '../../../types/warehouse-model';
import ModalComponent from '../../modal-component/modal-component';
import PaginationBlock from '../../pagination-block/pagination-block';
import { SortData } from '../../../types/main';
import axios, { PagesURl } from '../../../services/api'
import { stageList } from '../../../consts';
import { toast } from 'react-toastify';
import { getActiveFilter, getTotalStages } from '../../../utils/filter-table';
import HeaderPoint from '../../filter-table/header-point';
import { AddWarehouseProductsNames } from '../../../types/enums/product-type';
import { getAllRemainsWarehouse } from '../../../utils/remains';

interface CreateProductModalProps {
  isOpen: boolean;
  title: string;
  btnText: string;
  fields: { name: string, type: string, backName: string, value?: string | number | AddProducts[] | Warehouse , required?: boolean, options?: string[] | Product[] | Warehouse[] }[];
  children?: JSX.Element | undefined;
  onRequestClose: () => void;
  checkFields: (fields: { [key: string]: string | boolean | number | AddProducts[] }) => boolean;
  onSubmit: Function;
  onError?: (error: string) => void
}

export default function CreateWareShipmentModalWindow({ isOpen, onRequestClose, onSubmit, fields, title, btnText, checkFields, children, onError }: CreateProductModalProps) {
  const [addProducts, setAddProducts] = useState(false)
  let newFields: { [key: string]: string | boolean | number | AddProducts[] } = {}
  for (let i in fields) {
    switch (fields[i].type) {
      case 'checkbox':
        newFields[fields[i].backName] = false
        break
      case 'select':
        if (fields[i].value) {
          newFields[fields[i].backName] = fields[i].value as string
        } else {
          newFields[fields[i].backName] = 0
        }
        break
      case 'number':
        if (typeof fields[i].value === 'number') {
          newFields[fields[i].backName] = fields[i].value as number
        } else {
          newFields[fields[i].backName] = 0
        }
        break
      case 'text':
        if (typeof fields[i].value === 'string') {
          newFields[fields[i].backName] = fields[i].value as string
        } else {
          newFields[fields[i].backName] = ''
        }
        break
      case 'datetime-local':
        newFields[fields[i].backName] = getCurrentDate()
        break
      case 'date':
        newFields[fields[i].backName] = getCurrentDateForInputWithoutTime()
        break
      case 'table':
        if (fields[i].value){
          newFields[fields[i].backName] = fields[i].value as string
        }else{
          newFields[fields[i].backName] = []
        }
        break
    }
  }
  const [inputs, setInputs] = useState(newFields)

  function isNotStringBooleanNumber(value: any): value is AddProducts[] {
    return typeof value !== 'string' && typeof value !== 'boolean' && typeof value !== 'number';
  }

  const checkForNullTable = () => {
    for (let i in inputs) {
      let point = inputs[i]
      if (isNotStringBooleanNumber(point)) {
        for (let j in point) {
          if (point[j].quantity === 0) {
            return false
          }
        }
      }
    }
    return true
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (checkFields(inputs)) {
      if (checkForNullTable()) {
        onSubmit(inputs);
        for (let i in fields) {
          switch (fields[i].type) {
            case 'checkbox':
              newFields[fields[i].backName] = false
              break
            case 'select':
              if (fields[i].value) {
                newFields[fields[i].backName] = fields[i].value as string
              } else {
                newFields[fields[i].backName] = 0
              }
              break
            case 'number':
              if (typeof fields[i].value === 'number') {
                newFields[fields[i].backName] = fields[i].value as number
              } else {
                newFields[fields[i].backName] = 0
              }
              break
            case 'text':
              newFields[fields[i].backName] = ''
              break
            case 'datetime-local':
              newFields[fields[i].backName] = getCurrentDate()
              break
            case 'date':
              newFields[fields[i].backName] = getCurrentDateForInputWithoutTime()
              break
            case 'table':
              newFields[fields[i].backName] = []
              break
          }
        }
        setInputs(newFields)
        children || onRequestClose();
      } else {
        if (onError) {
          onError('количество товара в таблице должно быть больше 0')
        }
      }
    }
  };

  const changeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string, type: string) => {
    switch (type) {
      case 'number':
        setInputs({ ...inputs, [name]: formatString(e.target.value) })
        return
      case 'select':
        setInputs({ ...inputs, [name]: Number(e.target.value) - 1 })
        return
      case 'text':
        setInputs({ ...inputs, [name]: e.target.value })
        return
      case 'datetime-local':
        setInputs({ ...inputs, [name]: e.target.value + ':00.000Z' })
        return
      case 'date':
        setInputs({ ...inputs, [name]: e.target.value.slice(0, 10) })
    }
  }

  const changeCheckbox = (initialState: boolean, name: string) => {
    initialState ? setInputs({ ...inputs, [name]: false }) : setInputs({ ...inputs, [name]: true })
  }

  const changeSelect = (value: number | string, backName: string) => {
    setInputs({ ...inputs, [backName]: value })
  }

  const onCloseModal = () => {
    for (let i in fields) {
      switch (fields[i].type) {
        case 'checkbox':
          newFields[fields[i].backName] = false
          break
        case 'select':
          newFields[fields[i].backName] = 0
          break
        case 'number':
          if (typeof fields[i].value === 'number') {
            newFields[fields[i].backName] = fields[i].value as number
          } else {
            newFields[fields[i].backName] = 0
          }
          break
        case 'text':
          newFields[fields[i].backName] = ''
          break
        case 'datetime-local':
          newFields[fields[i].backName] = getCurrentDate()
          break
        case 'date':
          newFields[fields[i].backName] = getCurrentDateForInputWithoutTime()
          break
        case 'table':
          newFields[fields[i].backName] = []
          break
      }
    }
    setInputs(newFields)
    onRequestClose()
  }

  const getValueInput = (type: 'number' | 'datetime-local' | 'text' | 'date', backName: string) => {
    switch (type) {
      case 'number':
        return formatNumber(inputs[backName] as number)
      case 'text':
        return inputs[backName] as string
      case 'date':
        return inputs[backName] as string
      case 'datetime-local':
        return getDateForInput(inputs[backName] as string)
    }
  }

  const onSubmitProducts = (products:AddProducts[]) => {
    setAddProducts(false)
    setInputs({...inputs, products: products})
  }

  const closeAddProducts = () => {
    setAddProducts(false)
  }

  useEffect(()=>{
    let newFields: { [key: string]: string | boolean | number | AddProducts[] } = {}
    for (let i in fields) {
      switch (fields[i].type) {
        case 'checkbox':
          newFields[fields[i].backName] = false
          break
        case 'select':
          if (fields[i].value) {
            typeof fields[i].value === 'number' ? fields[i].value?.toString() : fields[i].value as string
          } else {
            newFields[fields[i].backName] = 0
          }
          break
        case 'number':
          if (typeof fields[i].value === 'number') {
            newFields[fields[i].backName] = fields[i].value as number
          } else {
            newFields[fields[i].backName] = 0
          }
          break
        case 'text':
          if (typeof fields[i].value === 'string') {
            newFields[fields[i].backName] = fields[i].value as string
          } else {
            newFields[fields[i].backName] = ''
          }
          break
        case 'datetime-local':
          newFields[fields[i].backName] = getCurrentDate()
          break
        case 'date':
          newFields[fields[i].backName] = getCurrentDateForInputWithoutTime()
          break
        case 'table':
          if (fields[i].value){
            newFields[fields[i].backName] = fields[i].value as string
          }else{
            newFields[fields[i].backName] = []
          }
          break
      }
    }
    setInputs(newFields)
  }, [fields])
  return (
    <>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={onCloseModal}
        contentLabel={title}
        className="modal"
        overlayClassName="modal-overlay"
      >
        <div className="modal-content">
          <div className='modal__firstLine'>
            <h2>{title}</h2>
            <div className="close__button" onClick={onCloseModal}>
              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(0.719608 0.69438 -0.719608 0.69438 0.392899 2.15039)" stroke="black" strokeWidth="2" />
                <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(-0.719608 0.69438 -0.719608 -0.69438 21.6071 1)" stroke="black" strokeWidth="2" />
              </svg>
            </div>
          </div>
          <form onSubmit={handleSubmit} className='modal__form'>
            {fields.map((field) => (
              <div key={field.name} className='close__inputBlock'>
                {field.type === 'select' &&
                  <>
                    <div className='close__inputLabel'>
                      {field.name}
                      {(field.required === true || field.required === undefined) && <span className='close__inputRequired'>*</span>}
                    </div>
                    {
                      <CustomSelect selectedOpt={field.value as (string | Product | Warehouse)} changeValue={changeSelect} options={field.options} name={field.backName} />
                    }
                  </>
                }
                {field.type === 'checkbox' &&
                  <div className='close__inputLabelCheckbox'>
                    <span>{field.name}:</span>
                    <input
                      className='close__inputCheckbox'
                      type={field.type}
                      onChange={(e) => { changeInput(e, field.backName, field.type) }}
                    />
                    <CustomSlider isActive={inputs[field.backName] as boolean} changeCheckbox={changeCheckbox} name={field.backName} />
                  </div>}
                {(field.type === 'number' || field.type === 'datetime-local' || field.type === 'text' || field.type === 'date') &&
                  <>
                    <div className='close__inputLabel'>
                      <span>{field.name}:</span>
                      {(field.required === true || field.required === undefined) && <span className='close__inputRequired'>*</span>}
                    </div>
                    <input
                      className='close__input'
                      type={field.type === 'number' ? 'text' : field.type}
                      value={getValueInput(field.type, field.backName)}
                      onChange={(e) => { changeInput(e, field.backName, field.type) }}
                      required={field.required !== undefined ? field.required : true}
                    />
                  </>
                }
                {field.type === 'table' &&  <AddTable content={inputs['products'] as AddProducts[]} onAddProducts={()=>{setAddProducts(true)}}/>}
              </div>
            ))}
            <button className='submit__button' type="submit">{btnText}</button>
            {children}
          </form>
        </div>
      </Modal>
      {addProducts && <ModalComponent warning={{title:'Отменить изменения?'}} style={{width: '92%'}} title='Добавить товары' onClose={closeAddProducts} children={<AddProductsModal content={inputs['products']} onSubmitProducts={onSubmitProducts}/>} />}
    </>
  );
}

type AddProductsTableProps = {
  content: TableRemains[]
  activeFilter: SortData
  onFilter: (data?: SortData) => void
  onSubmitProducts: (products: AddProducts[]) => void;
}

function AddProductsTable({content, activeFilter, onFilter, onSubmitProducts}:AddProductsTableProps) {
  const tableHeader = AddWarehouseProductsNames
  const [tableContent, setTableContent] = useState(content)

  const [allFilterNames, setAllFilterNames] = useState<{ [K in keyof Remains]: (string | number | boolean)[] }>({
    name: [],
    articleWB: [],
    articleOzon: [],
    barcode: [],
    vendorCode: [],
    warehousesInfo: [],
    marketPlaceCount: [],
    photos: []
  })

  const changeFilterContent = async (data: SortData | null) => {
    if (!data) {
      onFilter()
    } else {
      onFilter(data)
    }
  }

  const addProducts = () => {
    let result:AddProducts[] = []
    for (let product of tableContent){
      if (product.quantity !== 0){
        result.push({
          productBarcode: product.barcode,
          quantity: product.quantity,
          netCost: product.netCost
        })
      }
    }
    onSubmitProducts(result)
  }

  const changeQuantity = (value: string, index: number) => {
    value = value.replace(' ', '')
    let newContent = tableContent.slice()
    let intValue = parseInt(value)
    newContent[index].quantity = intValue ? intValue : 0
    setTableContent(newContent)
  }

  const changeNetCost = (value: string, index: number) => {
    value = value.replace(' ', '')
    let newContent = tableContent.slice()
    let intValue = parseInt(value)
    newContent[index].netCost = intValue ? intValue : 0
    setTableContent(newContent)
  }

  const getTotal = (index: number) => {
    if (tableContent[index].quantity !== 0 && tableContent[index].netCost !== 0){
      return tableContent[index].quantity * tableContent[index].netCost
    }
    return 0
  }

  const getAllFilterNames = (field: keyof Remains) => {
    if (allFilterNames) {
      return allFilterNames[field]
    }
    return []
  }

  const getAllNames = async () => {
    const products = await getAllRemainsWarehouse();
    let newFilterNames = JSON.parse(JSON.stringify(allFilterNames))
    if (newFilterNames.name.length !== 0) {
      return
    }
    Object.values(products).forEach(product => {
      newFilterNames.name.push(product.name);
      newFilterNames.barcode.push(product.barcode);
      newFilterNames.articleOzon.push(product.articleOzon);
      newFilterNames.articleWB.push(product.articleWB);
      newFilterNames.vendorCode.push(product.vendorCode);
    });
    setAllFilterNames(newFilterNames)
  }

  useEffect(()=>{
    setTableContent(content)
  }, [content])
  useEffect(()=>{
    getAllNames()
  },[])

  const getAddProductsText = (value: string | number | ({name: string, quantity: number}[]) | ({marketplaceType: "WB" | "Ozon",
    url: string
  }[]), key: keyof TableRemains, name: string) => {

    function checkIsWareHouseBlock(value: any): value is ({name: string, quantity: number}[]){
      if (!value){
        return false
      }
      if (value.length !== 0){
        if (value[0].name){
          return true
        }
      }
      return false
    }

    if (key === 'name' && !checkIsWareHouseBlock(value) && Array.isArray(value)){
      if (value.length === 0){
        return <><p style={{marginTop: '13px'}}>{name}</p></>
      } else if (value.length === 1){
        return <><img className='table__img' src={value[0].url}/><p style={{marginTop: '13px'}}>{name}</p></>
      } else {
        for (let img of value){
          if (img.marketplaceType === 'WB'){
            return <><img className='table__img' src={img.url}/><p style={{marginTop: '13px'}}>{name}</p></>
          }
        }
      }
    }
    if (typeof value === 'string' || typeof value === 'number'){
      return typeof value === 'number' ? formatNumber(value) : value
    }
    if (checkIsWareHouseBlock(value)){
      return (
        <div className='addTable__warehouseBlock'>
          {value.map((el)=>(
          <div className='addTable__warehouseBlock__point centerFlex'>
            <span className='addTable__warehouseBlock__name'>{el.name}</span>
            <span className='addTable__warehouseBlock__quantity'>{formatNumber(el.quantity)}</span>
          </div>
          ))}
        </div>
      )
    }
  }

  const isBlockFilter = (columnName: string) => {
    switch (columnName){
      case 'Количество': return true
      case 'Себестоимость': return true
      case 'Сумма': return true
      case 'Остаток на складах': return true
      default: return false
    }
  }

  return (
    <div>
      <div className='scrolltable__container'>
        <div className={`table__container ${tableContent.length < 2 && tableContent.length !== 0 ? 'padding__table' : ''}`}>
          <table className='table borderTable'>
            <thead className='thead'>
              <tr className="table__tr">
                {Object.values(tableHeader).map((el, index) => (<HeaderPoint blockedFilter={tableContent.length !== 0 ? isBlockFilter(el) : true} allNames={getAllFilterNames(getKeyByValue(tableHeader, el) as keyof Remains)} filterData={getActiveFilter(activeFilter, getKeyByValue(tableHeader, el) as string)} changeFilterContent={changeFilterContent} columnName={getKeyByValue(tableHeader, el) as string} name={el} key={`${el}--${index}`} />))}
              </tr>
            </thead>
            <tbody className='tbody'>
              {tableContent.length !== 0 ? tableContent.map((el, indexLen) => (
                <tr key={`${el.barcode}--${indexLen}`} className='body__tr'>
                  {Object.keys(el).map((key) => (
                    key !== 'photos' && <td className={`table__td ${indexLen === tableContent.length - 1 ? 'last__td' : ''}`} key={key}>
                      <div className='table__content'>
                        {key === 'quantity' && <input onChange={(e) => { changeQuantity(e.target.value, indexLen) }} type='text' className='table__input' value={formatNumber(el[key])} />}
                        {key === 'netCost' && <input onChange={(e) => { changeNetCost(e.target.value, indexLen) }} type='text' className='table__input' value={formatNumber(el[key])} />}
                        {key === 'total' && getTotal(indexLen)}
                        {key !== 'quantity' && key !== 'netCost' && key !== 'total' && getAddProductsText(el[key as keyof TableRemains], key as keyof TableRemains, el.name)}
                      </div>
                    </td>))}
                </tr>
              )) : <td colSpan={Object.values(tableHeader).length} className='body__notData'>Нет данных</td>
              }
            </tbody>
          </table>
        </div>
      </div>
      <button onClick={addProducts} className='tableSubmit__button pointer'>Сохранить</button>
    </div>
  )
}

type AddProductsModalProps = {
  onSubmitProducts: (products: AddProducts[]) => void
  content: string | number | boolean | AddProducts[]
}

function AddProductsModal({onSubmitProducts, content}:AddProductsModalProps) {
  const notify = (message: string) => toast(message);

  const [products, setProducts] = useState<TableRemains[]>([]);
  const [productCount, setProductCount] = useState(0)
  const [activeStage, setActiveStage] = useState<number | null>(null)
  const [countRows, setCountRows] = useState<number>(stageList[0])
  const [activeFilter, setActiveFilter] = useState<SortData>({ take: countRows, skip: 0 })

  const handleFindProducts = async (data: SortData = { take: countRows, skip: 0 }) => {
    try {
      const response = await axios.post<{data:Remains[], totalCount: number}>(PagesURl.REMAINS + '/find', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const products = response.data.data;
      const result:TableRemains[] = []
      if (typeof content === 'string' || typeof content === 'boolean' || typeof content === 'number'){
        return
      }
      for (const product of products) {
        let flag = false
        for (let item of content){
          if (item.productBarcode === product.barcode){
            result.push({
              barcode: product.barcode,
              name: product.name,
              quantity: item.quantity,
              netCost: item.netCost,
              total: item.quantity * item.netCost,
              articleOzon: product.articleOzon,
              articleWB: product.articleWB,
              warehousesInfo:product.warehousesInfo,
              photos: product.photos
            });
            flag = true
          }
        }
        !flag && 
        result.push({
          barcode: product.barcode,
          name: product.name,
          quantity: 0,
          netCost: 0,
          total: 0,
          articleOzon: product.articleOzon,
          articleWB: product.articleWB,
          warehousesInfo:product.warehousesInfo,
          photos: product.photos
        });
      }
      setProducts(result)
      setProductCount(response.data.totalCount)
      setActiveFilter(data)
      if (!activeStage) {
        setActiveStage(response.data.totalCount > data.take ? 1 : null)
      }
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)) {
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  };

  const changeActiveStage = (toStage: number) => {
    let newSortData: SortData = JSON.parse(JSON.stringify(activeFilter))
    newSortData.skip = countRows * toStage
    handleFindProducts(newSortData)
    setActiveStage(toStage + 1)
  }

  const changeSelectedValue = (newValue: number) => {
    setCountRows(newValue)
    handleFindProducts({ take: newValue, skip: 0 })
  }

  useEffect(() => {
    handleFindProducts();
  }, []);

  return (
    <>
      <PaginationBlock
        onChangeSelectedValue={changeSelectedValue}
        activeStage={activeStage}
        getTotalStages={() => { return getTotalStages(productCount, countRows) }}
        countRows={countRows}
        changeActiveStage={changeActiveStage}
        children={<AddProductsTable onSubmitProducts={onSubmitProducts} content={products} activeFilter={activeFilter} onFilter={handleFindProducts}/>}
      />
    </>
  )
}