import { ChangeChildShipment, ChildShipment, HistoryTableChildShipment, HistoryTableShipment, ParentTableShipment } from './../types/shipment-model';
import axios, { PagesURl } from '../services/api';
import { CreateChildShipment, CreateParentShipment, GetHistoryShipment, GetShipment, MissingLineShipment } from "../types/shipment-model";
import { toast } from 'react-toastify';



export const getAllWareShipment = async (): Promise<ParentTableShipment[] | undefined> => {
  try {
    // Отправка запроса на сервер для получения списка товаров
    const response = await axios.post(PagesURl.SHIPMENT + '/parent/find', null, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const shipments = response.data.data;
    return shipments
  } catch (error) {
    console.error('Error:', error);
    toast(error as string)
  }
}

export const getAllMarketShipment = async (): Promise<ChildShipment[] | undefined> => {
  try {
    // Отправка запроса на сервер для получения списка товаров
    const response = await axios.post<{ data: ChildShipment[], totalCount: number }>(PagesURl.SHIPMENT + '/child/find', null, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const shipments = response.data.data;
    return shipments
  } catch (error) {
    console.log('Error:', error);
    toast(error as string)
  }
}



export function checkCreateParentShipment(fields: any): fields is CreateParentShipment {
  return (
    Array.isArray(fields.products) &&
    typeof fields.name === 'string' &&
    typeof fields.createdAt === "string" &&
    typeof fields.logisticCost === 'number' &&
    typeof fields.paymentDeadline === 'string'
  );
}

export function checkCreateChildShipment(fields: any): fields is CreateChildShipment {
  return (
    typeof fields.number === 'string' &&
    Array.isArray(fields.products) &&
    typeof fields.description === 'string' &&
    typeof fields.warehouseId === 'string' &&
    typeof fields.marketplaceType === 'number' &&
    typeof fields.createdAt === "string"
  );
}



export function checkChangeShipment(fields: any): fields is ChangeChildShipment {
  return (
    typeof fields.shipmentNumber === "string" &&
    typeof fields.targetState === "number" &&
    typeof fields.newDate === "string"
  );
}

export function checkGetShipment(fields: any): fields is GetShipment {
  return (
    typeof fields.productId === "string"
  )
}
export function checkGetHistoryShipment(fields: any): fields is GetHistoryShipment {
  return (
    typeof fields.shipmentId === "string"
  )
}

export const getMissingLines = (shipmentLength: number, shipmentNumber: string, isParent: boolean) => {
  let result: MissingLineShipment[] = []
  if (isParent) {
    for (let i = shipmentLength; i < 4; i++) {
      result.push({
        'shipmentNumber': shipmentNumber,
        'isReadOnly': i !== shipmentLength,
        'newState': i
      })
    }
  } else {
    const missingLinesLength = 7 - shipmentLength
    for (let i = 4; i < missingLinesLength + shipmentLength; i++) {
      i + shipmentLength < 7 &&
        result.push({
          'shipmentNumber': shipmentNumber,
          'isReadOnly': i !== 4,
          'newState': i + shipmentLength
        })
    }
  }
  return result
}

export const sortActivityLog = (array: HistoryTableShipment[] | HistoryTableChildShipment[]): HistoryTableShipment[] | HistoryTableChildShipment[] => {
  return array.sort((a, b) => {
    if (a.newState < b.newState) {
      return -1;
    }
    if (a.newState > b.newState) {
      return 1;
    }
    return 0;
  })
}