import { useEffect, useState } from 'react'
import SearchFilter from './filter-blocks/search-filter'
import './filter.css'

type FilterOptionsProps = {
  title: string | JSX.Element
  values: string[]
  changeFilterValue: (newValues: string[]) => void
  isDeletingFilter: boolean
}

export default function FilterOptions({title, values, changeFilterValue, isDeletingFilter}:FilterOptionsProps){
  const [tableNames, setTableNames] = useState<{ name: string, isActive: boolean }[]>(
    values.map((el) => ({ name: el, isActive: true }))
  )

  const changeAllNames = (isAdd: boolean) => {
    let newTableNames = tableNames.fullClone()
    newTableNames.forEach((name)=>(name.isActive = isAdd))
    setTableNames(newTableNames)
    changeFilterValue(getOldValues(newTableNames))
  }

  const changeNames = (name: string, isAdd:boolean) => {
    let newTableNames = tableNames.fullClone()
    for (let tableName of newTableNames){
      if (tableName.name === name){
        tableName.isActive = isAdd
        setTableNames(newTableNames)
        changeFilterValue(getOldValues(newTableNames))
        return
      }
    }
  }

  const getOldValues = (newTableNames: { name: string, isActive: boolean }[]) => {
    let result:string[] = []
    newTableNames.forEach((name)=>{
      name.isActive && result.push(name.name)
    })
    return result
  }

  useEffect(()=>{
    if (isDeletingFilter){
      setTableNames(values.map((el) => ({ name: el, isActive: true })))
    }
  },[isDeletingFilter, values])

  return (
    <div className='filterOptions__container'>
    <h3 className='filterRange__title'>{title}</h3>
    <SearchFilter content={tableNames} changeNames={changeNames} changeAllNames={changeAllNames}/>
    </div>
  )
}