import '../filter-table.css'
import { ParentTableShipment } from '../../../types/shipment-model'
import { ShipmentState, WareShipmentTableNames } from '../../../types/enums/shipment-type'
import HeaderPoint from '../header-point'
import { formatNumber, getKeyByValue } from '../../../utils'
import { SortData } from '../../../types/main'
import DetailsBlock from '../details-block/details-block'
import { useEffect, useState } from 'react'
import { getAllWareShipment } from '../../../utils/shipment'
import ModalWindow from '../../modal-window/modal-window'
import axios, { PagesURl } from '../../../services/api';
import { PayPayment, PaymentActivityLogDto } from '../../../types/payment-model'
import { toast } from 'react-toastify'
import ModalComponent from '../../modal-component/modal-component'
import PaymentHistoryPoint from '../../payment-history-point/payment-history-point'
import { WarehouseProduct } from '../../../types/product-model'
import TableFullBlock from '../table-full-block/table-full-block'
import { getActiveFilter } from '../../../utils/filter-table'

type WareShipmnentFilterTableProps = {
  content: ParentTableShipment[],
  payments: string[]
  getPointInfo: (number: string) => void
  onDeleteShipment: (shipmentNumber: string) => void
  onFilter: (data?: SortData) => void
  onChangeShipment: (e: React.MouseEvent<HTMLOrSVGElement>, shipment: ParentTableShipment) => void
  activeFilter: SortData
  isEdit: boolean
}

export default function WareShipmnentFilterTable({ content, getPointInfo, onDeleteShipment, onFilter, onChangeShipment, activeFilter, isEdit, payments }: WareShipmnentFilterTableProps) {
  const tableHeader = WareShipmentTableNames
  const [displayPay, setDisplayPay] = useState(false)
  const [activePaymentIndex, setActivePaymentIndex] = useState<number|null>(null)

  const [displayHistory, setDisplayHistory] = useState(false)   
  const [paymentsHistory, setPaymentHistory] = useState<PaymentActivityLogDto[] | null>(null)

  const notify = (message: string) => toast(message);
  const [allFilterNames, setAllFilterNames] = useState<{ [K in keyof ParentTableShipment]: (string | number | boolean)[] }>({
    id: [],
    name: [],
    createdAt: [],
    description: [],
    products: [],
    warehouseName: [],
    state: [],
    totalAmount: [],
    paidAmount: [],
    paidPercent: [],
  })

  const getAllNamesByField = (field: keyof ParentTableShipment) => {
    if (allFilterNames) {
      return allFilterNames[field]
    }
    return []
  }

  const getAllNames = async () => {
    let newFilterNames: { [K in keyof ParentTableShipment]: (string | number | boolean)[] } = JSON.parse(JSON.stringify(allFilterNames))
    if (newFilterNames.createdAt.length !== 0) {
      return
    }
    const shipments = await getAllWareShipment()
    /*     for (let shipment of shipments){
          for (let key in shipment){
            const shipmentKey = key as keyof ParentTableShipment;
            newFilterNames[shipmentKey].push(shipment[shipmentKey])
          }
        } */
    if (!shipments){
      return
    }
    Object.values(shipments).forEach(shipment => {
      newFilterNames.name.push(shipment.name);
      newFilterNames.createdAt.push(shipment.createdAt);
      newFilterNames.description.push(shipment.description)
      newFilterNames.products.push(shipment.products.join(', '))
      newFilterNames.warehouseName.push(shipment.warehouseName)
      newFilterNames.state.push(ShipmentState[shipment.state as unknown as number]);
      newFilterNames.totalAmount.push(shipment.totalAmount)
      newFilterNames.paidAmount.push(shipment.paidAmount)
      newFilterNames.paidPercent.push(shipment.paidPercent)
    });
    setAllFilterNames(newFilterNames)
  }

  useEffect(() => {
    getAllNames()
  }, [])

  const changeShipment = (index: number) => {
    return
  }

  const isProduct = (value: any): value is WarehouseProduct[] => {
    if (Array.isArray(value)){
      return typeof value[0].productBarcode === 'number'
    }
    return false
  }

  const getProductText = (products: WarehouseProduct[]) => {
    let fullResult:string[] = []
    let briefResult: string = ''
    products.forEach((product)=>{return briefResult += `${product.name}, `})
    briefResult = briefResult.slice(0, -2)
    products.forEach((product)=>{return fullResult.push(product.name)})
    return <TableFullBlock title='Товары в поставке' fullContent={products} briefContent={briefResult}/>
  }

  const getText = (el: string | number | boolean | WarehouseProduct[], key: keyof ParentTableShipment) => {
    if (key === 'description' && typeof el === 'string' && el.length !== 0){
      return <TableFullBlock title='Описание поставки' fullContent={el} briefContent={el}/>
    }
    if (typeof el === 'boolean')
      return el ? 'Да' : 'Нет'
    if (typeof el === 'number'){
      return formatNumber(el)
    }
    if (Array.isArray(el)){
      return el.join(', ')
    }
    return el
  }

  const deleteShipment = (index: number) => {
    onDeleteShipment(content[index].id)
  }

  const changeFilterContent = async (data: SortData | null) => {
    if (!data) {
      onFilter()
    } else {
      onFilter(data)
    }
  }

  const handlePay = async (data: PayPayment) =>{
    if (activePaymentIndex !== null){
      const newData = {...data, paymentId: payments[activePaymentIndex]}
      try{
        await axios.post(PagesURl.PAYMENT + '/pay', newData)
        onFilter()
      }catch (error: any) {
        if (error.response.data.match(/Message = (.*?),/)){
          notify(error.response.data.match(/Message = (.*?),/)[1])
        }
      }
    }
  }

  const handlePaymentHistory = async (paymentIndex: number) => {
    const {data} = await axios.get<{paymentsHistory: PaymentActivityLogDto[]}>(PagesURl.PAYMENT + `/history?paymentId=${payments[paymentIndex]}`, {
      params: {
        paymentId: payments[paymentIndex]
      }
    })
    setPaymentHistory(data.paymentsHistory)
  }

  const handleDeletePayment = async (id: string) => {
    try {
      await axios.delete(PagesURl.PAYMENT + `/cancel`, {params:{id: id}})
      if (paymentsHistory){
        setPaymentHistory(paymentsHistory?.filter((el)=>(el.id!==id)))
      }
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)) {
        notify(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }
 
  const isBlockFilter = (columnName: string) => {
    switch (columnName){
      case 'Название': return true
      case 'Описание': return true
      case 'Товары в поставке': return true
      case 'Прогресс оплаты': return true
      default: return false
    }
  }

  return (
    <>
      <div>
        {/* <SearchBlock content={[]} /> */}
        <div className='scrolltable__container'>
          <div className={`table__container ${content.length < 5 && content.length !== 0 ? 'padding__table' : ''}`}>
            <table className='table borderTable'>
              <thead className='thead'>
                <tr className="table__tr">
                  {Object.values(tableHeader).map((el, index) => (index!==0 &&
                    <HeaderPoint 
                      blockedFilter={content.length !== 0 ? isBlockFilter(el) : true}  
                      isDisplayOptions={el !== 'Прогресс оплаты'} 
                      allNames={getAllNamesByField(getKeyByValue(tableHeader, el) as keyof ParentTableShipment)} 
                      filterData={getActiveFilter(activeFilter, getKeyByValue(tableHeader, el) as string)} 
                      changeFilterContent={changeFilterContent} columnName={getKeyByValue(tableHeader, el) as string} name={el} key={el} />))}
                </tr>
              </thead>
              <tbody className='tbody'>
                {content.length !== 0 ? content.map((el, indexLen) => (
                  <tr key={el.id} className='body__tr' onClick={(e) => {e.stopPropagation();getPointInfo(el.id) }}>
                    {Object.keys(el).map((key, index) => (
                      index !== 0 && <td className={`table__td`} key={key}>
                        <div style={index===1 ? {justifyContent: 'flex-start'} : {}} className='table__content'>
                          {index === 1 &&
                        <svg style={{cursor: 'pointer'}} onClick={(e)=>{onChangeShipment(e, el)}} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="25" height="25" rx="6" fill="#0042E4"/>
                        <path d="M6 18.2193H18.6389M6 18.2193V15.0833L12.3194 8.81144M6 18.2193H9.15972L15.4792 11.9474M12.3194 8.81144L14.5854 6.56243L14.5869 6.5611C14.8987 6.25152 15.055 6.09645 15.2351 6.03837C15.3938 5.98721 15.5646 5.98721 15.7233 6.03837C15.9033 6.09641 16.0594 6.2513 16.3708 6.56044L17.7452 7.92443C18.058 8.2349 18.2145 8.39021 18.2731 8.56922C18.3247 8.72668 18.3246 8.89629 18.2731 9.05375C18.2145 9.23263 18.0583 9.3877 17.7459 9.69773L17.7452 9.6984L15.4792 11.9474M12.3194 8.81144L15.4792 11.9474" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                          }
                          {key === 'paidPercent' ? 
                          <div className='filterTable__percentContainer'>
                            <div className='filterTable__percentValues centerFlex'>
                              <span className='filterTable__percentValue'>{`${formatNumber(el.paidAmount)} / ${formatNumber(el.totalAmount)} р.`}</span>
                              <span className='filterTable__percentValue'>{el.paidPercent > 100 ? '100' : el.paidPercent}%</span>
                            </div>
                            <div className='filterTable__percentContent'>
                              <div style={{ width: `${el[key] > 100 ? '100' : el[key]}%` }} className={`filterTable__percentDone ${el[key] !== 0 && el[key] !== 100 ? 'percentBorder' : ''}`}></div>
                            </div>
                          </div> :
                           isProduct(el[key as keyof ParentTableShipment]) ? getProductText(el[key as keyof ParentTableShipment] as WarehouseProduct[]) : getText(el[key as keyof ParentTableShipment], key as keyof ParentTableShipment)}
                          {index + 1 === Object.keys(el).length && isEdit &&
                            <DetailsBlock 
                              additionalComponents={[
                                {img: 'img/edit-pencil.svg', hoverImg: 'img/active-pencil.svg', text: 'Добавить платеж', action:()=>{setDisplayPay(true);setActivePaymentIndex(indexLen)}},
                                {img: 'img/shipment/payment-history/icon.svg', hoverImg: 'img/shipment/payment-history/active-icon.svg', text: 'История платежей', action:()=>{setDisplayHistory(true);handlePaymentHistory(indexLen)}}
                              ]} 
                              displayEdit={false} 
                              changeAction={changeShipment} 
                              deleteAction={deleteShipment} 
                              index={indexLen} />
                          }
                        </div>
                      </td>))}
                  </tr>
                )) : <td colSpan={Object.values(tableHeader).length} className='body__notData'>Нет данных</td>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ModalWindow 
        isOpen={displayPay} 
        title='Добавить платеж' 
        btnText='Создать' 
        fields={
        [
          {
            name: 'Сумма',
            backName: 'paymentAmount',
            type: 'number'
          },
          {
            name: 'Описание',
            backName: 'description',
            type: 'text',
            required: false
          }
        ]} 
        onRequestClose={()=>{setDisplayPay(false)}}
        checkFields={(fields)=>{return true}}
        onSubmit={handlePay}
      />
      {displayHistory && paymentsHistory &&
        <ModalComponent title='История платежей' onClose={()=>{setDisplayHistory(false); setPaymentHistory(null)}} children={
          <div style={{maxHeight:'60vh', overflow: 'auto', paddingRight: '10px', paddingBottom: '35px', paddingLeft: '10px', marginTop: '20px', paddingTop: '10px'}}>
            {paymentsHistory.length !== 0 ? paymentsHistory.map((payment)=>(
              <PaymentHistoryPoint onDelete={()=>{handleDeletePayment(payment.id)}} key={payment.id} oldPay={payment.oldPaidAmount} newPay={payment.newPaidAmount} date={payment.paymentDate} id={payment.paymentId} price={payment.paymentAmount} description={payment.description}/>))
            : <span>Платежи отсутствуют</span>}
          </div>
      }/>
      }
    </>
  )
}