import { ChildTableShipment, ParentTableShipment } from "../shipment-model";

export const ShipmentState = [
  'Создано',
  'Производство',
  'Доставка до склада',
  'На Складе',
  'Доставка на маркетплейс',
  'Приемка на маркетплейсе',
  'Продано'
];

export const MarketPlaceType = [
  'WB',
  'Ozon'
];

export const DeliveryType = [
  'Водитель',
  'Транспортная компания',
  'Фулфилмент'
]

export const WareShipmentTableNames:{ [K in keyof ParentTableShipment]: string } = {
  id: 'id',
  name: 'Название',
  createdAt: 'Дата создания',
  description: 'Описание',
  products: 'Товары в поставке',
  warehouseName: 'Склад',
  state: 'Состояние',
  totalAmount: 'Общая стоимость',
  paidAmount: 'Оплачено',
  paidPercent: 'Прогресс оплаты',
}

export const  MarketShipmentTableNames:{ [K in keyof ChildTableShipment]: string } = {
  number: 'Номер поставки',
  createdAt: 'Дата создания',
  description: 'Описание',
  products: 'Товары в поставке',
  marketplaceType: 'Маркетплейс',
  warehouseName: 'Склад',
  state: 'Состояние',
}