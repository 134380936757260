import './dropdown-menu.css'
import DropdownPoint from './dropdown-point'

type DropdownMenuProps = {
  content: { src: string, hoverSrc: string, text: string, action: () => void, newSrc?: string, newHoverSrc?:string, isNew?:boolean }[]
}


export default function DropdownMenu({ content }: DropdownMenuProps) {

  return (
    <div className='dropdownMenu__container'>
      {content.map((el) => (
        <DropdownPoint key={el.text} content={el}/>
      ))}
    </div>
  )
}