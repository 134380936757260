export enum ParticipantRole {
  'Просмотр', 
  'Создание', 
  'Изменение', 
  'Владелец'
}

export enum Entities {
  'Продукты' = 1, 
  'Поставки' = 2, 
  'Продажи' = 4, 
  'Оплаты' = 8, 
  'Метрики' = 16, 
  'Склады' = 32,
  'Все' = 64
}

export const SidebarLinks = [
/*   {
    name: 'products',
    value: 'Продукты'
  }, */
  {
    name: 'accounting',
    value: 'Товароучет'
  },
  {
    name: 'warehouseshipment',
    value: 'Поставки до склада'
  },
  {
    name: 'marketshipment',
    value: 'Поставки до маркетплейса'
  },
  {
    name: 'selling',
    value: 'Продажи'
  },
/*   {
    name: 'payment',
    value: 'Оплата'
  }, */
/*   {
    name: 'metrics',
    value: 'Метрики'
  } */
]