import { useState } from 'react'
import PaginationBlock from '../pagination-block/pagination-block'
import styles from './info-container.module.css'

type InfoContainerProps = {
  title: string,
  pointCount: number,
  changeContainer?: {changeState: (toNew: boolean)=>void, firstText: string, secondText: string}
  children: JSX.Element
  onChangeSelectedValue: (value: number) => void, 
  activeStage: number | null, 
  countRows: number, 
  getTotalStages: () => number, 
  changeActiveStage: (toStage: number) => void
  onReadAll?: () => void
}

export default function InfoContainer(props: InfoContainerProps){
  const [isDisplayAll, setIsDisplayAll] = useState(false)
  const [isDisplaySelect, setIsDisplaySelect] = useState(false)
  return (
    <PaginationBlock 
      changeActiveStage={props.changeActiveStage} 
      activeStage={props.activeStage}
      countRows={props.countRows}
      getTotalStages={props.getTotalStages}
      onChangeSelectedValue={props.onChangeSelectedValue}>
      <div className='notifications'>
        <div className='errors__firstLine'>
          <div className='notifications__title centerFlex'>
            <h1>{props.title}</h1>
            <span className='notifications__count centerFlex'>{props.pointCount}</span>
          </div>
          {props.changeContainer && <>
          {isDisplaySelect ? <div className='errors__selectContainer'>
            <div onClick={() => {props.changeContainer?.changeState(true); setIsDisplaySelect(!isDisplaySelect);setIsDisplayAll(false) }} className='errors__select pointer'>
              <svg className='errors__selectSvg' width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L8 8L15 1" stroke="#0042E4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <p>{props.changeContainer.firstText}</p>
            </div>
            <div onClick={() => {props.changeContainer?.changeState(false); setIsDisplaySelect(!isDisplaySelect);setIsDisplayAll(true) }} className='errors__select pointer'>
              <svg className='errors__selectSvg' width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L8 8L15 1" stroke="#0042E4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <p>{props.changeContainer.secondText}</p>
            </div>
          </div> :
            <div className='errors__selectContainer'>
              {isDisplayAll ? <div className='errors__select pointer' onClick={() => {setIsDisplaySelect(!isDisplaySelect) }}>
                <svg className='errors__selectSvg' width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L8 8L15 1" stroke="#0042E4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p>{props.changeContainer.secondText}</p>
              </div> :
                <div className='errors__select pointer' onClick={() => {setIsDisplaySelect(!isDisplaySelect) }}>
                  <svg className='errors__selectSvg' width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L8 8L15 1" stroke="#0042E4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  <p>{props.changeContainer.firstText}</p>
                </div>}
            </div>
          }
          {props.onReadAll && 
            <div onClick={props.onReadAll} className={styles.onReadAll__container}>
              <div className={styles.onReadAll__circle}></div>
              <p className={styles.onReadAll__text}>Отметить все прочитанными</p>
            </div>
          }
          </>}
        </div>
        {props.children}
      </div>
    </PaginationBlock>
  )
}