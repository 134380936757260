import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import '../modal-window.css';
import CustomSlider from '../../slider/custom-slider';
import { formatNumber, formatString, getCurrentDate, getCurrentDateForInputWithoutTime, getDateForInput } from '../../../utils';
import { AddProducts, ChildShipment } from '../../../types/shipment-model';

interface CreateProductModalProps {
  isOpen: boolean;
  title: string;
  btnText: string;
  fields: { name: string, type: string, backName: string, value?: string | number | ChildShipment | {id: string | number, name: string | number}, required?: boolean, options?: ChildShipment[] | {id: string | number, name: string | number}[] }[];
  children?: JSX.Element | undefined;
  onRequestClose: () => void;
  checkFields: (fields: { [key: string]: string | boolean | number | ChildShipment | {id: string | number, name: string | number} }) => boolean;
  onSubmit: Function;
  onError?:(error: string) => void
}

function SellingModalWindow({ isOpen, onRequestClose, onSubmit, fields, title, btnText, checkFields, children, onError }: CreateProductModalProps) {
  let newFields: { [key: string]: string | boolean | number | ChildShipment | {id: string | number, name: string | number} } = {}
  for (let i in fields) {
    switch (fields[i].type) {
      case 'checkbox':
        if (typeof fields[i].value === 'number') {
          newFields[fields[i].backName] = fields[i].value as string
        } else {
          newFields[fields[i].backName] = false
        }
        break
      case 'select':
        newFields[fields[i].backName] = fields[i].value as number
        break
      case 'number':
        if (typeof fields[i].value === 'number') {
          newFields[fields[i].backName] = fields[i].value as number
        } else {
          newFields[fields[i].backName] = 0
        }
        break
      case 'text':
        if (typeof fields[i].value === 'string') {
          newFields[fields[i].backName] = fields[i].value as string
        } else {
          newFields[fields[i].backName] = ''
        }
        break
      case 'datetime-local':
        if(fields[i].value){
          newFields[fields[i].backName] = fields[i].value as string
        } else{
          newFields[fields[i].backName] = getCurrentDate()
        }
        break
      case 'date':
        if(fields[i].value){
          newFields[fields[i].backName] = fields[i].value as string
        } else{
          newFields[fields[i].backName] = getCurrentDateForInputWithoutTime()
        }
        break
    }
  }
  const [inputs, setInputs] = useState(newFields)
  function isNotStringBooleanNumber(value: any): value is AddProducts[]  {
    return typeof value !== 'string' && typeof value !== 'boolean' && typeof value !== 'number';
  }

  const checkForNullTable = () => {
    for (let i in inputs){
      let point = inputs[i]
      if (isNotStringBooleanNumber(point)){
        for (let j in point){
          if (point[j].quantity === 0){
            return false
          }
        }
      }
    }
    return true
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (checkFields({
      ...inputs,
      'productBarcode': typeof inputs['productBarcode'] === 'object' ? (inputs['productBarcode'] as { id: string | number, name: string | number }).id : inputs['productBarcode']
  })) {
      if (checkForNullTable()) {
        onSubmit({
          ...inputs,
          'productBarcode': typeof inputs['productBarcode'] === 'object' ? (inputs['productBarcode'] as { id: string | number, name: string | number }).id : inputs['productBarcode']
      });      
        for (let i in fields) {
          switch (fields[i].type) {
            case 'checkbox':
              newFields[fields[i].backName] = false
              break
            case 'select':
              newFields[fields[i].backName] = 0
              break
            case 'number':
              if (typeof fields[i].value === 'number') {
                newFields[fields[i].backName] = fields[i].value as number
              } else {
                newFields[fields[i].backName] = 0
              }
              break
            case 'text':
              newFields[fields[i].backName] = ''
              break
            case 'datetime-local':
              newFields[fields[i].backName] = getCurrentDate()
              break
            case 'date':
              newFields[fields[i].backName] = getCurrentDateForInputWithoutTime()
              break
          }
        }
        setInputs(newFields)
        children || onRequestClose();
      } else {
        if (onError){
          onError('количество товара в таблице должно быть больше 0')
        }
      }
    }
  };

  const changeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, name: string, type: string) => {
    switch (type) {
      case 'number':
        setInputs({ ...inputs, [name]: formatString(e.target.value) })
        return
      case 'select':
        setInputs({ ...inputs, [name]: Number(e.target.value) - 1 })
        return
      case 'text':
        setInputs({ ...inputs, [name]: e.target.value })
        return
      case 'datetime-local':
        setInputs({ ...inputs, [name]: e.target.value + ':00.000Z' })
        return
      case 'date':
        setInputs({ ...inputs, [name]: e.target.value.slice(0, 10) })
    }
  }

  const changeCheckbox = (initialState: boolean, name: string) => {
    initialState ? setInputs({ ...inputs, [name]: false }) : setInputs({ ...inputs, [name]: true })
  }

  const changeSelect = (value: number | string | {id: string | number, name: string | number} , backName: string) => {
    if (backName === 'shipmentNumber'){
      setInputs({ ...inputs, [backName]: value, 'productBarcode': {id: 0, name: 0} })
      return 
    }
    setInputs({ ...inputs, [backName]: value })
  }
  
  const onCloseModal = () => {
    for (let i in fields) {
      switch (fields[i].type) {
        case 'checkbox':
          newFields[fields[i].backName] = false
          break
        case 'select':
          newFields[fields[i].backName] = 0
          break
        case 'number':
          if (typeof fields[i].value === 'number') {
            newFields[fields[i].backName] = fields[i].value as number
          } else {
            newFields[fields[i].backName] = 0
          }
          break
        case 'text':
          newFields[fields[i].backName] = ''
          break
        case 'datetime-local':
          newFields[fields[i].backName] = getCurrentDate()
          break
        case 'date':
          newFields[fields[i].backName] = getCurrentDateForInputWithoutTime()
          break
      }
    }
    setInputs(newFields)
    onRequestClose()
  }

  const getValueInput = (type: 'number' | 'datetime-local' | 'text' | 'date', backName: string) => {
    switch (type){
      case 'number':
        return formatNumber(inputs[backName] as number)
      case 'text':
        return inputs[backName] as string
      case 'date':
        return inputs[backName] as string
      case 'datetime-local':
        return getDateForInput(inputs[backName] as string)
    }
  }

  const getBarcodesSelect = (name: string, required: boolean | undefined) => {
    if (inputs['shipmentNumber']){
      if (inputs['shipmentNumber'] !== 0){
        let result:{id: string | number, name: string | number}[] = []
        for (let field of fields){
          if (field.backName === 'shipmentNumber' && field.options){
            for (let option of field.options as ChildShipment[]){
              if (option.number === inputs['shipmentNumber']){
                for (let product of option.products){
                  result.push({id: product.productBarcode, name: product.productName})
                }
                return (
                  <>
                  <div className='close__inputLabel'>
                    {name}
                    {(required === true || required === undefined) && <span className='close__inputRequired'>*</span>}
                  </div>
                  {
                    <SellingSelect selectedOpt={inputs['productBarcode'] as string} changeValue={changeSelect} options={result} name='productBarcode'/>
                  }
                </>
                )
              }
            }
          }
        }
      }
    }
    return <></>
  }

  useEffect(() => {
    let newFields: { [key: string]: string | boolean | number | ChildShipment | { id: string | number, name: string | number } } = {}
    for (let i in fields) {
      switch (fields[i].type) {
        case 'checkbox':
          if (typeof fields[i].value === 'number') {
            newFields[fields[i].backName] = fields[i].value as string
          } else {
            newFields[fields[i].backName] = false
          }
          break
        case 'select':
          newFields[fields[i].backName] = fields[i].value as number
          break
        case 'number':
          if (typeof fields[i].value === 'number') {
            newFields[fields[i].backName] = fields[i].value as number
          } else {
            newFields[fields[i].backName] = 0
          }
          break
        case 'text':
          if (typeof fields[i].value === 'string') {
            newFields[fields[i].backName] = fields[i].value as string
          } else {
            newFields[fields[i].backName] = ''
          }
          break
        case 'datetime-local':
          if (fields[i].value) {
            newFields[fields[i].backName] = fields[i].value as string
          } else {
            newFields[fields[i].backName] = getCurrentDate()
          }
          break
        case 'date':
          if (fields[i].value) {
            newFields[fields[i].backName] = fields[i].value as string
          } else {
            newFields[fields[i].backName] = getCurrentDateForInputWithoutTime()
          }
          break
      }
    }
    setInputs(newFields)
  }, [fields])

  return (
    <Modal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onCloseModal}
      contentLabel={title}
      className="modal"
      overlayClassName="modal-overlay"
    >
      <div className="modal-content">
        <div className='modal__firstLine'>
          <h2>{title}</h2>
          <div className="close__button" onClick={onCloseModal}>
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(0.719608 0.69438 -0.719608 0.69438 0.392899 2.15039)" stroke="black" strokeWidth="2" />
              <line opacity="0.7" y1="-1" x2="30.0262" y2="-1" transform="matrix(-0.719608 0.69438 -0.719608 -0.69438 21.6071 1)" stroke="black" strokeWidth="2" />
            </svg>
          </div>
        </div>
        <form onSubmit={handleSubmit} className='modal__form'>
          {fields.map((field) => (
            <div key={field.name} className='close__inputBlock'>
              {field.type === 'select' && field.options && field.backName !== 'productBarcode' &&
                <>
                  <div className='close__inputLabel'>
                    {field.name}
                    {(field.required === true || field.required === undefined) && <span className='close__inputRequired'>*</span>}
                  </div>
                  {
                    <SellingSelect selectedOpt={inputs['shipmentNumber'] as string} changeValue={changeSelect} options={field.options} name={field.backName} />
                  }
                </>
              }
              {field.type === 'select' && field.backName === 'productBarcode' && getBarcodesSelect(field.name, field.required)}
              {field.type === 'checkbox' &&
                <div className='close__inputLabelCheckbox'>
                  <span>{field.name}:</span>
                  <input
                    className='close__inputCheckbox'
                    type={field.type}
                    onChange={(e) => { changeInput(e, field.backName, field.type) }}
                  />
                  <CustomSlider isActive={inputs[field.backName] as boolean} changeCheckbox={changeCheckbox} name={field.backName} />
                </div>}
              {(field.type === 'number' || field.type === 'datetime-local' || field.type === 'text' || field.type === 'date') &&
                <>
                  <div className='close__inputLabel'>
                    <span>{field.name}:</span>
                    {(field.required === true || field.required === undefined) && <span className='close__inputRequired'>*</span>}
                  </div>
                  <input
                    className='close__input'
                    type={field.type === 'number' ? 'text' : field.type}
                    value={getValueInput(field.type, field.backName)}
                    onChange={(e) => { changeInput(e, field.backName, field.type) }}
                    required={field.required !== undefined ? field.required : true}
                  />
                </>
              }
            </div>
          ))}
          <button className='submit__button' type="submit">{btnText}</button>
          {children}
        </form>
      </div>
    </Modal>
  );
}

export default SellingModalWindow;

type SellingSelectProps = {
  changeValue: (element: number | string | {id: string | number, name: string | number}, name: string) => void;
  options: {id: string | number, name: string | number}[] | ChildShipment[];
  name: string;
  selectedOpt?: string | number | {id: string | number, name: string | number};
}

function SellingSelect({ changeValue, options, name, selectedOpt}: SellingSelectProps) {
  const [displayOptions, setDisplayOptions] = useState(false)

  const isObject = (option: any): option is {id: string | number, name: string | number} => {
    if (option === undefined){
      return false
    }
    return typeof option.name === 'string' || typeof option.name === 'number'
  }

  const submitChange = (index: number, el: ChildShipment | {id: string | number, name: string | number}) =>{
    setDisplayOptions(false)
    const option = options[index]
    isObject(option) ? changeValue(option, name) : changeValue(option.number, name)
  }

  const getSelectedOption = () => {
    if (!selectedOpt){return ''}
    if (isObject(selectedOpt)){
      return selectedOpt.id !== 0 && selectedOpt.name !== 0 ? selectedOpt.name : ''
    }
    return selectedOpt
  }

  return (
    <>
      <div className={`close__select ${displayOptions && 'close__selectActive'}`} onClick={() => { setDisplayOptions(!displayOptions) }}>
        {getSelectedOption()}
        <svg className="close__selectSvg" width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.0641657 2.39908L5.32107 8.98908C5.40801 9.09788 5.59185 9.09788 5.67868 8.98908L10.9359 2.39874C10.9985 2.31992 11.017 2.20231 10.9833 2.10016C10.9763 2.07888 10.9685 2.0624 10.962 2.05028C10.9219 1.97592 10.8447 1.92936 10.7601 1.92936L0.239426 1.92936C0.155228 1.92936 0.0776648 1.97569 0.0378537 2.05028C0.0311041 2.06263 0.0233249 2.07922 0.0168041 2.09901C-0.0171726 2.20231 0.00124577 2.32003 0.0641657 2.39908Z" fill={displayOptions ? "#007BFF" : "#808080"} />
        </svg>
      </div>
      {displayOptions &&<div className="close__selectList">
        {options && options.map((el, index)=>(<div key={index} onClick={()=>{submitChange(index, el)}} className="close__selectListPoint">{isObject(el) ? el.name : el.number}</div>))}
      </div>}
    </>
  )
}