import { useEffect, useState } from 'react'
import './main-block.css'
import { CreateOrganization, Organization } from '../../types/organization-model'
import ModalWindow from '../modal-window/modal-window'
import checkCreateOrganization from '../../utils/organization'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getStateOrganizations } from '../../store/organization-process/selectors'
import { createOrganizationAction, deleteOrganizationAction, getOrganizationsAPI } from '../../store/api-actions/organization-api-actions'
import { getIsFirstLogin } from '../../store/user-process/selectors'
import Card from '../card/card'
import { changeStateActiveOrganization } from '../../store/organization-process/organization-actions'
import { redirectToRoute } from '../../store/action'
import { getUserInfoAction } from '../../store/api-actions/user-api-actions'
import { getOrganization } from '../../services/organization'
import { OrganizationRole } from '../../types/account-model'
import axios, { PagesURl } from '../../services/api'
import { deleteFirstLoginAction } from '../../store/user-process/user-action'


export default function MainBlock() {

  const isFirstLogin = useAppSelector(getIsFirstLogin)

  const [createOrganization, setCreateOrganization] = useState(isFirstLogin)

  const dispatch = useAppDispatch()

  const organizations = useAppSelector(getStateOrganizations)

  const [userRoles, setUserRoles ]= useState<OrganizationRole[] | null>(null) 


  const handleCreateOrganization = async (data: CreateOrganization) => {
    dispatch(createOrganizationAction(data))
  }
  const handleGetUserRoles = async () => {
    const {data} = await axios.get<OrganizationRole[]>(PagesURl.USER + '/get/roles')
    setUserRoles(data)
  }

  const checkOnDeleteOrganization = (name: string) => {
    if (!userRoles){
      return false
    }
    for (let role of userRoles){
      if (role.organizationName === name){
        return role.role === 3 ? true : false
      }
    }
    return false
  }

  const redirectToActiveOrganization = (organization: Organization) => {
    dispatch(changeStateActiveOrganization(organization))
    dispatch(getUserInfoAction())
    dispatch(redirectToRoute(`./${organization.id}`))
  }

  useEffect(() => {
    if (!getOrganization()){
      dispatch(getOrganizationsAPI())
    }
    handleGetUserRoles()
  }, [])

  useEffect(()=>{

  },[])


  return (
    <>
      <div className='mainBlock__container'>
        <div className='mainBlock__cards centerFlex'>
          {organizations.map((organization) => (
            <Card 
              key={organization.id}
              isCanDelete={checkOnDeleteOrganization(organization.name)}
              onClickAction={()=>{redirectToActiveOrganization(organization)}} 
              onDeleteAction={()=>{dispatch(deleteOrganizationAction(organization.id))}} 
              title={organization.name} 
              secondLine={organization.creationDate} 
              leftContent={organization.usersNames} 
              rightContent={organization.productsNames} 
            />))}
          <div onClick={()=>{setCreateOrganization(true)}} className='card__container centerFlex mainBlock__createCard'>
            <div className='createCard__content flex pointer'>
              <svg width="97" height="97" viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="97" height="97" rx="8.42105" fill="#D9E5FF" />
                <line x1="48.5" y1="18" x2="48.5" y2="79" stroke="#0042E4" strokeWidth="5" />
                <line x1="79" y1="48.5" x2="18" y2="48.5" stroke="#0042E4" strokeWidth="5" />
              </svg>
              <p className='mainBlock__createCardTitle'>Создать организацию</p>
            </div>
          </div>
        </div>
        <ModalWindow
          isOpen={createOrganization}
          onRequestClose={() => { setCreateOrganization(false); dispatch(deleteFirstLoginAction())}}
          title='Создание организации'
          btnText='Создать'
          onSubmit={handleCreateOrganization}
          fields={[
            {
              name: 'Название',
              backName: 'name',
              type: 'text'
            }
          ]}
          checkFields={checkCreateOrganization}
        />
      </div>
    </>
  )
}