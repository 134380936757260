import { useEffect, useState } from 'react'
import './filter.css'

type FilterRadioProps = {
  title?: string,
  content: {name: string | JSX.Element, backName: string | null}[]
  changeValue: (backName: string | null) => void
  isDeletingSort: boolean
}
export default function FilterRadio({content, changeValue, isDeletingSort}:FilterRadioProps){

  const [activeValue, setActiveValue] = useState<string | null>(null)

  const onChangeValue = (backName: string | null) => {
    setActiveValue(backName)
    changeValue(backName)
  }

  useEffect(()=>{
    if (isDeletingSort){
      setActiveValue(null)
    }
  },[isDeletingSort])

  return (
    <div className='filterRadio__container'>
      {content.map((el) => (
        <div onClick={()=>{onChangeValue(el.backName)}} key={el.backName} className='filterRadio__point centerFlex pointer'>
          {el.backName === activeValue ? 
            <img className='filterRadio__radio' alt='' src='img/radio-button/active.svg' /> :
            <img  className='filterRadio__radio' alt='' src='img/radio-button/inactive.svg' />
          }
          {typeof el.name === 'string' ? <span>{el.name}</span> : el.name}
        </div>
      ))}
    </div>
  )
}