const ORGANIZATION_ID = 'organization-id'

export const getOrganization = () => {
  const organization = localStorage.getItem(ORGANIZATION_ID)
  return organization ? parseInt(organization) : null
}

export const saveOrganization = (id: number) => {
  localStorage.setItem(ORGANIZATION_ID, id.toString())
}

export const dropOrganization = () => {
  localStorage.removeItem(ORGANIZATION_ID)
}