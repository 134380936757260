import { useEffect, useState } from 'react'
import './notifications.css'
import { Invitation } from '../../types/notifications'
import Spinner from '../../components/spinner/spinner'
import axios, { PagesURl } from '../../services/api'
import { ParticipantRole } from '../../types/enums/organization-type'
import { toast } from 'react-toastify'
import { useAppDispatch } from '../../hooks'
import { getInvitationsLengthAction } from '../../store/api-actions/organization-api-actions'
import InfoContainer from '../../components/info-container/info-container'
import { getTotalStages } from '../../utils/filter-table'
import { stageList } from '../../consts'
import { SortData } from '../../types/main'

export default function Notifications() {

  const [invitations, setInvitations] = useState<Invitation[]>()

  const [countRows, setCountRows] = useState<number>(stageList[0])
  const [activeStage, setActiveStage] = useState<number | null>(null)
  const [invitationsCount, setInvitationsCount] = useState(0)
  const [activeFilter, setActiveFilter] = useState<SortData>({ take: countRows, skip: 0 })

  const dispatch = useAppDispatch()

  const handleGetInvitations = async (data: SortData = { take: countRows, skip: 0 }) => {
    try {
      const response = await axios.post(PagesURl.ORGANIZATION + '/find/invitations', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const products = response.data.data;
      setInvitations(products)
      setInvitationsCount(response.data.totalCount)
      setActiveFilter(data)
      if (!activeStage) {
        setActiveStage(response.data.totalCount > data.take ? 1 : null)
      }
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)){
        toast(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }

  const handleAnswerInvitation = async (organizationId: number, accept: boolean) => {
    try {
      await axios.post(PagesURl.ORGANIZATION + '/accept/invitation', [{
        organizationId: organizationId,
        acceptInvitation: accept
      }])
      handleGetInvitations()
      dispatch(getInvitationsLengthAction())
    } catch (error: any) {
      if (error.response.data.match(/Message = (.*?),/)) {
        toast(error.response.data.match(/Message = (.*?),/)[1])
      }
    }
  }

  const changeSelectedValue = (newValue: number) => {
    setCountRows(newValue)
    handleGetInvitations({ take: newValue, skip: 0 })
  }

  const changeActiveStage = (toStage: number) => {
    let newSortData: SortData = JSON.parse(JSON.stringify(activeFilter))
    newSortData.skip = countRows * toStage
    handleGetInvitations(newSortData)
    setActiveStage(toStage + 1)
  }


  useEffect(() => {
    handleGetInvitations()
  }, [])

  if (!invitations) {
    return <Spinner />
  }

  return (
    <InfoContainer
    title='Приглашения'
    pointCount={invitations.length}
    onChangeSelectedValue={changeSelectedValue}
    activeStage={activeStage}
    getTotalStages={() => { return getTotalStages(invitationsCount, countRows) }}
    countRows={countRows}
    changeActiveStage={changeActiveStage}>
        <div className='notifications__content'>
          {invitations.map((invitation, index) => (
            <div key={`${index}--${invitation.organizationId}`} className='notifications__notification centerFlex'>
              <div className='notifications__text centerFlex'>
                <div className='notifications__new'></div>
                <div className='notifications__user'></div>
                <div className='notifications__textContent'>
                  <p className='notifications__textName'>{`${invitation.ownerName} приглашает вас в ${invitation.organizationName}`}</p>
                  <p className='notifications__textRole'>{ParticipantRole[invitation.role]}</p>
                </div>
              </div>
              <div className='notifications__buttons centerFlex'>
                <div onClick={() => { handleAnswerInvitation(invitation.organizationId, true) }} className='notifications__button notifications__button_submit centerFlex pointer'>
                  <img alt='принять' src='img/notifications/submit.svg' />
                  <p className='notifications__buttonText notifications__buttonText_submit'>Принять</p>
                </div>
                <div onClick={() => { handleAnswerInvitation(invitation.organizationId, false) }} className='notifications__button notifications__button_notSubmit centerFlex pointer'>
                  <img alt='отклонить' src='img/notifications/notSubmit.svg' />
                  <p className='notifications__buttonText notifications__buttonText_notSubmit'>Отклонить</p>
                </div>
              </div>
            </div>
          ))}
        </div>
    </InfoContainer>
    )
}