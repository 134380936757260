const AUTH_TOKEN_KEY_NAME = 'authorization-token';
const REFRESH_TOKEN_KEY_NAME = 'refresh-token';
const AUTH_ACCESS_TOKEN_EXPIRES_IN = 'access-token-expires-in';
const AUTH_REFRESH_TOKEN_EXPIRES_IN = 'refresh-roken-expires-in';

export type Token = string;

export const getToken = (): Token => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY_NAME);
  return token ? token : '';
};

export const getRefreshToken = (): string => {
  const token = localStorage.getItem(REFRESH_TOKEN_KEY_NAME);
  return token ? token : '';
}

export const getAccessTokenExpiresIn = () => {
  const token = localStorage.getItem(AUTH_ACCESS_TOKEN_EXPIRES_IN);
  return token ? token : null;
}

export const getRefreshTokenExpiresIn = () => {
  const token = localStorage.getItem(AUTH_REFRESH_TOKEN_EXPIRES_IN);
  return token ? token : null;
}

export const saveToken = (token: Token, refreshToken: string, accessTokenExpiresIn: number, refreshTokenExpiresIn: number): void => {
  localStorage.setItem(AUTH_TOKEN_KEY_NAME, token)
  localStorage.setItem(REFRESH_TOKEN_KEY_NAME, refreshToken)
  localStorage.setItem(AUTH_ACCESS_TOKEN_EXPIRES_IN, accessTokenExpiresIn.toString())
  localStorage.setItem(AUTH_REFRESH_TOKEN_EXPIRES_IN, refreshTokenExpiresIn.toString())
};

export const dropToken = (): void => {
  localStorage.removeItem(AUTH_TOKEN_KEY_NAME)
  localStorage.removeItem(REFRESH_TOKEN_KEY_NAME)
  localStorage.removeItem(AUTH_ACCESS_TOKEN_EXPIRES_IN)
  localStorage.removeItem(AUTH_REFRESH_TOKEN_EXPIRES_IN)
};