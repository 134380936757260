import useOutsideClick from '../../hooks/useOutsideClick';
import { SortData } from '../../types/main';
import FilterBlock from '../../filter/filter-blocks/filter-block';
import './filter-table.css'
import { useRef, useState } from 'react';

type HeaderPointProps = {
  filterData: SortData
  columnName: string
  name: string
  changeFilterContent: (data: SortData | null) => void;
  allNames: (string | number | boolean)[]
  isDisplayOptions?: boolean
  blockedFilter?:boolean
}

export default function HeaderPoint({ name, changeFilterContent, columnName, filterData, allNames, isDisplayOptions, blockedFilter}: HeaderPointProps) {
  const [displayMenu, setDisplayMenu] = useState(false)
  const blockRef = useRef<HTMLTableCellElement>(null);
  useOutsideClick(blockRef, ()=>{displayMenu && setDisplayMenu(false)})

  function getStringArray(content: (string | number | boolean)[]) {
    if (!content){
      return []
    }
    let newArray = []
    for (let i of content) {
      if (i){
        newArray.push(i.toString())
      }
    }
    return newArray
  }

  const isDisplayFilter = () => {
    if (blockedFilter === undefined){
      return true
    }
    return !blockedFilter
  }

  return (
    <th className={`"table__th ${isDisplayFilter() && 'table__headPadding'}`}>
      <div ref={blockRef} className={`table__headerPoint ${!isDisplayFilter() && 'table__headerPoint_center'}`}>
        <span className={`${isDisplayFilter() && 'th__text'}`}>{name}</span>
        {isDisplayFilter() &&
          <svg onClick={() => { setDisplayMenu(!displayMenu) }} className="table__svg pointer" width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="28" height="28" rx="6.62" fill="#F7F9FF" />
            <rect x="0.5" y="0.5" width="28" height="28" rx="6.62" stroke="#AAC3FF" strokeLinecap="round" />
            <path d="M9.9616 8H19.9616L14.9516 14.3L9.9616 8ZM7.2116 7.61C9.2316 10.2 12.9616 15 12.9616 15V21C12.9616 21.55 13.4116 22 13.9616 22H15.9616C16.5116 22 16.9616 21.55 16.9616 21V15C16.9616 15 20.6816 10.2 22.7016 7.61C23.2116 6.95 22.7416 6 21.9116 6H8.0016C7.1716 6 6.7016 6.95 7.2116 7.61Z" fill="#AAC3FF" />
          </svg>}
        {displayMenu && <FilterBlock isDisplayOptions={isDisplayOptions} allNames={getStringArray(allNames)} data={filterData} columnName={columnName} changeFilterContent={changeFilterContent} />}
      </div>
    </th>
  )
}