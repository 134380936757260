import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosInstance } from "axios";
import { AuthorizationStatus } from "../../types/enums/account-type";
import { AppDispatch, State } from "../../types/state";
import { getCurrentDateUTCNumber, getDataNumberUTCFromDouble } from "../../utils";
import { getAccessTokenExpiresIn, getRefreshToken, getRefreshTokenExpiresIn, getToken, saveToken } from "../../services/token";
import { redirectToRoute } from "../action";
import { Account, AuthData, RegisterData, User } from "../../types/account-model";
import { PagesURl } from "../../services/api";

export const checkAuthAction = createAsyncThunk<AuthorizationStatus, undefined, { dispatch: AppDispatch; state: State; extra: AxiosInstance }>(
  'user/checkAuth',
  async (_arg, {dispatch, extra: api}) => {
    try {
      let curentDate = getCurrentDateUTCNumber()
      if (getToken().length === 0 || getDataNumberUTCFromDouble(getRefreshTokenExpiresIn()) < curentDate){
        console.log('auth token not found or refresh token < current Data')
        return AuthorizationStatus.NoAuth
      } else if (getDataNumberUTCFromDouble(getAccessTokenExpiresIn()) < curentDate){
        console.log('update refresh token')
        const {data} = await api.post<Account>(PagesURl.AUTH + '/refresh', {'refreshToken': getRefreshToken()})
        saveToken(data.accessToken, data.refreshToken, data.accessTokenExpiresIn, data.refreshTokenExpiresIn)
        return AuthorizationStatus.Auth
      }
      console.log('authorization success')
      return AuthorizationStatus.Auth
    }
    catch {
      return AuthorizationStatus.NoAuth
    }
  }
);

export const loginAction = createAsyncThunk<void | string, AuthData, { dispatch: AppDispatch; state: State; extra: AxiosInstance }>(
  'user/login',
  async ({phoneData, password}, {dispatch, extra: api}) => {
    try{
      const {data} = await api.post<Account>(PagesURl.AUTH + '/login', {phoneData, password});
      saveToken(data.accessToken, data.refreshToken, data.accessTokenExpiresIn, data.refreshTokenExpiresIn)
      dispatch(redirectToRoute('/'))
    }
    catch (error: any) {
      return error.response.data.match(/Message = (.*?),/)[1]
    }
  },
);

export const getUserInfoAction = createAsyncThunk<User, undefined, { dispatch: AppDispatch; state: State; extra: AxiosInstance }>(
  'user/getInfo',
  async (_args, {extra: api}) => {
    const {data} = await api.get<User>(PagesURl.USER + '/get');
    return data
  },
);

export const editUserInfoAction = createAsyncThunk<void, {[x: string]: string}, { dispatch: AppDispatch; state: State; extra: AxiosInstance }>(
  'user/editInfo',
  async (data, {dispatch, extra: api}) => {
    await api.put(PagesURl.USER + '/edit',data);
    dispatch(getUserInfoAction())
  },
);

export const registrationAction = createAsyncThunk<void | string, RegisterData, { dispatch: AppDispatch; state: State; extra: AxiosInstance }>(
  'user/registration',
  async (registerData, {dispatch, extra: api}) => {
    try{
      const {data} = await api.post<Account>(PagesURl.AUTH + '/register', registerData);
      saveToken(data.accessToken, data.refreshToken, data.accessTokenExpiresIn, data.refreshTokenExpiresIn)
      dispatch(checkAuthAction())
      sessionStorage.removeItem('regSt')
      sessionStorage.removeItem('regPh')
      sessionStorage.removeItem('regPass')
      sessionStorage.removeItem('regCode')
    }
    catch (error: any) {
      return error.response.data.match(/Message = (.*?),/)[1]
    }
  },
);