import { useState } from "react"
import './login.css'
import PasswordInput from "../../components/account-block/password-input"
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { useAppDispatch } from "../../hooks"
import { loginAction } from "../../store/api-actions/user-api-actions";
import { validatePhoneNumber } from "../../utils/user";
import PhoneInput from "../../components/custom-input/phone-input/phone-input";
import ErrorBlock from "../../components/error-block/error-block";

export default function Login() {

  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  
  const [invalidNumber, setInvalidNumber] = useState<string | null>(null)
  const [signInForm, setSignInForm] = useState({
    phoneData: "",
    password: ""
  })

  const handleSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (validatePhoneNumber(signInForm.phoneData)){
      dispatch(loginAction(signInForm))
      setInvalidNumber(null)
    } else {
      setInvalidNumber('Номер телефона не соответствует формату')
    }
  }

  return (
    <>
    <Helmet>
      <title>Вход</title>
    </Helmet>
      {invalidNumber && <ErrorBlock text={invalidNumber}/>}
      <div className='account__modal'>
        <div className='account__modal__content'>
          <div className='account__modal__text_login modal__textSignIn'>
            <h1 className='account__modal__title'>Вход в личный кабинет</h1>
            <form onSubmit={(e) => { handleSignIn(e) }} className='account__modal__form'>
              <PhoneInput placeholderText="Телефон" value={signInForm.phoneData} onChangeValue={(value: string)=>{setSignInForm({...signInForm, phoneData: value})}}/>
              <PasswordInput changeInput={(e) => { setSignInForm({ ...signInForm, password: e.target.value }) }} value={signInForm.password} isRegistration={false} />
              <button className='account__modal__submit' type='submit'>Войти</button>
            </form>
            <p onClick={()=>{navigate('/registration')}} className='haveAccount__text_login'>Регистрация</p>
            <p className='approval__text_login'>Используя систему товароучета, вы даете согласие на <span style={{ textDecorationLine: 'underline', cursor: 'pointer' }}>обработку персональных данных.</span></p>
          </div>
        </div>
      </div>
    </>

  )
}