import { SortData } from "../types/main"

export const getOptimizeFilter = (filter: SortData, checkedArray: {name: string, length: number}[]) => {
  let newFilterData = JSON.parse(JSON.stringify(filter))
  if (newFilterData.filters){
    for (let filter = 0; filter < newFilterData.filters.length; filter++){
      for (let point of checkedArray){
        if (newFilterData.filters[filter].filterType === 'Equals'){
          if (newFilterData.filters[filter].field.name === point.name && (newFilterData.filters[filter].values.length === point.length || newFilterData.filters[filter].values.length === 0)){
            newFilterData.filters.splice(filter, 1)
          } 
        }
      }
    }
  }
  return newFilterData as SortData
}