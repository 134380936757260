import { createSlice } from "@reduxjs/toolkit";
import { OrganizationProcess } from "../../types/state";
import { NameSpace } from "../../consts";
import { 
  addParticipantsAction, 
  editActiveOrganizationAction, 
  getActiveOrganizationAction, 
  getInvitationsLengthAction, 
  getOrganizationsAPI, 
  getParticipantsAction, 
  getSyncErrorsLengthAction, 
  getTasksLengthAction } from "../api-actions/organization-api-actions";
import { changeStateActiveOrganization } from "./organization-actions";
import { getOrganization, saveOrganization } from "../../services/organization";

const initialState: OrganizationProcess = {
  organizations: [],
  isGetOrganizations: false,
  isGetUsers: false,
  isGetActiveOrganization: false,
  activeOrganization: null,
  users: [],
  errorMessage: null,
  notificationLength: 0,
  syncErrorsLength: 0,
  tasksLength: 0
}

export const organizationProcess = createSlice({
  name: NameSpace.Organization,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(addParticipantsAction.fulfilled, (state, action) => {
        if (action.payload){
          state.errorMessage = action.payload
        }
      })
      .addCase(editActiveOrganizationAction.pending, (state) => {
        state.isGetActiveOrganization = false
      })
      .addCase(editActiveOrganizationAction.fulfilled, (state) => {
        state.isGetActiveOrganization = true
      })
      .addCase(getOrganizationsAPI.pending, (state) => {
        state.isGetOrganizations = false
      })
      .addCase(getOrganizationsAPI.fulfilled, (state, action) => {
        state.organizations = action.payload
        state.isGetOrganizations = true
        if (action.payload && !getOrganization()){
          saveOrganization(action.payload[0].id)
          state.activeOrganization = action.payload[0]
        }
      })
      .addCase(changeStateActiveOrganization, (state, action) => {
        state.activeOrganization = action.payload
        state.isGetActiveOrganization = true
        if (action.payload){
          saveOrganization(action.payload.id)
        }
      })
      .addCase(getActiveOrganizationAction.pending, (state) => {
        state.activeOrganization = null
        state.isGetActiveOrganization = false
      })
      .addCase(getActiveOrganizationAction.rejected, (state) => {
        state.activeOrganization = null
      })
      .addCase(getActiveOrganizationAction.fulfilled, (state, action) => {
        state.activeOrganization = action.payload
        state.isGetActiveOrganization = true
      })
      .addCase(getParticipantsAction.pending, (state) => {
        state.isGetUsers = false
      })
      .addCase(getParticipantsAction.fulfilled, (state, action) => {
        state.users = action.payload
        state.isGetUsers = true
      })
      .addCase(getInvitationsLengthAction.fulfilled, (state,  action) => {
        state.notificationLength = action.payload
      })
      .addCase(getSyncErrorsLengthAction.fulfilled, (state, action) => {
        state.syncErrorsLength = action.payload
      })
      .addCase(getTasksLengthAction.fulfilled, (state, action) => {
        state.tasksLength = action.payload
      })
  },
});