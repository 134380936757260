import { useRef } from 'react'
import './cabinet.css'

type CabinetInputProps = {
  content: { text: string, name: string, inputValue: string}
  index: number
  changeInputsValue: () => void
  onChangeValue: (value: string) => void
  changeIsActive: (toActive: boolean)=> void
  isActive: boolean
}

export default function CabinetInput({ content, index, changeInputsValue, changeIsActive, isActive, onChangeValue}: CabinetInputProps) {
  const input = useRef<HTMLInputElement>(null)

  const onEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && input.current) {
      changeIsActive(false)
      changeInputsValue()
    }
  }

  return (
    <div className='cabinetInput__container'>
      <p className='cabinetInput__text'>{content.text}</p>
      {isActive ? <input onKeyUp={(e) => { onEnter(e) }} ref={input} onChange={(e)=>{onChangeValue(e.target.value)}} className='cabinetInput__textInputActive' type={content.name === 'dateOfBirth' ? 'date' : 'text'} value={content.inputValue} /> :
      <p className='cabinetInput__textInput'>{content.inputValue}</p>}
    </div>
  )
}